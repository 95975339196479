<form [formGroup]="migrationForm" (ngSubmit)="onSubmit()">
  <div class="mb-3 mt-4 relative">
    <label for="email" class="block mb-1 text-md text-gray-200"> Email </label>
    <input
      id="email"
      formControlName="email"
      type="text"
      placeholder="Enter your email"
      pInputText
      class="w-full"
      [appValidationMessage]="migrationForm.controls['email']"
      [validationMessages]="{
        required: 'email is required',
        pattern: 'Invalid format for email'
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="developer" class="block mb-1 text-md text-gray-200">
      Password
    </label>
    <p-password
      id="password"
      class="p-fluid"
      placeholder="Enter your password"
      formControlName="password"
      [toggleMask]="true"
      [feedback]="false"
      [appValidationMessage]="migrationForm.controls['password']"
      [validationMessages]="{
        required: 'Password is required',
        pattern: 'Invalid format for password'
      }"
    ></p-password>
  </div>

  <p-button
    label="Migrate Account"
    class="p-fluid"
    type="submit"
    [disabled]="migrationForm.invalid"
  />
</form>
