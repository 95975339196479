export enum USER_ROLE {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}

export enum AUTH_ACTION {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  MIGRATE_ACCOUNT = 'migrate-existing',
  MIGRATE_AND_CREATE = 'migrate-and-create',
}
