import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WakeLockService {
  private wakeLock?: WakeLockSentinel | null;
  private visibilityChangeSubscription?: Subscription | null;

  async requestWakeLock(): Promise<void> {
    try {
      if ('wakeLock' in navigator) {
        this.wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake lock acquired');

        this.listenToVisibilityChange();
      } else {
        console.error('Wake Lock API is not supported in this browser.');
      }
    } catch (err) {
      console.error(`Failed to request wake lock: ${err}`);
    }
  }

  releaseWakeLock(): void {
    if (this.wakeLock == null) return;

    this.wakeLock
      .release()
      .then(() => {
        console.log('Wake lock released');
        this.wakeLock = null;
      })
      .catch((err: unknown) => {
        console.error(`Failed to release wake lock: ${err}`);
      });

    if (!this.visibilityChangeSubscription) return;

    this.visibilityChangeSubscription.unsubscribe();
    this.visibilityChangeSubscription = null;
  }

  private listenToVisibilityChange(): void {
    this.visibilityChangeSubscription = fromEvent(
      document,
      'visibilitychange'
    ).subscribe(() => {
      if (document.visibilityState === 'visible' && this.wakeLock != null) {
        this.requestWakeLock();
      }
    });
  }
}
