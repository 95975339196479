<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="mb-3 pt-3 relative">
    <label for="developer" class="block mb-1 text-md text-gray-200"
      >Developer Tag</label
    >
    <input
      id="developer"
      formControlName="developerTag"
      type="text"
      placeholder="Enter your developer tag"
      pInputText
      class="w-full"
      [appValidationMessage]="form.controls['developerTag']"
      [validationMessages]="{
        required: 'Developer Tag is required',
        pattern: 'Invalid format for Developer Tag'
      }"
    />
  </div>

  <div class="mb-5 relative">
    <label for="address" class="block mb-1 text-md text-gray-200">
      Registered Address
    </label>
    <input
      id="address"
      formControlName="registeredAddress"
      type="text"
      placeholder="Enter your registered address"
      pInputText
      class="w-full"
      [appValidationMessage]="form.controls['registeredAddress']"
      [validationMessages]="{
        required: 'Registered Address is required',
        pattern: 'Invalid format for Registered Address'
      }"
    />
  </div>

  <p-button
    label="Start work with GAIMIN developers tool"
    class="p-fluid"
    type="submit"
    [disabled]="form.invalid"
  />
</form>
