import { DatePipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { Invoice } from '../../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-invoices-tab',
  standalone: true,
  imports: [NgStyle, TableModule, DatePipe],
  templateUrl: './invoices-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesTabComponent {
  @Input() invoices!: Invoice[];
}
