<form [formGroup]="installationForm">
  @if (!previouslyPublished) { @if
  (!installationForm.controls['gameLink'].value) {
  <div
    class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
  >
    <span class="text-white text-md">Select the game zip folder</span>
    @if (!selectedGameArchiveName) {
    <p-button (onClick)="archiveSelector.click()">Select</p-button>
    } @else {
    <div
      class="text-md p-2 flex justify-content-center align-items-center gap-3"
    >
      <span class="text-white">{{ selectedGameArchiveName }}</span>
      <span
        class="pi pi-trash cursor-pointer hover:text-red-400 transition-duration-200"
        (click)="onRemoveArchive()"
      ></span>
    </div>
    }
  </div>
  } @if (installationForm.controls['gameArchive'].value &&
  !installationForm.controls['gameLink'].value) {
  <div class="mb-3 relative">
    <div
      class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
    >
      <span class="text-white text-md">Select Game Launcher</span>
      <p-dropdown
        formControlName="gameExecutable"
        [options]="gameExeList"
        placeholder="Select Game Executable"
        [loading]="!gameExeList"
        [lazy]="true"
      >
      </p-dropdown>
    </div>
  </div>
  } @else {
  <div>
    @if ( !installationForm.controls['gameLink'].value ) {
    <span class="text-gray-400 flex gap-3 align-items-center mb-5 text-md">
      <hr class="border-none border-top-1 border-gray-700 flex-1" />
      <span>Or</span>
      <hr class="border-none border-top-1 border-gray-700 flex-1" />
    </span>

    }

    <div class="mb-3 relative">
      <label for="game-link" class="block mb-1 text-md text-gray-200">
        Browser game link
      </label>
      <input
        id="game-link"
        type="url"
        placeholder="Add link"
        pInputText
        class="w-full"
        autocomplete="off"
        formControlName="gameLink"
        [appValidationMessage]="installationForm.controls['gameLink']"
        [validationMessages]="{ pattern: 'Invalid format for the Game URL' }"
      />
    </div>
  </div>
  } } @else {
  <div class="text-center mt-6">
    <h2 class="text-white text-2xl mb-3">Game files are already hosted</h2>
    <p class="text-white text-md mb-5">
      If you want to make any changes you can reupload them
    </p>
    <p-button
      class="p-button-primary p-button-md"
      (onClick)="archiveSelector.click()"
      >Reupload</p-button
    >
  </div>
  }

  <input
    type="file"
    accept=".zip"
    class="hidden"
    #archiveSelector
    (change)="onSelectArchive($event)"
    [multiple]="false"
  />
</form>

<!-- TODO: use table to display selected folder structure -->
<!-- <div>
  <div class="flex justify-content-between align-items-center p-4">
    <h3 class="text-white text-2xl">Game files</h3>
    <div
      class="cursor-pointer text-2xl hover:text-red-400 transition-duration-200"
    >
      <span class="pi pi-trash text-2xl"></span>
      Remove game files
    </div>
  </div>

  <p-treeTable
    [value]="gameFiles"
    [scrollable]="true"
    [tableStyle]="{
    'min-width': '50rem',
    'font-size': '1.6rem',
    background: 'transparent',
  }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th ttSortableColumn="name" id="gameFileName" style="width: 60%">
          Name
        </th>
        <th ttSortableColumn="size" id="gameFileSize">Size</th>
        <th ttSortableColumn="createdDate" id="gameFileCreatedDate">
          Created Date
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttRow]="rowNode">
        <td style="width: 60%">
          <p-treeTableToggler [rowNode]="rowNode" />
          {{ rowData.name }}
        </td>
        <td>{{ rowData.size | fileSize }}</td>
        <td>{{ rowData.createdDate | date : 'dd.MM.yy, HH:mm' }}</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div> -->
