import { inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../app/shared/services/auth/auth.service';
import { environment } from '../environments/environment';
import { Response } from '../app/tools/interfaces/response.interfaces';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const accessToken: string | null = authService.accessToken;

  let authReq: HttpRequest<unknown> = req;

  if (
    (req.url.startsWith(environment.devportalApi) ||
      req.url.startsWith(environment.gaiminApi)) &&
    !req.url.includes('/api/auth/token') &&
    !req.url.includes('/api/user/sign-up') &&
    !req.url.includes('/api/users/devportal/already-connected') &&
    !req.url.includes('/v3/api-docs') &&
    accessToken
  ) {
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  return next(authReq).pipe(
    tap((event: HttpEvent<unknown>) => {
      if (event instanceof HttpResponse) {
        const responseBody = event.body as Response<unknown>;
        if (
          responseBody &&
          !responseBody.success &&
          responseBody.error?.reason === 'JWT_TOKEN_EXPIRED'
        ) {
          throw new HttpErrorResponse({
            error: responseBody.error,
            status: 401,
            statusText: 'Unauthorized',
          });
        } else if (
          responseBody &&
          responseBody.error?.type === 'PERMISSION_DENIED' &&
          !event.url?.includes('/api/user/me')
        ) {
          router.navigateByUrl('/app/home');
        }
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        return handle401Error(authReq, next, authService);
      }
      return throwError(() => error);
    })
  );
};

const handle401Error = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService
): Observable<HttpEvent<unknown>> => {
  return authService.refreshAccessToken().pipe(
    switchMap((tokenResponse) => {
      const { access_token: accessToken } = tokenResponse;

      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return next(clonedReq);
    }),
    catchError((error) => {
      authService.signOut();
      return throwError(() => error);
    })
  );
};
