<div class="relative">
  <div
    [ngStyle]="{
      background: 'linear-gradient(90deg, #1a2980, #26d0ce)',
      'z-index': '-1'
    }"
    class="w-full h-12rem absolute top-0"
  ></div>

  <div
    class="mx-auto flex flex-column pt-8 px-4"
    [ngStyle]="{ 'max-width': '90rem' }"
  >
    <ng-container>
      <app-home-banners [hasAvailableGames]="!!games.length"></app-home-banners>
      <app-home-games-list
        [gamesList]="games"
        [duplicateGamesList]="duplicateGames"
        (onGameRemoved)="onGameRemoved()"
      ></app-home-games-list>
    </ng-container>
  </div>
</div>
