import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import {
  EMAIL,
  NAME,
  PHONE_NUMBER,
  TEXT,
} from '../../../tools/constants/pattern.constants';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-contact-sales',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    InputTextModule,
  ],
  templateUrl: './contact-sales.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactSalesComponent {
  public isMessageSent = false;

  contactSalesForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(NAME)]],
    company: ['', [Validators.required, Validators.pattern(TEXT)]],
    email: ['', [Validators.required, Validators.pattern(EMAIL)]],
    phone: ['', [Validators.required, Validators.pattern(PHONE_NUMBER)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private emailService: EmailService
  ) {
    this.config.header = 'Contact Sales';
    this.config.contentStyle = {
      width: '500px',
    };
  }

  public onSubmit(): void {
    const requestBody = this.contactSalesForm.value;

    this.emailService.contactSalesRequest(requestBody).subscribe((res) => {
      this.isMessageSent = true;
    });
  }

  public closeModal(): void {
    this.ref.close();
  }
}
