import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { DialogService } from 'primeng/dynamicdialog';
import { GamePreviewDialogComponent } from '../../../shared/modals/game-preview-dialog/game-preview-dialog.component';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ReviewFeedbackDialogComponent } from '../../../shared/modals/review-feedback-dialog/review-feedback-dialog.component';
import { GameBasicInfo } from '../../../tools/interfaces/response.interfaces';
import { GameRequestsService } from '../../../shared/services/games/game-requests.service';
import { Observable } from 'rxjs';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';

@Component({
  selector: 'app-game-draft-header',
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    AsyncPipe,
    TagModule,
    ButtonModule,
    ConvertEnumFromBEPipe,
  ],
  templateUrl: './game-draft-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDraftHeaderComponent {
  @Input() gameAssets!: GameBasicInfo;
  @Input() isPagePreviewDisabled!: boolean;
  @Input() icon!: string;
  @Input() status!: GAME_STATUS;
  @Input() gameId!: number;
  @Input() title!: string;
  @Input() developer!: string;

  reviewFeedback$!: Observable<{ review: string }>;
  GAME_STATUS = GAME_STATUS;

  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;

  get isRevisionsRequired(): boolean {
    return this.status === GAME_STATUS.CHANGES_REQUESTED;
  }

  get isDraftPage() {
    return window.location.href.includes('draft');
  }

  constructor(
    private dialogService: DialogService,
    public gameRequestsService: GameRequestsService,
    public breakpointService: BreakpointService
  ) {}

  ngOnInit(): void {
    this.getReviewFeedback();
  }

  getReviewFeedback(): void {
    this.reviewFeedback$ = this.gameRequestsService.getReviewFeedbackRequest(
      this.gameId
    );
  }

  onOpenGamePreview(): void {
    this.dialogService.open(GamePreviewDialogComponent, {
      data: {
        ...this.gameAssets,
        developer: this.developer,
        title: this.title,
      },
    });
  }

  onOpenFeedback(feedback: string): void {
    this.dialogService.open(ReviewFeedbackDialogComponent, {
      data: {
        feedback,
      },
    });
  }
}
