import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-privacy-policy-dialog',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy-dialog.component.html',
})
export class PrivacyPolicyDialogComponent {
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.config.contentStyle = {
      width: '800px',
      'background-color': '#18181E',
    };
    this.config.header = 'Privacy Policy';
  }
}
