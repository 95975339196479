import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { AsyncPipe, Location, NgClass, NgStyle } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { ReferralRequestsService } from '../../../shared/services/referral-requests.service';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { ReferralDialogComponent } from '../../../shared/modals/referral-dialog/referral-dialog.component';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';
import { BodyBackgroundSetterComponent } from '../../../shared/components/body-background-setter/body-background-setter.component';

@Component({
  selector: 'app-referral-start',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    LoaderComponent,
    NgStyle,
    AsyncPipe,
    BodyBackgroundSetterComponent,
  ],
  templateUrl: './referral-start.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReferralStartComponent {
  @Output() connectedToRefferal = new EventEmitter();

  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;

  constructor(
    private location: Location,
    private referralRequestsService: ReferralRequestsService,
    private messageService: MessageService,
    private dialogService: DialogService,
    public breakpointService: BreakpointService
  ) {}

  onMoveBackClick(): void {
    this.location.back();
  }

  connectToReferral() {
    this.referralRequestsService.connectToReferralRequest().subscribe({
      next: () => {
        this.connectedToRefferal.emit();

        this.messageService.add({
          severity: NOTIFICATION.SUCCESS,
          summary: 'Success',
          detail:
            'Your account has been successfully connected to the referral program.',
        });
      },
      error: () => {
        this.dialogService.open(ReferralDialogComponent, {});
      },
    });
  }
}
