@if (!loaderService.isLoading ) {
<div class="border-bottom-1 border-gray-800 py-2 mb-3">
  <div
    [ngStyle]="{ 'max-width': '62.5rem' }"
    class="m-auto flex justify-content-between align-items-center px-3"
  >
    <h2 class="text-xl">Account</h2>
    <p-button
      label="Save"
      severity="primary"
      size="small"
      (onClick)="onUpdateUserInfo()"
      [disabled]="accountInfoForm.pristine"
    ></p-button>
  </div>
</div>
<div [ngStyle]="{ 'max-width': '62.5rem' }" class="m-auto px-3 pb-8">
  <p-tabView
    [ngClass]="{
      'vertical-tabview':
        (breakpointService.currentRange$ | async) !== SCREEN_SIZE.XSMALL
    }"
  >
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex gap-2">
          <span class="pi pi-user"></span>
          <span>My account</span>
        </div>
      </ng-template>
      <div class="flex-1">
        <app-account-tab [accountInfoForm]="accountInfoForm"></app-account-tab>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex gap-2">
          <span class="pi pi-clipboard"></span>
          <span>Invoices</span>
        </div>
      </ng-template>

      <app-invoices-tab [invoices]="invoices"></app-invoices-tab>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex gap-2">
          <span class="pi pi-trash"></span>
          <span>Delete account</span>
        </div>
      </ng-template>

      <app-delete-tab></app-delete-tab>
    </p-tabPanel>
  </p-tabView>
</div>
}
