<footer class="m-2 border-round-xl" [ngStyle]="{ 'background-color': '#15161c' }">
  <div
    class="pt-8"
    [ngStyle]="{
    'max-width': '1260px',
    margin: '0 auto',
  }"
  >
    <h2 class="font-medium text-3xl mb-4">GAIMIN</h2>

    <p class="text-bluegray-300 mb-7">
      A next-generation infrastructure project set to <br />
      revolutionize the industry.
    </p>

    <div class="flex mb-8 justify-content-between">
      <div class="flex gap-8">
        @for (linksBlock of gaiminLinks; track $index) {
        <div class="flex flex-column">
          <h3 class="text-md font-medium mb-4">{{ linksBlock.title }}</h3>

          <ul class="list-none p-0 m-0 flex flex-column gap-3">
            @for (link of linksBlock.links; track $index) { @if
            (link.isComingSoon) {
            <li class="w-max text-bluegray-300">
              <span
                class="no-underline text-bluegray-300"
                [class.coming-soon]="link.isComingSoon"
                >{{ link.description }}</span
              >
            </li>
            } @else {
            <li class="custom-underline w-max text-bluegray-300">
              <a
                [href]="link.link"
                target="_blank"
                class="no-underline text-bluegray-300"
                [class.coming-soon]="link.isComingSoon"
                >{{ link.description }}</a
              >
            </li>
            } }
          </ul>
        </div>
        }
      </div>

      <div class="flex">
        @for (linksBlock of socialLinks; track $index) {
        <div class="flex flex-column">
          <h3 class="text-md font-medium mb-4">{{ linksBlock.title }}</h3>

          <ul class="list-none p-0 m-0 flex flex-column gap-3">
            @for (link of linksBlock.links; track $index) {
            <li class="custom-underline w-max text-bluegray-300">
              <a
                [href]="link.link"
                target="_blank"
                class="no-underline text-bluegray-300"
                >{{ link.description }}</a
              >
            </li>
            }
          </ul>
        </div>
        }
      </div>
    </div>

    <div
      class="flex border-top-1 justify-content-between py-5 border-bluegray-300"
    >
      <p class="text-bluegray-300">
        Copyright © 2024 GAIMIN. All rights reserved.
      </p>

      <p class="flex gap-2 text-bluegray-300">
        <a
          class="text-bluegray-300 no-underline custom-underline cursor-pointer"
          (click)="onEulaOpen($event)"
          >End User License Agreement</a
        >
        /
        <a
          class="text-bluegray-300 no-underline custom-underline cursor-pointer"
          (click)="onPrivacyPolicyOpen($event)"
          >Privacy Policy</a
        >
      </p>
    </div>
  </div>
</footer>
