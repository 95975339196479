import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appValidationMessage]',
  standalone: true,
})
export class ValidationMessageDirective {
  @Input('appValidationMessage') control!: AbstractControl | null;
  @Input() validationMessages!: { [key: string]: string };

  private errorMessageElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.createErrorMessageElement();
    this.subscribeToControlEvents();
  }

  private get shouldShowErrorMessage(): boolean {
    return !!this.control?.errors && this.control?.touched;
  }

  private get errorMessage(): string {
    const firstKey: string = Object.keys(this.control!.errors!)[0];
    return this.validationMessages[firstKey] || 'Invalid field';
  }

  private createErrorMessageElement(): void {
    this.errorMessageElement = this.renderer.createElement('small');
    this.applyClassesToErrorElement();
    this.insertErrorMessageElement();
    this.updateErrorMessage();
  }

  private applyClassesToErrorElement(): void {
    const classes = 'color-red-300 text-xs absolute mt-1 top-full left-0';
    classes
      .split(' ')
      .forEach((className) =>
        this.renderer.addClass(this.errorMessageElement, className)
      );
  }

  private insertErrorMessageElement(): void {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    const nextSibling = this.renderer.nextSibling(this.el.nativeElement);

    nextSibling
      ? this.renderer.insertBefore(
          parent,
          this.errorMessageElement,
          nextSibling
        )
      : this.renderer.appendChild(parent, this.errorMessageElement);
  }

  private subscribeToControlEvents(): void {
    this.control?.statusChanges.subscribe(() => this.handleControlChanges());
    this.control?.valueChanges.subscribe(() => this.handleControlChanges());
  }

  private handleControlChanges(): void {
    this.control?.markAllAsTouched();
    this.updateErrorMessage();
  }

  private updateErrorMessage(): void {
    if (this.shouldShowErrorMessage) {
      this.showErrorMessage(this.errorMessage);
    } else {
      this.hideErrorMessage();
    }
  }

  private showErrorMessage(message: string): void {
    this.renderer.setProperty(this.errorMessageElement, 'innerText', message);
    this.renderer.setStyle(this.errorMessageElement, 'display', 'block');
  }

  private hideErrorMessage(): void {
    this.renderer.setStyle(this.errorMessageElement, 'display', 'none');
  }
}
