import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';

@Component({
  selector: 'app-delete-account-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonModule, InputTextModule],
  templateUrl: './delete-account-dialog.component.html',
})
export class DeleteAccountDialogComponent {
  deleteAccountForm: FormGroup = this.fb.group({
    confirmation: ['', Validators.required, this.matchDeleteAccountValidator],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.config.styleClass = 'bg-gray-1000 ';
    this.config.contentStyle = {
      width: '600px',
      'background-color': '#18181E',
    };
    this.config.header = 'Delete account';
  }

  matchDeleteAccountValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    return of(control.value === 'Delete Account' ? null : { mismatch: true });
  }

  onCancel(): void {
    this.ref.close();
  }

  // TODO: move to authService (or create AccountService)
  deleteAccountRequest(): void {
    this.http
      .delete(`${environment.devportalApi}/api/user/me`)
      .subscribe((res) => {
        this.ref.close();
        this.authService.signOut();
        this.messageService.add({
          severity: NOTIFICATION.SUCCESS,
          summary: 'Success',
          detail: 'Account was deleted successfully!',
        });
      });
  }
}
