<div
  class="flex justify-content-between py-3 border-round bg-gray-950 pr-6 mb-3"
>
  @for (item of statisticData; track $index) {
  <article
    class="flex align-items-center gap-6 py-3 pl-6 border-gray-800"
    [class.border-left-1]="$index !== 0"
  >
    <div class="flex flex-column gap-3">
      <div class="flex gap-2 align-items-center">
        <img [src]="'../../../../assets/icons/' + item.icon" alt="" />
        <h2 class="text-gray-400 font-normal text-md">{{ item.title }}</h2>
      </div>

      <div class="flex align-items-baseline gap-2">
        <p class="font-normal text-2xl">
          {{ item.value | thousandSeparator : item.isGmrx }}
        </p>

        @if (item.isGmrx) {
        <p class="text-gray-200 font-normal text-xl">GMRX</p>
        }
      </div>
    </div>

    @if (item.hasButton) {
    <p-button label="Withdraw" severity="primary"></p-button>
    }
  </article>
  }
</div>
