<div class="flex h-full gap-2 w-full p-3 relative">
  <div class="flex flex-column flex-1 justify-content-center">
    <div
      class="justify-self-center align-self-end m-auto"
      [ngStyle]="{
        'max-width': '32rem'
      }"
    >
      <div class="mb-4">
        <button
          type="button"
          class="flex justify-content-center align-items-center w-min cursor-pointer gap-1 p-2 border-round-md text-gray-100 border-1 border-gray-700 mb-8 transition-colors transition-duration-200 hover:border-white"
          [ngStyle]="{
            'line-height': '100%',
            'background-color': 'transparent',
          }"
          (click)="onMoveBackClick()"
        >
          <svg
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.183069 5.45387C-0.0610235 5.20283 -0.0610235 4.79516 0.183069 4.54413L3.93234 0.688273C4.17643 0.437241 4.57284 0.437241 4.81693 0.688273C5.06102 0.939305 5.06102 1.34698 4.81693 1.59801L1.50898 5L4.81498 8.40199C5.05907 8.65302 5.05907 9.06069 4.81498 9.31173C4.57088 9.56276 4.17448 9.56276 3.93038 9.31173L0.181117 5.45587L0.183069 5.45387Z"
              fill="#D3D3EE"
            />
          </svg>

          Back
        </button>

        <h2 class="text-3xl mb-5 font-medium">
          Join the GAIMIN referral program and invite new users
        </h2>

        <p class="text-md font-normal mb-3">
          You’ll earn 30% of the rewards generated in the previous 7 days by
          your referrals once they start earning on GAIMIN.
        </p>

        <p class="text-md font-normal">
          To be eligible for these rewards, you need to personally monetize for
          at least 10 hours in the previous 7 days.
        </p>
      </div>

      <p-button
        label="Join"
        class="p-fluid mb-5 block"
        (onClick)="connectToReferral()"
      ></p-button>
    </div>
  </div>

  @if ((breakpointService.currentRange$ | async) !== SCREEN_SIZE.XSMALL) {
  <div class="flex-1 h-full">
    <div
      class="bg-gray-1000 text-center border-round-xl flex flex-column h-full justify-content-center align-items-center gap-6"
    >
      <img src="./assets/illustrations/referral.png" alt="" class="w-full" />
      <div
        class="flex flex-column justify-content-center align-items-center gap-3"
        [ngStyle]="{ 'max-width': '500px' }"
      >
        <h2 class="text-5xl font-medium mb-2">
          Join GAIMIN Referral Program and Get Reward
        </h2>
      </div>
    </div>
  </div>
  } @else {
  <img
    src="./assets/illustrations/referral.png"
    alt=""
    class="w-full absolute"
    [ngStyle]="{ 'z-index': '-1', transform: 'translateX(-1rem) scale(2)' }"
  />
  <app-body-background-setter color="#111115"></app-body-background-setter>
  }
</div>

<app-loader></app-loader>
