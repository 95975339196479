import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AppLayoutComponent } from './pages/layout/app-layout/app-layout.component';
import { AuthLayoutComponent } from './pages/layout/auth-layout/auth-layout.component';
import { NewGameComponent } from './pages/new-game/new-game.component';
import { AddFirstGameComponent } from './pages/add-first-game/add-first-game.component';
import { GameAddedComponent } from './pages/game-added/game-added.component';
import { ReviewFormComponent } from './pages/review-form/review-form.component';
import { OnlineGuard } from './shared/guards/online.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { GameReviewingComponent } from './pages/game-reviewing/game-reviewing.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { AdminGameComponent } from './pages/admin-pages/admin-game/admin-game.component';
import { AdminPanelComponent } from './pages/admin-pages/admin-panel/admin-panel.component';
import { AdminLayoutComponent } from './pages/layout/admin-layout/admin-layout.component';
import { AdminGuard } from './shared/guards/admin.guard';
import { ReviewFeedbackComponent } from './pages/review-feedback/review-feedback.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { StartComponent } from './pages/start/start.component';
import { MarketingComponent } from './pages/marketing/marketing.component';

// TODO: improve page names to be more declarative
export const routes: Routes = [
  {
    path: 'start',
    component: StartComponent,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
  },
  {
    path: 'auth/callback/:action',
    component: AuthCallbackComponent,
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      { path: '', redirectTo: 'gameslist', pathMatch: 'full' },
      {
        path: 'gameslist',
        component: AdminPanelComponent,
        canActivate: [AuthGuard, OnlineGuard, AdminGuard],
      },
      {
        path: 'gameslist/:game-id',
        component: AdminGameComponent,
        canActivate: [AuthGuard, OnlineGuard, AdminGuard],
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
        canActivate: [AuthGuard, OnlineGuard, AdminGuard],
      },
    ],
  },
  {
    path: 'app',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'add-first-game',
        component: AddFirstGameComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'new-game',
        component: NewGameComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'game-added/:game-id',
        component: GameAddedComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'review/:game-id',
        component: ReviewFormComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'game-reviewing/:game-id',
        component: GameReviewingComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'review-feedback/:game-id',
        component: ReviewFeedbackComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'documentation',
        redirectTo: 'documentation/gameuploading',
        pathMatch: 'full',
      },
      {
        path: 'documentation/:section',
        component: DocumentationComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'api',
        component: HomeComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'analytics',
        component: HomeComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
      {
        path: 'referral',
        component: ReferralComponent,
        canActivate: [AuthGuard, OnlineGuard],
      },
    ],
  },
  {
    path: 'password',
    component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'reset', component: ResetPasswordComponent },
    ],
  },
  {
    path: '',
    redirectTo: 'app/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'app/home',
  },
];
