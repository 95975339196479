<footer
  class="m-2 border-round-xl"
  [ngStyle]="{ 'background-color': '#161921' }"
>
  <div
    class="pt-8"
    [ngStyle]="{
    'max-width': '1260px',
    margin: '0 auto',
  }"
  >
    <h2 class="font-medium text-3xl mb-3">GAIMIN</h2>

    <p class="color-text-400 mb-7 line-height-3">
      A next-generation infrastructure project set to <br />
      revolutionize the industry.
    </p>

    <div class="flex mb-8 justify-content-between">
      <div class="flex gap-8">
        @for (linksBlock of gaiminLinks; track $index) {
        <div class="flex flex-column">
          <h3 class="text-md font-medium mb-4">{{ linksBlock.title }}</h3>

          <ul class="list-none p-0 m-0 flex flex-column gap-3">
            @for (link of linksBlock.links; track $index) { @if
            (link.isComingSoon) {
            <li class="w-max color-text-400">
              <span
                class="no-underline color-text-400"
                [class.coming-soon]="link.isComingSoon"
                >{{ link.description }}</span
              >
            </li>
            } @else {
            <li class="custom-underline w-max color-text-400">
              <a
                [href]="link.link"
                target="_blank"
                class="no-underline color-text-400"
                [class.coming-soon]="link.isComingSoon"
                >{{ link.description }}</a
              >
            </li>
            } }
          </ul>
        </div>
        }
      </div>

      <div class="flex">
        @for (linksBlock of socialLinks; track $index) {
        <div class="flex flex-column">
          <h3 class="text-md font-medium mb-4">{{ linksBlock.title }}</h3>

          <ul class="list-none p-0 m-0 flex flex-column gap-3">
            @for (link of linksBlock.links; track $index) {
            <li class="custom-underline w-max color-text-400">
              <a
                [href]="link.link"
                target="_blank"
                class="no-underline color-text-400"
                >{{ link.description }}</a
              >
            </li>
            }
          </ul>
        </div>
        }
      </div>
    </div>

    <div class="flex justify-content-between py-5">
      <p class="color-text-400">
        Copyright © 2024 GAIMIN. All rights reserved.
      </p>

      <p class="flex gap-2 color-text-400">
        <a
          class="color-text-400 no-underline custom-underline cursor-pointer"
          (click)="onEulaOpen($event)"
          >End User License Agreement</a
        >
        /
        <a
          class="color-text-400 no-underline custom-underline cursor-pointer"
          (click)="onPrivacyPolicyOpen($event)"
          >Privacy Policy</a
        >
      </p>
    </div>
  </div>
</footer>
