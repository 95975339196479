import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function editorRequired(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const strippedValue = value.replace(/<[^>]*>/g, '').trim();

    return strippedValue ? null : { required: true };
  };
}
