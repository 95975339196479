<div
  [ngStyle]="{
    'background-image': 'url(../../../../assets/illustrations/referral.png)',
    'background-repeat': 'no-repeat',
  }"
>
  <div
    class="flex justify-content-between w-full px-7 py-6 border-1 border-gray-750 border-round mb-3"
    [ngStyle]="{ 'background-color': 'rgba(0, 0, 0, 0.7)' }"
  >
    <div class="flex flex-column gap-3">
      <h2 class="text-5xl font-medium line-height-2">Invite friends</h2>

      <p class="text-xl line-height-2 font-medium">
        Get 30% for any referrals purchases
      </p>
    </div>

    @if (referralLink) {
    <div class="flex flex-column gap-3">
      <h2 class="text-xl line-height-2 font-medium">
        Get rewarded for referring a friend
      </h2>

      <div class="flex gap-2">
        <input
          [ngStyle]="{ 'min-width': '400px', cursor: 'text' }"
          id="username"
          type="username"
          pInputText
          formControlName="username"
          class="p-input w-full opacity-100"
          disabled
          [value]="referralLink | shortenString: 50"
          (copy)="copyToClipboard()"
        />

        <p-button
          label="Copy"
          severity="primary"
          (onClick)="copyToClipboard()"
        ></p-button>
      </div>
    </div>
    }
  </div>
</div>
