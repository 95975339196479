import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { Location, NgStyle } from '@angular/common';
import { ReferalService } from '../../shared/services/referal.service';

@Component({
  selector: 'app-referal',
  standalone: true,
  imports: [RouterModule, ButtonModule, LoaderComponent, NgStyle],
  templateUrl: './referal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferalComponent {
  constructor(
    private location: Location,
    private referalService: ReferalService,
    private cdr: ChangeDetectorRef
  ) {}

  onMoveBackClick(): void {
    this.location.back();
  }

  connectToReferal() {
    this.cdr.detectChanges();
    this.referalService.connectToReferal();
  }
}
