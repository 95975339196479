<div class="flex flex-column h-screen overflow-hidden">
  <app-header></app-header>

  <div class="flex flex-1">
    <div class="no-print">
      @if ((breakpointService.currentRange$ | async) === SCREEN_SIZE.XSMALL) {
      <app-layout-mobile-sidebar></app-layout-mobile-sidebar>
      } @else {
      <app-layout-sidebar></app-layout-sidebar>
      }
    </div>

    <div
      class="flex-grow-1 overflow-y-scroll custom-scroll"
      [ngStyle]="{ height: 'calc(100vh - 3.5rem)' }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-loader></app-loader>
