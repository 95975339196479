<div class="max-w-800px m-auto flex mb-5" [ngStyle]="{ 'max-width': '50rem' }">
  <img [src]="icon" alt="" class="w-4rem h-4rem" />
  <div class="ml-3">
    <h2 class="text-2xl line-height-2 m-0">{{ title }}</h2>
    <ng-container
      *ngIf="isDraftPage && (reviewFeedback$ | async); else noReview"
    >
      <p-tag
        [value]="GAME_STATUS.CHANGES_REQUESTED | convertEnumFromBE"
        [class]="GAME_STATUS.CHANGES_REQUESTED"
      ></p-tag>
    </ng-container>

    <ng-template #noReview>
      <p-tag [value]="status | convertEnumFromBE" [class]="status"></p-tag>
    </ng-template>
  </div>

  <div class="ml-auto flex gap-2">
    @if (status !== GAME_STATUS.PUBLISHED){
    <ng-container *ngIf="reviewFeedback$ | async as feedbackResponse">
      <p-button
        severity="primary"
        label="View feedback"
        (onClick)="onOpenFeedback(feedbackResponse.review)"
      ></p-button>
    </ng-container>
    }

    @if((breakpointService.currentRange$ |async) !== SCREEN_SIZE.XSMALL) {
    <p-button
      severity="secondary"
      label="Preview Page"
      (onClick)="onOpenGamePreview()"
      [disabled]="isPagePreviewDisabled"
    ></p-button>
    }
  </div>
</div>
