@if (!isMessageSent) {
<form [formGroup]="contactSalesForm" (ngSubmit)="onSubmit()" class="pt-4">
  <div class="mb-6">
    <h2 class="text-2xl font-medium">File Sharing Request</h2>

    <div class="mb-3 mt-4 relative">
      <label for="name" class="block mb-1 text-md text-gray-200"> Name </label>
      <input
        id="name"
        formControlName="name"
        type="text"
        placeholder="Enter Your Name"
        pInputText
        class="w-full"
        [appValidationMessage]="contactSalesForm.controls['name']"
        [validationMessages]="{
          required: 'Name is required',
          pattern: 'Invalid format for name'
        }"
      />
    </div>

    <div class="mb-3 mt-4 relative">
      <label for="company" class="block mb-1 text-md text-gray-200">
        Company name
      </label>
      <input
        id="company"
        formControlName="company"
        type="text"
        placeholder="Enter Company name"
        pInputText
        class="w-full"
        [appValidationMessage]="contactSalesForm.controls['company']"
        [validationMessages]="{
          required: 'Company name is required',
          pattern: 'Invalid format for company name'
        }"
      />
    </div>

    <div class="mb-3 mt-4 relative">
      <label for="email" class="block mb-1 text-md text-gray-200">
        Email
      </label>
      <input
        id="email"
        formControlName="email"
        type="text"
        placeholder="Enter Email"
        pInputText
        class="w-full"
        [appValidationMessage]="contactSalesForm.controls['email']"
        [validationMessages]="{
          required: 'email is required',
          pattern: 'Invalid format for email'
        }"
      />
    </div>

    <div class="mb-3 mt-4 relative">
      <label for="phone" class="block mb-1 text-md text-gray-200">
        Phone number
      </label>
      <input
        id="phone"
        formControlName="phone"
        type="text"
        placeholder="Enter Phone number"
        pInputText
        class="w-full"
        [appValidationMessage]="contactSalesForm.controls['phone']"
        [validationMessages]="{
          required: 'Phone number is required',
          pattern: 'Invalid format for Phone number'
        }"
      />
    </div>
  </div>

  <p-button
    label="Contact"
    class="p-fluid"
    type="submit"
    [disabled]="contactSalesForm.invalid"
  />
</form>
} @else {
<div class="pt-4">
  <img
    class="w-full mb-3"
    src="../../../../assets/illustrations/modal-banner.png"
    alt=""
  />

  <h2 class="font-medium text-white text-center text-xl mb-3">
    File Sharing Request
  </h2>

  <p class="text-center text-gray-200 font-normal text-base mb-4">
    Our sales team will reach out to you within 1 business day
  </p>

  <p-button label="OK" class="p-fluid" (onClick)="closeModal()" />
</div>
}
