import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { DialogService } from 'primeng/dynamicdialog';
import { GamePreviewDialogComponent } from '../../../shared/modals/game-preview-dialog/game-preview-dialog.component';
import { GameData } from '../../../tools/interfaces/response.interfaces';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { GamesService } from '../../../shared/services/games.service';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { NgStyle } from '@angular/common';
import { ReviewFeedbackDialogComponent } from '../../../shared/modals/review-feedback-dialog/review-feedback-dialog.component';

@Component({
  selector: 'app-review-form-header',
  standalone: true,
  imports: [NgStyle, TagModule, ButtonModule, ConvertEnumFromBEPipe],
  templateUrl: './review-form-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewFormHeaderComponent implements OnInit {
  // TODO: implement actual types for forms
  @Input() pageInfo!: {
    title: string;
    shortDescription: string;
    fullDescription: string;
    genres: string[];
    chains: string[];
    releaseDate?: Date;
    releaseStage: {
      label: string;
      value: string;
    };
    screenshots: string[];
    bannerImage: string;
    backgroundImage: string;
    developer: {
      displayName: string;
    };
  };
  @Input() isPagePreviewDisabled!: boolean;
  @Input() icon!: string;
  @Input() status!: string;
  @Input() gameId!: number;

  feedback?: string;

  get isRevisionsRequired(): boolean {
    return this.status === GAME_STATUS.REVISIONS_REQUIRED;
  }

  constructor(
    private dialogService: DialogService,
    private gameService: GamesService
  ) {}

  ngOnInit(): void {
    if (!this.isRevisionsRequired) return;
    this.gameService.getReviewFeedback(this.gameId).subscribe((res) => {
      this.feedback = res.review;
    });
  }

  onOpenGamePreview(): void {
    this.dialogService.open(GamePreviewDialogComponent, {
      data: this.pageInfo,
    });
  }

  onOpenFeedback(): void {
    this.dialogService.open(ReviewFeedbackDialogComponent, {
      data: {
        feedback: this.feedback,
      },
    });
  }
}
