import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RegexpResponse } from '../../tools/interfaces/response.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RegexpService {
  private _websiteRegexpSubject$ = new BehaviorSubject(new RegExp(''));
  private _youtubeRegexpSubject$ = new BehaviorSubject(new RegExp(''));
  private _twitterRegexpSubject$ = new BehaviorSubject(new RegExp(''));
  private _discordRegexpSubject$ = new BehaviorSubject(new RegExp(''));
  private _telegramRegexpSubject$ = new BehaviorSubject(new RegExp(''));
  private _instagramRegexpSubject$ = new BehaviorSubject(new RegExp(''));

  private _isRegexpInitialized$ = new BehaviorSubject(false);

  public get isRegexpInitialized() {
    return this._isRegexpInitialized$.asObservable();
  }

  public get websiteRegexp() {
    return this._websiteRegexpSubject$.value;
  }

  public get youtubeRegexp() {
    return this._youtubeRegexpSubject$.value;
  }

  public get twitterRegexp() {
    return this._twitterRegexpSubject$.value;
  }

  public get discordRegexp() {
    return this._discordRegexpSubject$.value;
  }

  public get telegramRegexp() {
    return this._telegramRegexpSubject$.value;
  }

  public get instagramRegexp() {
    return this._instagramRegexpSubject$.value;
  }

  constructor(private http: HttpClient) {
    this._initializeRegex();
  }

  private _convertJavaRegexpToJS(javaRegExp: string): string {
    return javaRegExp.replace(/\\\\/g, '\\');
  }

  private _initializeRegex(): void {
    this.http
      .get<RegexpResponse>(`${environment.devportalApi}/api/config/validation`)
      .subscribe((res) => {
        const { website, youtube, twitter, discord, telegram, instagram } =
          res.regex;

        this._websiteRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(website))
        );
        this._youtubeRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(youtube))
        );
        this._twitterRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(twitter))
        );
        this._discordRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(discord))
        );
        this._telegramRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(telegram))
        );
        this._instagramRegexpSubject$.next(
          new RegExp(this._convertJavaRegexpToJS(instagram))
        );

        this._isRegexpInitialized$.next(true);
      });
  }
}
