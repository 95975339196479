export enum SortState {
  ORIGINAL = 'ORIGINAL',
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortField {
  NAME = 'NAME',
  GMRX = 'GMRX',
  REGISTER_DATE = 'REGISTER_DATE',
}

