import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { LayoutMobileSidebarComponent } from './layout-mobile-sidebar/layout-mobile-sidebar.component';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  standalone: true,
  imports: [
    NgStyle,
    AsyncPipe,
    LayoutSidebarComponent,
    LayoutMobileSidebarComponent,
    RouterModule,
    LoaderComponent,
    HeaderComponent,
    SidebarModule,
  ],
})
export class AppLayoutComponent {
  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;
  isMobileSidebarVisible: boolean = true;

  constructor(
    public breakpointService: BreakpointService,
    public dialogService: DialogService
  ) {}
}
