import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';
import { ReferralRequestsService } from '../../../shared/services/referral-requests.service';
import { environment } from '../../../../environments/environment';
import { ShortenStringPipe } from '../../../shared/pipes/shorten-string.pipe';

@Component({
  selector: 'app-referral-invite',
  standalone: true,
  imports: [InputTextModule, ButtonModule, NgStyle, ShortenStringPipe],
  templateUrl: './referral-invite.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReferralInviteComponent implements OnInit {
  public referralLink = '';

  constructor(
    private messageService: MessageService,
    private referralRequestsService: ReferralRequestsService
  ) {}

  ngOnInit(): void {
    this.referralRequestsService.getReferralLinkRequest().subscribe((link) => {
      this.referralLink = this._getCorrectLink(link);
    });
  }

  public copyToClipboard(): void {
    navigator.clipboard.writeText(this.referralLink);

    this.messageService.add({
      severity: NOTIFICATION.SUCCESS,
      summary: 'Success',
      detail: 'Invite link has been copied to the clipboard',
    });
  }

  private _getCorrectLink(link: string): string {
    return `${environment.gaiminUrl}?${link.split('?')[1]}`;
  }
}
