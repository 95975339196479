import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  ReferralUser,
  UserNep,
} from '../../tools/interfaces/response.interfaces';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralRequestsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  public checkReferralConnectionRequest() {
    return this.http.get<boolean>(
      `${environment.gaiminApi}/api/users/devportal/already-connected?devPortalJWT=${this.authService.accessToken}`
    );
  }

  public connectToReferralRequest() {
    return this.http.post(
      `${environment.gaiminApi}/api/users/devportal/connect?devPortalJWT=${this.authService.accessToken}`,
      {}
    );
  }

  public getUserStatisticRequest() {
    return this.http.get<UserNep>(
      `${environment.gaiminApi}/api/users/me/nep/all`
    );
  }

  public getUserReferralsRequest() {
    return this.http.get<ReferralUser[]>(
      `${environment.gaiminApi}/api/users/me/referral-network/users`
    );
  }

  public getReferralLinkRequest() {
    return this.http.get<string>(
      `${environment.gaiminApi}/api/referral-program`
    );
  }
}
