import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator',
  standalone: true
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: number | string, condition: boolean = true): number | string {
    if (condition && Number.isNaN(value)) {
      return +value.toLocaleString('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3
      });
    }

    return value;
  }
}
