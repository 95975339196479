<div class="h-screen overflow-y-scroll custom-scroll">
  <div
    [ngStyle]="{
      'background-image':
        'url(../../../../assets/illustrations/gradient-bg.png)',
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }"
  >
    <div
      [ngStyle]="{
        'max-width': '1260px',
        margin: '0 auto',
      }"
      class="h-screen p-4 flex flex-column"
    >
      <header class="flex justify-content-between mb-1">
        <div class="flex gap-5 align-items-center">
          <div>
            <a routerLink="/start" class="flex">
              <img src="../../../../assets/icons/logo.png" alt="" />
            </a>
          </div>

          <nav>
            <ul class="flex gap-4 list-none">
              <li>
                <a
                  routerLink="/marketing"
                  class="font-bold text-white no-underline custom-underline"
                >
                  File Sharing
                </a>
              </li>

              <li>
                <a
                  class="font-bold text-white no-underline custom-underline cursor-pointer"
                  (click)="onOpenSupportDialog($event)"
                >
                  Help
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="flex gap-4">
          <p-button
            label="Migration info"
            severity="primary"
            size="small"
            (onClick)="onMigrationInfoClick()"
          ></p-button>

          <div
            class="flex flex-column justify-content-center align-items-center gap-2"
          >
            <p-button
              label="Sign in"
              severity="primary"
              size="small"
              (onClick)="authService.authActionRedirect(SIGN_IN)"
            ></p-button>

            <div
              class="flex flex-column justify-content-center align-items-center"
            >
              <p class="text-gray-100 text-sm">Don't have an account?</p>
              <span
                class="w-max cursor-pointer font-medium text-primary custom-underline"
                (click)="authService.authActionRedirect(SIGN_UP)"
                >Sign up</span
              >
            </div>
          </div>
        </div>
      </header>

      <div
        class="flex flex-column align-items-center justify-content-center mt-5 flex flex-grow-1"
      >
        <p
          [ngStyle]="{
            'border-radius': '100px',
            'background-color': 'rgba(238, 242, 247, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.05)'
          }"
          class="uppercase text-center text-white text-xl text-bold px-4 py-3 mb-7"
        >
          Grow Your Player Base Fast
        </p>

        <p class="text-center font-bold text-6xl mb-3">
          List your Web3 game on <br />
          GAIMIN and reach 500K gamers
        </p>

        <p class="opacity-60 text-2xl mb-6">
          Tap into Web3 Gaming and grow your player base with GAIMIN
        </p>

        <p-button
          label="Get Started"
          severity="primary"
          size="large"
          [style]="{ width: '250px' }"
          (onClick)="authService.authActionRedirect(SIGN_UP)"
        ></p-button>
      </div>
    </div>
  </div>

  <div
    [ngStyle]="{
      'max-width': '1260px',
      margin: '0 auto',
    }"
  >
    <div class="flex justify-content-between align-items-center mb-5">
      <p class="font-bold text-4xl">Our advantages</p>

      <p class="text-xl text-gray-600">
        Discover the benefits of tailored solutions backed <br />
        by cutting-edge technology and expert support.
      </p>
    </div>

    <div class="grid mb-8">
      @for (advantage of advantagesContent; track $index) {
      <div
        [class.col-8]="advantage.fractions === 8"
        [class.col-4]="advantage.fractions === 4"
      >
        <article
          class="p-5 flex flex-column justify-content-end border-round-xl"
          [ngStyle]="{
            height: '360px',
            'background-image': advantage.backgroundUrl,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center'
          }"
        >
          <h2 class="font-bold text-xl mb-4">{{ advantage.title }}</h2>

          <p [innerHTML]="advantage.text"></p>
        </article>
      </div>
      }
    </div>
  </div>

  <app-products-footer></app-products-footer>
</div>
