import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { AccessToken } from '../../../tools/interfaces/response.interfaces';
import {
  AUTH_ACTION,
  USER_ROLE,
} from '../../../tools/constants/auth.constants';
import { GamesService } from '../games/games.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MigrationNoticeDialogComponent } from '../../modals/migration-notice-dialog/migration-notice-dialog.component';
import { AuthRequestsService } from './auth-requests.service';
import { BehaviorSubject, EMPTY, Observable, switchMap, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public accessToken: string | null = null;
  public refreshToken: string | null = null;
  public isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private gamesService: GamesService,
    private dialogService: DialogService,
    private authRequestsService: AuthRequestsService
  ) {}

  public initialize() {
    this._retrieveTokensFromStorages();

    this.authRequestsService.getUserRolesRequest().subscribe({
      next: (roles) => {
        const isAdmin: boolean = roles.includes(USER_ROLE.ADMIN);
        this.isAdmin$.next(isAdmin);
      },
    });
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  public get isAdmin(): boolean {
    return this.isAdmin$.value;
  }

  private _setAccessToken(token: string | null): void {
    this.accessToken = token;

    token
      ? localStorage.setItem('accessToken', token)
      : localStorage.removeItem('accessToken');
  }

  private _setRefreshToken(token: string | null): void {
    this.refreshToken = token;

    token
      ? localStorage.setItem('refreshToken', token)
      : localStorage.removeItem('refreshToken');
  }

  public refreshAccessToken(): Observable<AccessToken> {
    return this.authRequestsService
      .refreshAccessTokenRequest(this.refreshToken)
      .pipe(
        tap((tokenResponse: AccessToken) => {
          const { access_token: accessToken, refresh_token: refreshToken } =
            tokenResponse;
          this._setAccessToken(accessToken);
          this._setRefreshToken(refreshToken);
        })
      );
  }

  private _retrieveTokensFromStorages(): void {
    const accessToken: string | null = localStorage.getItem('accessToken');
    const refreshToken: string | null = localStorage.getItem('refreshToken');

    if (!refreshToken || !accessToken) {
      this.isLoggedIn$.next(false);
    }

    this._setAccessToken(accessToken);
    this._setRefreshToken(refreshToken);
  }

  public signOut(): void {
    this._setAccessToken(null);
    this._setRefreshToken(null);
    this.isLoggedIn$.next(false);
    this.isAdmin$.next(false);

    this._logoutRedirect();
  }

  private _logoutRedirect(): void {
    const loginUrl: string = btoa(`${environment.devportalUrl}/auth`);
    const params: HttpParams = new HttpParams().set('returnURL', loginUrl);
    const gaiminAuthLogoutUrl = `${
      environment.gaiminAuthFE
    }/logout?${params.toString()}`;

    this.document.location.href = gaiminAuthLogoutUrl;
  }

  public authorizeUser(accessToken: string, refreshToken: string): void {
    this._setAccessToken(accessToken);
    this._setRefreshToken(refreshToken);
    this.isLoggedIn$.next(true);

    this._checkForAdminRights();
  }

  private _checkForAdminRights(): void {
    this.authRequestsService
      .getUserRolesRequest()
      .pipe(
        switchMap((userRoles) => {
          if (userRoles.includes(USER_ROLE.ADMIN)) {
            this.isAdmin$.next(true);
            this.router.navigateByUrl('/admin/gameslist');
            return EMPTY;
          } else {
            return this.gamesService.getUsersGames();
          }
        })
      )
      .subscribe({
        next: (games) => {
          this.router.navigateByUrl(
            games.length ? '/app/home' : 'app/app-first-game'
          );
        },
        error: (error) => {
          console.error(
            'Error checking for admin rights or fetching games:',
            error
          );
        },
      });
  }

  public openMigrationNotice(): void {
    this.dialogService.open(MigrationNoticeDialogComponent, {});
  }

  public authActionRedirect(authActionType: AUTH_ACTION): void {
    const redirectUrl: string = this._buildRedirectUrl(authActionType);

    this.document.location.href = this._getAuthUrl(authActionType, redirectUrl);
  }

  private _buildRedirectUrl(authActionType: AUTH_ACTION): string {
    const callbackUrl = `${environment.devportalUrl}/auth/callback/${authActionType}`;

    return btoa(callbackUrl);
  }

  private _getAuthUrl(
    authActionType: AUTH_ACTION,
    redirectUrl: string
  ): string {
    const params: HttpParams = new HttpParams().set('returnURL', redirectUrl);

    switch (authActionType) {
      case AUTH_ACTION.SIGN_IN:
      case AUTH_ACTION.MIGRATE_ACCOUNT: {
        return `${environment.gaiminAuthFE}/signin?${params.toString()}`;
      }
      case AUTH_ACTION.SIGN_UP:
      case AUTH_ACTION.MIGRATE_AND_CREATE: {
        const signUpUrl: string = btoa(
          `${environment.gaiminAuthFE}/signup?${params.toString()}`
        );
        return `${environment.gaiminAuthFE}/logout?returnURL=${signUpUrl}`;
      }
      default: {
        throw new Error(`Unsupported auth action: ${authActionType}`);
      }
    }
  }
}
