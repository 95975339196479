@if (gameDraft) {
<div
  [ngStyle]="{ padding: '100px 0', 'min-height': 'calc(100vh - 3.5rem)' }"
  class="relative"
>
  <app-review-form-header
    [pageInfo]="basicInfo.value"
    [icon]="gameDraft.iconUri"
    [status]="gameDraft.status"
    [isPagePreviewDisabled]="basicInfo.invalid"
    [gameId]="gameDraft.id"
  ></app-review-form-header>

  <form
    [formGroup]="gameReviewForm"
    (ngSubmit)="onSubmit()"
    class="m-auto"
    [ngStyle]="{ 'max-width': '840px' }"
  >
    <p-tabView [(activeIndex)]="activeIndex">
      <p-tabPanel header="1. Basic info">
        <ng-template pTemplate="content">
          <app-basic-info
            [basicInfoForm]="basicInfo"
            [gameId]="gameDraft.id"
          ></app-basic-info>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="2. Installation files">
        <ng-template pTemplate="content">
          <app-installation
            [installationForm]="installation"
            [gameId]="gameDraft.id"
          ></app-installation>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="3. Legal">
        <ng-template pTemplate="content">
          <app-legal [legalForm]="legal"></app-legal>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="4. Social & Support">
        <ng-template pTemplate="content">
          <app-links [socialLinksForm]="links"></app-links>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="5. Send to Review">
        <ng-template pTemplate="content">
          <div class="text-center mt-6">
            @if (gameDraft.status === GAME_STATUS.REVIEW) {
            <p class="text-white text-xl mt-0 mb-4">
              Your request is under review. We'll notify you once it's done.
            </p>
            } @else {
            <h2 class="text-white text-2xl mb-4">
              Send {{ gameDraft.title }} to GAIMIN Review
            </h2>
            <div
              class="flex gap-2 mb-4 justify-content-center align-items-center"
            >
              <p-checkbox
                [binary]="true"
                inputId="user-consent"
                formControlName="userConsent"
              />
              <p class="text-gray-400 text-md">
                By checking, you agree to our
                <a
                  class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
                  (click)="onOpenDistributionAgreement()"
                >
                  Distribution Agreement
                </a>
              </p>
            </div>
            <p-button
              [disabled]="!isFormValid"
              class="p-button-primary p-button-md"
              (onClick)="onSubmit()"
            >
              Send to review
            </p-button>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </form>
  @if (activeIndex !== 4) {

  <div
    class="p-2 bottom-0 right-0 fixed w-full"
    [ngStyle]="{
    'background-color': '#0A0118',
  }"
  >
    <div
      class="m-auto flex justify-content-end gap-2"
      [ngStyle]="{ 'max-width': '1000px' }"
    >
      @if (!isFormDisabled) {
      <p-button
        severity="secondary"
        label="Save in draft"
        (onClick)="saveGameDraft(gameDraft.id).subscribe()"
      ></p-button>
      }

      <p-button
        label="Next step"
        (onClick)="activeIndex = activeIndex + 1"
      ></p-button>
    </div>
  </div>

  }

  <img
    class="absolute left-0 w-full rotate-180"
    [ngStyle]="{ top: '0px', 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />

  <img
    class="absolute left-0 bottom-0 w-full"
    [ngStyle]="{ 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />
</div>
}
<app-body-background-setter></app-body-background-setter>
