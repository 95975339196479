import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameData } from '../../../../tools/interfaces/response.interfaces';
import { AdminService } from '../../../../shared/services/admin/admin.service';

@Component({
  selector: 'app-admin-game-legal-tab',
  standalone: true,
  imports: [],
  templateUrl: './admin-game-legal-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminGameLegalTabComponent {
  @Input() gameData!: GameData;

  constructor(public adminService: AdminService) {}
}
