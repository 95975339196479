<div class="flex flex-column justify-content-center align-items-center">
  <img
    class="absolute-centered opacity-30"
    src="./assets/illustrations/new-game.png"
    alt=""
  />
  <img
    class="z-5 mb-6 w-12rem h-12rem"
    [src]="addedGame?.iconUri"
    alt=""
    [ngStyle]="{ 'margin-top': '16rem' }"
  />
  <h2 class="z-5 text-4xl font-medium line-height-2 text-center mt-0 mb-2">
    {{ addedGame?.title }} is added
  </h2>

  <p class="z-5 mb-4 mt-0 text-2xl font-normal">
    Go through GAIMIN review process to publish the game in our Platform.
  </p>
  <div class="flex gap-3">
    <p-button
      (onClick)="redirectHome()"
      label="Home"
      severity="secondary"
      size="large"
    >
    </p-button>
    <p-button
      (onClick)="redirectReview()"
      label="Go to GAIMIN review"
      severity="primary"
      size="large"
    >
    </p-button>
  </div>
</div>

<app-body-background-setter></app-body-background-setter>
