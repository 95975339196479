import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { NgStyle } from '@angular/common';
import { NOTIFICATION } from './tools/constants/notification.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterModule, ToastModule, NgStyle],
})
export class AppComponent {
  NOTIFICATION: typeof NOTIFICATION = NOTIFICATION;
}
