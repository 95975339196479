import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { GameData } from '../../../tools/interfaces/response.interfaces';

import { GAME_STATUS } from '../../../tools/constants/game.constants';

@Injectable({
  providedIn: 'root',
})
export class AdminRequestsService {
  constructor(private http: HttpClient) {}

  getGamesForReviewRequest(params: HttpParams) {
    return this.http.get<GameData[]>(
      `${environment.devportalApi}/api/game/review`,
      {
        params,
      }
    );
  }

  approveGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.APPROVED,
      }
    );
  }

  rejectGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.REJECTED,
      }
    );
  }

  flagGameForRevisionsRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.REVISIONS_REQUIRED,
      }
    );
  }

  sendFeedbackRequest(id: number, feedback: string) {
    return this.http.put(
      `${environment.devportalApi}/api/game/review/feedback`,
      {
        gameId: id,
        review: feedback,
      }
    );
  }

  publishGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/publish/${id}`,
      {}
    );
  }

  unpublishGameRequest(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/unpublish/${id}`,
      {}
    );
  }

  deleteGameRequest(id: number) {
    return this.http.delete<GameData>(
      `${environment.devportalApi}/api/game/delete/${id}`,
      {}
    );
  }

  resetGameStatusRequest(id: number, targetStatus: GAME_STATUS) {
    return this.http.put(
      `${environment.devportalApi}/api/game/status/reset/${id}`,
      {
        targetStatus,
      }
    );
  }
}
