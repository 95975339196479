<div class="px-4 pt-4 flex flex-column align-items-center justify-content-center">
  <h2 class="mb-3">Download the GAIMIN Platform - Required for the Referral Program</h2>

  <ol class="mb-6">
    <li class="text-xl">
      Download the platform using
      <a
        class="text-primary font-normal no-underline hover:text-primary-300"
        href="https://www.gaimin.gg/download/steps"
        target="_blank"
        >this link</a
      >.
    </li>
    <li class="text-xl">Install and launch the platform.</li>
    <li class="text-xl">
      Log in or sign up.
    </li>
    <li class="text-xl">
      Return to the referral page and click "Connect".
    </li>
  </ol>

  <p-button
    [ngStyle]="{'width': '300px'}"
    label="Connect"
    class="p-fluid block"
    (click)="connectToReferal()"
  ></p-button>
</div>
