import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  NonNullableFormBuilder,
} from '@angular/forms';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DialogService,
} from 'primeng/dynamicdialog';
import { TEXT } from '../../../tools/constants/pattern.constants';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { AuthRequestsService } from '../../services/auth/auth-requests.service';
import { OrderSummaryDialogComponent } from '../order-summary-dialog/order-summary-dialog.component';

@Component({
  selector: 'app-company-information-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputMaskModule,
    ValidationMessageDirective,
  ],
  templateUrl: './company-information-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyInformationDialogComponent implements OnInit {
  companyInformationForm: FormGroup = this.fb.group({
    companyName: ['', [Validators.required, Validators.pattern(TEXT)]],
    companyAddress: ['', [Validators.required, Validators.pattern(TEXT)]],
    representativeName: ['', [Validators.required, Validators.pattern(TEXT)]],
    representativeEmail: ['', [Validators.required, Validators.email]],
    representativePhone: ['', [Validators.pattern(TEXT)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private fb: NonNullableFormBuilder,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private authRequestsService: AuthRequestsService,
    private cdr: ChangeDetectorRef
  ) {
    this.config.header = 'Provide company information';
    this.config.contentStyle = {
      'background-color': '#18181E',
    };
    this.config.style = {
      width: '100%',
      'max-width': '50rem',
    };
  }
  ngOnInit(): void {
    this.getCurrentUserInfo();
  }

  private getCurrentUserInfo() {
    return this.authRequestsService
      .getUserInfoRequest()
      .subscribe((userInfo) => {
        const { registeredAddress, gaiminAuthUser, companyName, phoneNumber } =
          userInfo;
        const { email, firstName, lastName } = gaiminAuthUser;

        this.companyInformationForm.patchValue({
          companyName,
          companyAddress: registeredAddress,
          representativeName: `${firstName} ${lastName}`,
          representativeEmail: email,
          representativePhone: phoneNumber,
        });

        this.cdr.detectChanges();
      });
  }

  onSubmit(): void {
    this.dialogService.open(OrderSummaryDialogComponent, {
      data: {
        bundle: this.config.data.bundle,
        userData: this.companyInformationForm.value
      },
    });
  }
}
