<div class="card p-3 mb-3">
  <div class="flex align-items-center justify-content-center mb-3">
    <i
      class="pi pi-exclamation-triangle mr-2"
      style="font-size: 2rem; color: orange"
    ></i>
    <h2 class="text-center">
      Action Required: Migrate Your GAIMIN Dev Portal Account
    </h2>
  </div>

  <p>
    To enhance account security and unify access to all GAIMIN products and
    services, we’re introducing a single sign-on (SSO) system. Please complete
    the following steps to migrate your account:
  </p>

  <ol>
    <li>Sign in or sign up for GAIMIN SSO.</li>
    <li>
      Create a new Developer Portal account or sign in to your existing one.
    </li>
    <li>
      Begin the migration process by entering the credentials for your old
      account (email and password associated with the account you want to
      migrate) in the designated migration dialog.
    </li>
  </ol>

  <p class="text-bold">IMPORTANT</p>
  <ul class="ml-4 p-0">
    <li>
      Secure Your Recovery Password: As part of this process, you'll create a
      recovery password for your smart wallet. GAIMIN cannot retrieve this
      password if it's lost—please store it safely, as you will be responsible
      for managing your wallet's security.
    </li>
  </ul>

  <p>Why It Matters:</p>
  <p>
    This migration will enhance your account's security and prepare it for
    upcoming GAIMIN services. Completing the process will ensure uninterrupted
    access and allow you to take full advantage of these new features.
  </p>

  <p>
    If you have questions, please reach out to our support team at:
    <a href="mailto:technical_support@gaimin.io">
      technical_support&#64;gaimin.io
    </a>
  </p>

  <div class="flex gap-2 mt-4">
    <p-button
      severity="primary"
      label="Sign In to GAIMIN SSO"
      class="p-fluid flex-1"
      (onClick)="authService.authActionRedirect(MIGRATE_ACCOUNT)"
    ></p-button>
    <p-button
      severity="secondary"
      label="Create GAIMIN SSO Account"
      class="p-fluid flex-1"
      (onClick)="authService.authActionRedirect(MIGRATE_AND_CREATE)"
    >
    </p-button>
  </div>
</div>
