import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GamesService } from '../../../shared/services/games/games.service';
import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { RemoveButtonDirective } from '../../../shared/directives/remove-button.directive';
import {
  GameData,
  GameDataResponse,
} from '../../../tools/interfaces/response.interfaces';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { GameRemovalConfirmationModalComponent } from '../../../shared/modals/game-removal-confirmation-modal/game-removal-confirmation-modal.component';
import { Router } from '@angular/router';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-home-games-list',
  standalone: true,
  imports: [
    NgStyle,
    NgFor,
    NgIf,
    AsyncPipe,
    TagModule,
    ConvertEnumFromBEPipe,
    RemoveButtonDirective,
    AccordionModule,
  ],
  templateUrl: './home-games-list.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeGamesListComponent {
  @Input() gamesList!: GameData[];
  @Input() duplicateGamesList!: GameData[];
  @Output() onGameRemoved: EventEmitter<unknown> = new EventEmitter();

  readonly GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;
  readonly resources: { title: string; description: string }[] = [
    {
      title: 'Getting started',
      description: 'Get advice and quick answers from the Intercom team',
    },
    {
      title: 'How to upload game files',
      description: 'Prepare game files to launch your game for players.',
    },
    {
      title: 'Legal documentation',
      description:
        'How to review and publish your terms of service and other legal info.',
    },
    {
      title: 'Social links',
      description: 'Find us on your favourite social media platforms.',
    },
    {
      title: 'Game support',
      description: 'Get help with troubleshooting and common issues.',
    },
    {
      title: 'How to update game files',
      description: 'Keep your game current with the latest patches.',
    },
    {
      title: 'How to unpublish a game',
      description:
        'Offer support in areas where your customers may need more help.',
    },
    {
      title: 'How to connect Gaimin API',
      description: 'Integrate your game with Gaimin`s features.',
    },
  ];

  constructor(
    private dialogService: DialogService,
    private router: Router,
    public gamesService: GamesService,
    public breakpointService: BreakpointService
  ) {}

  redirectToGameReview(game: GameData): void {
    switch (game.status) {
      case GAME_STATUS.CHANGES_REQUESTED:
      case GAME_STATUS.DRAFT:
        this.router.navigateByUrl(`/app/review/draft/${game.id}`);
        break;

      case GAME_STATUS.PENDING_REVIEW:
        this.router.navigateByUrl(`/app/review/pending/${game.id}`);
        break;

      default:
        this.router.navigateByUrl(`/app/review/${game.id}`);
        break;
    }
  }

  onRemoveGame(game: GameData): void {
    const { id, status } = game;
    const title: string = game.info?.title;

    this.dialogService.open(GameRemovalConfirmationModalComponent, {
      data: {
        id,
        status,
        title,
        onRemoval: this.emitGameRemovalEvent.bind(this),
      },
    });
  }

  onRedirect(link: string): void {
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
    }
  }

  redirectToGamePending(gameId: number): void {
    this.router.navigateByUrl(`/app/review/pending/${gameId}`);
  }

  redirectToGameDraft(gameId: number): void {
    this.router.navigateByUrl(`/app/review/draft/${gameId}`);
  }

  private emitGameRemovalEvent(): void {
    this.onGameRemoved.emit();
  }
}
