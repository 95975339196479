import { CurrencyPipe, NgFor, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FILE_SHARING_BUNDLE_NAME } from '../../tools/constants/file-sharing.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { ContactSalesComponent } from '../../shared/modals/contact-sales/contact-sales.component';
import { PaymentRequestsService } from '../../shared/services/payment-requests.service';
import { PrepaidBundle } from '../../tools/interfaces/response.interfaces';
import { CompanyInformationDialogComponent } from '../../shared/modals/company-information-dialog/company-information-dialog.component';

@Component({
  selector: 'app-file-sharing',
  standalone: true,
  imports: [NgStyle, NgFor, ButtonModule, CurrencyPipe],
  styles: [
    `
      .custom-li {
        display: flex;
        align-items: center;
        gap: 13px;

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url('/assets/icons/check.svg');
        }
      }
    `,
    `
      .most-popular {
        position: relative;
        width: fit-content;

        &::after {
          content: 'Most popular';
          font-weight: 500;
          font-size: 10px;
          text-transform: uppercase;
          color: #fff;
          border-radius: 6px;
          padding: 4px;
          background-color: #818181;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -90px;
          white-space: nowrap;
        }
      }
    `,
  ],
  templateUrl: './file-sharing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSharingComponent implements OnInit {
  public FILE_SHARING_BUNDLE_NAME = FILE_SHARING_BUNDLE_NAME;

  public benefits = [
    { icon: '../../../assets/icons/file-sharing/secure.png', text: 'Secure' },
    {
      icon: '../../../assets/icons/file-sharing/scalable.png',
      text: 'Scalable',
    },
    {
      icon: '../../../assets/icons/file-sharing/affordable.png',
      text: 'Affordable',
    },
  ];

  public bundles: PrepaidBundle[] = [];

  constructor(
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private paymentRequestsService: PaymentRequestsService
  ) {}

  ngOnInit(): void {
    this.paymentRequestsService.getPrepaidBundles().subscribe((res) => {
      this.bundles = res;
      this.cdr.detectChanges();
    });
  }

  public onCardBtnClick(bundleName: string) {
    const bundle = this.bundles.find((bundle) => bundle.name === bundleName);

    switch (bundleName) {
      case FILE_SHARING_BUNDLE_NAME.BASIC:
      case FILE_SHARING_BUNDLE_NAME.PRO:
      case FILE_SHARING_BUNDLE_NAME.ENTERPRISE:
        this.dialogService.open(CompanyInformationDialogComponent, {
          data: {
            bundle,
          },
        });
        break;

      case FILE_SHARING_BUNDLE_NAME.CUSTOM:
        this.dialogService.open(ContactSalesComponent, {});
        break;
    }
  }
}
