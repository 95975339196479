export const TEXT = /^[a-zA-Z0-9\s@;'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
export const QUESTION_TEXT = /^(?=.*[a-zA-Z])(?=.*\S)(?![а-яА-ЯіІїЇєЄ]).*$/;

// TODO: make a proper regex for password
export const PASSWORD = /^[a-zA-Z0-9\s@;'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

export const WEBSITE =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;
export const YOUTUBE_LINK =
  /^(https?\:\/\/)?(www\.)?youtube\.com\/(channel\/|c\/|user\/|@)[a-zA-Z0-9_-]+(\/videos)?$/;
export const TWITTER_LINK =
  /^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/([a-zA-Z0-9_]{1,15})\/?$/;
export const DISCORD_LINK =
  /^(https?:\/\/)?(www\.)?(discord\.(gg|com)\/(invite\/)?)?[a-zA-Z0-9]{2,}$/;
export const TELEGRAM_LINK =
  /^(https?:\/\/)?(www\.)?t\.me\/(@?[a-zA-Z0-9_]{5,})$/;
export const INSTAGRAM_LINK =
  /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9._]{1,30})\/?$/;
