import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from '../../../shared/modals/support-dialog/support-dialog.component';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styles: [],
  standalone: true,
  imports: [NgStyle, RouterModule, LoaderComponent, ButtonModule],
})
export class AuthLayoutComponent {
  constructor(public dialogService: DialogService) {}

  onOpenSupportDialog(): void {
    this.dialogService.open(SupportDialogComponent, {});
  }
}
