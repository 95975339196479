import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Directive({
  selector: '[appRemoveButton]',
  standalone: true,
})
export class RemoveButtonDirective implements OnInit {
  @Output() imageRemoved: EventEmitter<void> = new EventEmitter<void>();
  @Input('appRemoveButton') showButton?: boolean | string = true;
  @Input() buttonStyles: { [key: string]: any } = {};

  private buttonElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.showButton || this.showButton === '') {
      this.createRemoveButton();
    }
  }

  private createRemoveButton(): void {
    this.buttonElement = this.renderer.createElement('div');

    this.applyButtonStyles();
    this.applyCustomStyles();

    const icon = this.renderer.createElement('img');
    this.renderer.setAttribute(icon, 'src', './assets/icons/close-icon.svg');
    this.renderer.setAttribute(icon, 'alt', 'Remove icon');

    this.renderer.appendChild(this.buttonElement, icon);
    this.renderer.appendChild(this.el.nativeElement, this.buttonElement);

    this.initializeClickListener();
  }

  private applyButtonStyles(): void {
    this.renderer.setStyle(this.buttonElement, 'position', 'absolute');
    this.renderer.setStyle(this.buttonElement, 'top', '5%');
    this.renderer.setStyle(this.buttonElement, 'left', '5%');
    this.renderer.setStyle(this.buttonElement, 'width', '2rem');
    this.renderer.setStyle(this.buttonElement, 'height', '2rem');
    this.renderer.setStyle(
      this.buttonElement,
      'background-color',
      'rgba(0, 0, 0, 0.5)'
    );
    this.renderer.setStyle(this.buttonElement, 'display', 'flex');
    this.renderer.setStyle(this.buttonElement, 'justify-content', 'center');
    this.renderer.setStyle(this.buttonElement, 'align-items', 'center');
    this.renderer.setStyle(this.buttonElement, 'cursor', 'pointer');
    this.renderer.setStyle(this.buttonElement, 'border-radius', '0.5rem');
  }

  private initializeClickListener(): void {
    this.renderer.listen(this.buttonElement, 'click', (event: MouseEvent) => {
      event.stopPropagation();
      this.onRemoveIcon();
    });
  }

  private applyCustomStyles(): void {
    if (this.buttonStyles) {
      Object.keys(this.buttonStyles).forEach((style) => {
        this.renderer.setStyle(
          this.buttonElement,
          style,
          this.buttonStyles[style]
        );
      });
    }
  }

  private onRemoveIcon(): void {
    this.imageRemoved.emit();
  }
}
