<div class="">
  <h2 class="text-white text-2xl my-3">Suggestions for Improvement</h2>

  <p
    class="text-gray-200 text-xl line-height-3 mb-5 custom-scroll overflow-y-auto"
    [ngStyle]="{ maxHeight: '51vh' }"
  >
    {{ feedback }}
  </p>

  <div class="flex gap-2 justify-content-end">
    <p-button
      severity="primary"
      label="Close"
      (onClick)="onCancel()"
    ></p-button>
  </div>
</div>
