import { Component } from '@angular/core';
import { GamesService } from '../../shared/services/games.service';
import { TagModule } from 'primeng/tag';
import { Router, RouterModule } from '@angular/router';
import { ConvertEnumFromBEPipe } from '../../shared/pipes/convert-enum-from-be.pipe';
import { NgStyle } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { GameData } from '../../tools/interfaces/response.interfaces';
import { GameRemovalConfirmationModalComponent } from '../../shared/modals/game-removal-confirmation-modal/game-removal-confirmation-modal.component';
import { GAME_STATUS } from '../../tools/constants/game.constants';
import { RemoveButtonDirective } from '../../shared/directives/remove-button.directive';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [
    TagModule,
    RouterModule,
    ConvertEnumFromBEPipe,
    NgStyle,
    RemoveButtonDirective,
  ],
})
export class HomeComponent {
  readonly GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  readonly homeBanners = [
    {
      image: './assets/illustrations/home/home-banner-1.png',
      smallImage: './assets/illustrations/home/home-banner-small-1.png',
      title: 'Add game',
      link: 'Submit form to publish game',
      route: this.gamesService.gamesList.length
        ? '/app/new-game'
        : '/app/add-first-game',
    },
    {
      image: './assets/illustrations/home/home-banner-2.png',
      smallImage: './assets/illustrations/home/home-banner-small-2.png',
      title: 'Developer Hub',
      link: 'Find detailed information',
      route: '/app/documentation',
    },
    {
      image: './assets/illustrations/home/home-banner-3.png',
      smallImage: './assets/illustrations/home/home-banner-small-3.png',
      title: 'Public API',
      link: 'Full list of our public API’s',
      route: '/app/documentation/api',
    },
    {
      image: './assets/illustrations/home/home-banner-4.png',
      smallImage: './assets/illustrations/home/home-banner-small-4.png',
      title: 'Community',
      link: 'Join the GAIMIN community',
      route: 'https://linktr.ee/gaimin.io',
    },
  ];

  constructor(
    private dialogService: DialogService,
    public router: Router,
    public gamesService: GamesService
  ) {}

  ngOnInit(): void {
    this.gamesService.getUsersGames().subscribe();
  }

  redirectToGameReview(gameId: number): void {
    this.router.navigateByUrl(`/app/review/${gameId}`);
  }

  onRedirect(link: string): void {
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
    }
  }

  onRemoveGame(game: GameData): void {
    const { id, status, title } = game;

    this.dialogService.open(GameRemovalConfirmationModalComponent, {
      data: {
        id,
        status,
        title,
      },
    });
  }
}
