import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Response } from '../../tools/interfaces/response.interfaces';
import { ReferalDialogComponent } from '../modals/referal-dialog/referal-dialog.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { NOTIFICATION } from '../../tools/constants/notification.constants';

@Injectable({
  providedIn: 'root',
})
export class ReferalService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private dialogService: DialogService
  ) {}

  private isConnectedToReferal(devPortalJWT: string) {
    return this.http.get<{data: boolean}>(
      `${environment.gaiminApi}/api/users/devportal/already-connected?devPortalJWT=${devPortalJWT}`
    );
  }

  // Opens the platform if it is installed, or the modal window if not. Used timeout to catch an error in window.location.href
  private handlePlatformOpen(devPortalJWT: string) {
    const timeout = setTimeout(() => {
      this.dialogService.open(ReferalDialogComponent, {});
    }, 1000);

    window.location.href = `gaimin://connect?${devPortalJWT}`;

    window.addEventListener('blur', () => {
      clearTimeout(timeout);
    });
  }

  connectToReferal(): void {
    const devPortalJWT = localStorage.getItem('accessToken') || '';

    this.isConnectedToReferal(devPortalJWT).subscribe((referalResponse) => {
      if (referalResponse.data === false) {
        this.handlePlatformOpen(devPortalJWT);
      } else {
        this.messageService.add({
          severity: NOTIFICATION.ERROR,
          summary: 'Error',
          detail: 'This user is already connected to the referral system',
        });
      }
    });
  }
}
