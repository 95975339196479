import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TableModule } from 'primeng/table';
import {
  SortField,
  SortState,
} from '../../../tools/constants/referral.constants';
import { ReferralUserWithFullName } from '../../../tools/interfaces/response.interfaces';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-referral-overview',
  standalone: true,
  imports: [TableModule, DatePipe],
  templateUrl: './referral-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralOverviewComponent implements OnChanges {
  @Input() overviewData!: ReferralUserWithFullName[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['overviewData']) {
      const currentValue = changes['overviewData'].currentValue;

      this.tableData = [...currentValue];
      this._originalTableData = [...currentValue];
    }
  }

  public tableData: ReferralUserWithFullName[] = [];
  private _originalTableData: ReferralUserWithFullName[] = [];
  public itemsPerPage = 10;

  public get isPaginationVisible() {
    return this.tableData.length > this.itemsPerPage;
  }

  public get isReferralUsers() {
    return this.tableData.length > 0;
  }

  private _sortStateName: SortState = SortState.ORIGINAL;
  private _sortStateGmrx: SortState = SortState.ORIGINAL;
  private _sortStateRegisterDate: SortState = SortState.ORIGINAL;

  public get isSortstateNameDesc(): boolean {
    return this._sortStateName === SortState.DESC;
  }

  public get isSortstateNameAsc(): boolean {
    return this._sortStateName === SortState.ASC;
  }

  public get isSortstateNameOriginal(): boolean {
    return this._sortStateName === SortState.ORIGINAL;
  }

  public get isSortstateGmrxDesc(): boolean {
    return this._sortStateGmrx === SortState.DESC;
  }

  public get isSortstateGmrxAsc(): boolean {
    return this._sortStateGmrx === SortState.ASC;
  }

  public get isSortstateGmrxOriginal(): boolean {
    return this._sortStateGmrx === SortState.ORIGINAL;
  }

  public get isSortstateRegisterDateDesc(): boolean {
    return this._sortStateRegisterDate === SortState.DESC;
  }

  public get isSortstateRegisterDateAsc(): boolean {
    return this._sortStateRegisterDate === SortState.ASC;
  }

  public get isSortstateRegisterDateOriginal(): boolean {
    return this._sortStateRegisterDate === SortState.ORIGINAL;
  }

  private _getNextSortState(currentState: SortState): SortState {
    switch (currentState) {
      case SortState.DESC:
        return SortState.ORIGINAL;
      case SortState.ASC:
        return SortState.DESC;
      case SortState.ORIGINAL:
        return SortState.ASC;
    }
  }

  private _sortData(field: SortField): void {
    switch (field) {
      case SortField.NAME:
        this._sortTableData(
          this._sortStateName,
          (a: ReferralUserWithFullName, b: ReferralUserWithFullName) => {
            return a.fullName.localeCompare(b.fullName);
          }
        );
        break;

      case SortField.GMRX:
        this._sortTableData(
          this._sortStateGmrx,
          (a: ReferralUserWithFullName, b: ReferralUserWithFullName) => {
            return a.reward - b.reward;
          }
        );
        break;

      case SortField.REGISTER_DATE:
        this._sortTableData(
          this._sortStateRegisterDate,
          (a: ReferralUserWithFullName, b: ReferralUserWithFullName) => {
            return +new Date(a.referralDate) - +new Date(b.referralDate);
          }
        );
        break;
    }
  }

  private _sortTableData(
    state: SortState,
    compareFn: (a: any, b: any) => number
  ): void {
    if (this.tableData) {
      switch (state) {
        case SortState.DESC:
          this.tableData.sort((a, b) => compareFn(b, a)); // Sort descending
          break;
        case SortState.ASC:
          this.tableData.sort(compareFn); // Sort ascending
          break;
        case SortState.ORIGINAL:
          this.tableData = [...this._originalTableData!];
          break;
      }
    }
  }

  public sortByName(): void {
    this._sortStateName = this._getNextSortState(this._sortStateName);
    this._sortData(SortField.NAME);
  }

  public sortByGmrx(): void {
    this._sortStateGmrx = this._getNextSortState(this._sortStateGmrx);
    this._sortData(SortField.GMRX);
  }

  public sortByRegisterDate(): void {
    this._sortStateRegisterDate = this._getNextSortState(
      this._sortStateRegisterDate
    );
    this._sortData(SortField.REGISTER_DATE);
  }
}
