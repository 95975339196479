import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';
import { environment } from '../environments/environment';
import { Response } from '../app/tools/interfaces/response.interfaces';
import { NOTIFICATION } from '../app/tools/constants/notification.constants';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const messageService = inject(MessageService);
  const errorDescriptionBlacklist: string[] = ['Game manifest not found'];

  return next(req).pipe(
    tap((event) => {
      if (!(event instanceof HttpResponse)) return;

      if (!event.url?.includes(environment.devportalApi)) return;

      const responseBody = event.body as Response<unknown>;

      if (
        responseBody.error &&
        !errorDescriptionBlacklist.includes(responseBody.error.description)
      ) {
        messageService.add({
          severity: NOTIFICATION.ERROR,
          summary: responseBody.error.type,
          detail: responseBody.error.description,
        });
      }
    })
  );
};
