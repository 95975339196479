import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Response } from '../app/tools/interfaces/response.interfaces';
import { environment } from '../environments/environment';

export const httpInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    map((event) => {
      if (!(event instanceof HttpResponse)) return event;

      if (!event.url?.includes(environment.devportalApi)) return event;

      const responseBody = event.body as Response<unknown>;

      if (responseBody.error) {
        throw responseBody.error;
      }

      return event.clone({ body: responseBody?.data ?? event.body });
    })
  );
};
