import { CurrencyPipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-calculator',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    NgStyle,
    CurrencyPipe,
  ],
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
  templateUrl: './calculator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatorComponent {
  calculatorForm: FormGroup = this.fb.group({
    capacity: [''],
  });

  public gaiminPrice = 0;
  public awsPrice = 0;

  public get savingsPercentage() {
    return Math.ceil(
      Math.abs(((this.awsPrice - this.gaiminPrice) / this.awsPrice) * 100)
    );
  }

  private _calculatePrices(capacity: number): void {
    let gaiminPricePerGb;
    let awsPricePerGb;

    if (capacity <= 10000) {
      gaiminPricePerGb = 0.035;
      awsPricePerGb = 0.09;
    } else if (capacity <= 50000) {
      gaiminPricePerGb = 0.033;
      awsPricePerGb = 0.085;
    } else {
      gaiminPricePerGb = 0.03;
      awsPricePerGb = 0.07;
    }

    this.gaiminPrice = gaiminPricePerGb * capacity;
    this.awsPrice = awsPricePerGb * capacity;
  }

  constructor(private fb: FormBuilder) {}

  public onSubmit() {
    const formCapacity = this.calculatorForm.get('capacity');

    formCapacity?.setValue(Math.abs(formCapacity.value));
    this._calculatePrices(formCapacity?.value);
  }
}
