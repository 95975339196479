import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Table, TableModule, TableService } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { DropdownModule } from 'primeng/dropdown';
import { GamesService } from '../../../shared/services/games/games.service';
import { InputTextModule } from 'primeng/inputtext';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';
import { BlobReader, Entry, ZipReader } from '@zip.js/zip.js';
import { ControlsOf } from '../../../tools/interfaces/form.interfaces';
import { GameUploadService } from '../../../shared/services/games/game-upload.service';
import { from } from 'rxjs';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-game-access-subform',
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TableModule,
    TreeTableModule,
    DropdownModule,
    InputTextModule,
    ValidationMessageDirective,
  ],
  templateUrl: './game-access-subform.component.html',
  providers: [Table, TableService],
})
export class GameAccessSubformComponent {
  @Input() installationForm!: FormGroup<
    ControlsOf<{
      archive: File | null;
      executablePath: string;
    }>
  >;
  @Input() externalLinkForm!: FormGroup<ControlsOf<{ url: string }>>;
  @Input() gameId!: number;
  @Input() filesPreviouslyUploaded?: boolean | null;

  selectedGameArchiveName?: string;
  gameExeList?: string[];

  constructor(
    private gameUploadService: GameUploadService,
    public gamesService: GamesService,
    private cdr: ChangeDetectorRef
  ) {}

  onSelectArchive(event: Event): void {
    const gameArchive: File | null =
      (event.target as HTMLInputElement)?.files?.[0] ?? null;

    if (!gameArchive) return;

    this.selectedGameArchiveName = gameArchive.name;
    this.installationForm.patchValue({
      archive: gameArchive,
    });

    this.getExeFilesList(gameArchive).then((exeList) => {
      this.gameExeList = exeList;
      this.cdr.detectChanges();
    });
    this.filesPreviouslyUploaded = false;
  }

  onRemoveArchive(): void {
    this.installationForm.patchValue({
      archive: null,
      executablePath: '',
    });
    this.selectedGameArchiveName = '';
  }

  private async getExeFilesList(archive: File): Promise<string[]> {
    const zipReader: ZipReader<unknown> = new ZipReader(
      new BlobReader(archive)
    );
    const entries: Entry[] = await zipReader.getEntries();
    const exeList: string[] = entries
      .map((entry) => entry.filename)
      .filter((filename) => filename.endsWith('.exe'));

    return exeList;
  }

  uploadGameArchive(gameId: number) {
    const gameArchive: File | null =
      this.installationForm.controls['archive'].value;
    const relativeExePath: string =
      this.installationForm.controls['executablePath'].value;

    if (!(gameArchive instanceof File) || !relativeExePath) return from('');

    return this.gameUploadService.uploadGameArchiveByChunks(
      gameId,
      gameArchive,
      relativeExePath
    );
  }
}
