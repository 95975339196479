import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgStyle } from '@angular/common';
import { ReferralStatisticComponent } from './referral-statistic/referral-statistic.component';
import { ReferralOverviewComponent } from './referral-overview/referral-overview.component';
import { TabViewModule } from 'primeng/tabview';
import { ReferralStartComponent } from './referral-start/referral-start.component';
import { ReferralRequestsService } from '../../shared/services/referral-requests.service';
import {
  ReferralUserWithFullName,
  UserNep,
} from '../../tools/interfaces/response.interfaces';
import {
  interval,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  BehaviorSubject,
  filter,
  forkJoin,
} from 'rxjs';
import { ReferralInviteComponent } from './referral-invite/referal-invite.component';
import { AuthRequestsService } from '../../shared/services/auth/auth-requests.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ReferralDialogComponent } from '../../shared/modals/referral-dialog/referral-dialog.component';

@Component({
  selector: 'app-referral',
  standalone: true,
  imports: [
    ReferralInviteComponent,
    NgStyle,
    ReferralStatisticComponent,
    ReferralOverviewComponent,
    TabViewModule,
    ReferralStartComponent,
  ],
  templateUrl: './referral.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ReferralRequestsService],
})
export class ReferralComponent implements OnInit, OnDestroy {
  public overviewData: ReferralUserWithFullName[] = [];
  public userNep: UserNep | null = null;
  public isOverviewDataLoaded = false;
  private _destroy$ = new Subject<void>();
  private _secondsUpdateDelay = 30;
  private _isConnectedToReferral$ = new BehaviorSubject<boolean | null>(null);
  private _isAccountMigrated$ = new BehaviorSubject<boolean | null>(null);

  public get isConnectedToReferral() {
    return this._isConnectedToReferral$.value;
  }

  public get isAccountMigrated() {
    return this._isAccountMigrated$.value;
  }

  constructor(
    private referralRequestsService: ReferralRequestsService,
    private authRequestsService: AuthRequestsService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.referralRequestsService
      .checkReferralConnectionRequest()
      .subscribe((res) => {
        this._isConnectedToReferral$.next(res);
      });

    this.authRequestsService.getPlatformUserInfoRequest().subscribe({
      next: () => {
        this._isAccountMigrated$.next(true);
      },
      error: () => {
        this._isAccountMigrated$.next(false);

        this.dialogService.open(ReferralDialogComponent, {})
      },
    });

    this._isConnectedToReferral$
      .pipe(
        filter((isConnected) => isConnected === true),
        switchMap(() =>
          interval(1000 * this._secondsUpdateDelay).pipe(
            startWith(0),
            switchMap(() => {
              return forkJoin({
                userReferrals:
                  this.referralRequestsService.getUserReferralsRequest(),
                userNep: this.referralRequestsService.getUserStatisticRequest(),
              });
            })
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(({ userReferrals, userNep }) => {
        this.overviewData = userReferrals.map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }));

        this.userNep = userNep;
        this.isOverviewDataLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onConnectedToReferral() {
    this._isConnectedToReferral$.next(true);
    this._isAccountMigrated$.next(true);
  }
}
