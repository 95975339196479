import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgStyle } from '@angular/common';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  standalone: true,
  imports: [
    LayoutSidebarComponent,
    NgStyle,
    RouterModule,
    LoaderComponent,
    HeaderComponent,
  ],
})
export class AppLayoutComponent {}
