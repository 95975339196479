<router-outlet></router-outlet>
<p-toast position="bottom-right" class="flex flex-column gap-2">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-center gap-3 flex-1 p-0">
      <div
        class="flex align-items-center justify-content-center flex-shrink-0 filter-white"
      >
        @switch (message.severity) { @case (NOTIFICATION.SUCCESS) {
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5116 18H6.48837C1.94233 18 0 16.0577 0 11.5116V6.48837C0 1.94233 1.94233 0 6.48837 0H11.5116C16.0577 0 18 1.94233 18 6.48837V11.5116C18 16.0577 16.0577 18 11.5116 18ZM6.48837 1.25581C2.62884 1.25581 1.25581 2.62884 1.25581 6.48837V11.5116C1.25581 15.3712 2.62884 16.7442 6.48837 16.7442H11.5116C15.3712 16.7442 16.7442 15.3712 16.7442 11.5116V6.48837C16.7442 2.62884 15.3712 1.25581 11.5116 1.25581H6.48837Z"
            fill="#21A66E"
          />
          <path
            d="M6.33679 5.23458C7.04842 5.23458 7.76005 5.50249 8.30424 6.04668C8.54703 6.28947 8.54703 6.69133 8.30424 6.93412C8.06144 7.17691 7.65958 7.17691 7.41679 6.93412C6.82237 6.3397 5.85121 6.3397 5.25679 6.93412C5.014 7.17691 4.61214 7.17691 4.36935 6.93412C4.12656 6.69133 4.12656 6.28947 4.36935 6.04668C4.91354 5.51086 5.62517 5.23458 6.33679 5.23458Z"
            fill="#21A66E"
          />
          <path
            d="M11.663 5.23458C12.3746 5.23458 13.0862 5.50249 13.6304 6.04668C13.8732 6.28947 13.8732 6.69133 13.6304 6.93412C13.3876 7.17691 12.9858 7.17691 12.743 6.93412C12.1485 6.3397 11.1774 6.3397 10.583 6.93412C10.3402 7.17691 9.93831 7.17691 9.69552 6.93412C9.45273 6.69133 9.45273 6.28947 9.69552 6.04668C10.2397 5.51086 10.9513 5.23458 11.663 5.23458Z"
            fill="#21A66E"
          />
          <path
            d="M9.00082 14.9862C6.57291 14.9862 4.60547 13.0104 4.60547 10.5909C4.60547 9.82901 5.225 9.20947 5.98686 9.20947H12.0148C12.7766 9.20947 13.3962 9.82901 13.3962 10.5909C13.3962 13.0104 11.4287 14.9862 9.00082 14.9862ZM5.98686 10.4653C5.91989 10.4653 5.86128 10.5239 5.86128 10.5909C5.86128 12.3239 7.26779 13.7304 9.00082 13.7304C10.7338 13.7304 12.1404 12.3239 12.1404 10.5909C12.1404 10.5239 12.0817 10.4653 12.0148 10.4653H5.98686Z"
            fill="#21A66E"
          />
        </svg>
        } @case (NOTIFICATION.ERROR) {
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5116 18H6.48837C1.94233 18 0 16.0577 0 11.5116V6.48837C0 1.94233 1.94233 0 6.48837 0H11.5116C16.0577 0 18 1.94233 18 6.48837V11.5116C18 16.0577 16.0577 18 11.5116 18ZM6.48837 1.25581C2.62884 1.25581 1.25581 2.62884 1.25581 6.48837V11.5116C1.25581 15.3712 2.62884 16.7442 6.48837 16.7442H11.5116C15.3712 16.7442 16.7442 15.3712 16.7442 11.5116V6.48837C16.7442 2.62884 15.3712 1.25581 11.5116 1.25581H6.48837Z"
            fill="#EE1C1C"
          />
          <path
            d="M10.1392 5.0256C10.2983 5.0256 10.4574 5.0842 10.583 5.20978C11.1774 5.8042 12.1485 5.8042 12.743 5.20979C12.9858 4.96699 13.3876 4.96699 13.6304 5.20979C13.8732 5.45258 13.8732 5.85444 13.6304 6.09723C12.5504 7.17723 10.7839 7.17723 9.69552 6.09723C9.45273 5.85444 9.45273 5.45258 9.69552 5.20978C9.8211 5.0842 9.98017 5.0256 10.1392 5.0256Z"
            fill="#EE1C1C"
          />
          <path
            d="M7.86051 5.0256C8.01958 5.0256 8.17865 5.0842 8.30424 5.20979C8.54703 5.45258 8.54703 5.85444 8.30424 6.09723C7.22424 7.17723 5.45772 7.17723 4.36935 6.09723C4.12656 5.85444 4.12656 5.45258 4.36935 5.20978C4.61214 4.96699 5.014 4.96699 5.25679 5.20978C5.85121 5.8042 6.82237 5.8042 7.41679 5.20979C7.534 5.0842 7.69307 5.0256 7.86051 5.0256Z"
            fill="#EE1C1C"
          />
          <path
            d="M12.0148 14.3998H5.98686C5.225 14.3998 4.60547 13.7803 4.60547 13.0184C4.60547 10.5989 6.57291 8.62305 9.00082 8.62305C11.4287 8.62305 13.3962 10.5989 13.3962 13.0184C13.3962 13.7803 12.7766 14.3998 12.0148 14.3998ZM9.00082 9.87886C7.26779 9.87886 5.86128 11.2854 5.86128 13.0184C5.86128 13.0854 5.91989 13.144 5.98686 13.144H12.0148C12.0817 13.144 12.1404 13.0854 12.1404 13.0184C12.1404 11.2854 10.7338 9.87886 9.00082 9.87886Z"
            fill="#EE1C1C"
          />
        </svg>
        } @default {
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.48837 18C1.94233 18 0 16.0577 0 11.5116V6.48837C0 1.94233 1.94233 0 6.48837 0H11.5116C16.0577 0 18 1.94233 18 6.48837V11.5116C18 16.0577 16.0577 18 11.5116 18H6.48837ZM6.48837 1.25581C2.62884 1.25581 1.25581 2.62884 1.25581 6.48837V11.5116C1.25581 15.3712 2.62884 16.7442 6.48837 16.7442H11.5116C15.3712 16.7442 16.7442 15.3712 16.7442 11.5116V6.48837C16.7442 2.62884 15.3712 1.25581 11.5116 1.25581H6.48837ZM9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12.2968C9.75 12.7111 9.41421 13.0468 9 13.0468C8.58579 13.0468 8.25 12.7111 8.25 12.2968V9C8.25 8.58579 8.58579 8.25 9 8.25ZM9 4.95319C8.58579 4.95319 8.25 5.28897 8.25 5.70319C8.25 6.1174 8.58579 6.45319 9 6.45319H9.00667C9.42088 6.45319 9.75667 6.1174 9.75667 5.70319C9.75667 5.28897 9.42088 4.95319 9.00667 4.95319H9Z"
            fill="#5749EF"
          />
        </svg>

        }}
      </div>

      <div class="flex flex-column gap-1 text-white text-base">
        <span>{{ message.detail }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
