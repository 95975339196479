import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs';
import {
  GameData,
  GameDataResponse,
  GameInfo,
} from '../../../tools/interfaces/response.interfaces';
import { GameRequestsService } from './game-requests.service';
import {
  GAME_CHAINS,
  GAME_GENRES,
  GAME_STAGE,
  GAME_STATUS,
  RELEASE_STAGE,
} from '../../../tools/constants/game.constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private games: GameData[] = [];
  private gamesDraft: GameData[] = [];
  private gamesPendingReview: GameData[] = [];

  get gamesList(): GameData[] {
    return this.games.slice();
  }

  get gamesDraftList(): GameData[] {
    return this.gamesDraft.slice();
  }

  get gamesPendingReviewList(): GameData[] {
    return this.gamesPendingReview.slice();
  }

  set gamesList(gameData: GameData[]) {
    this.games = gameData;
  }

  set gamesDraftList(gameData: GameData[]) {
    this.gamesDraft = gameData;
  }

  set gamesPendingReviewList(gameData: GameData[]) {
    this.gamesPendingReview = gameData;
  }

  getGameById(id: number): GameData | null {
    return this.games.find((game) => game.id === id) || null;
  }

  getUsersGames(): Observable<GameDataResponse[]> {
    // TODO: use actual backend pagination
    const params: HttpParams = new HttpParams().append('size', 1_000_000);
    return this.gameRequestsService.getUsersGamesRequest(params).pipe(
      map((userGamesResponse) => {
        this.gamesList = userGamesResponse.content.map((game) => {
          return this.parseGameDataResponse(game);
        });

        this.gamesDraftList = userGamesResponse.content
          .filter((game) => {
            return (
              game.status === 'PUBLISHED' &&
              !game.info.review &&
              this.checkIfStagesContentDifferent(
                game.info.draft,
                game.info.release
              )
            );
          })
          .map((game) => {
            return this.parseGameDataResponse(game, GAME_STAGE.DRAFT);
          });

        forkJoin(
          this.gamesDraftList.map((game) =>
            this.gameRequestsService.getReviewFeedbackRequest(game.id).pipe(
              map((res) => {
                if (res && res.review) {
                  game.status = GAME_STATUS.CHANGES_REQUESTED;
                } else {
                  game.status = GAME_STATUS.DRAFT;
                }
                return game;
              }),
              catchError((error) => {
                game.status = GAME_STATUS.DRAFT;

                return of(game);
              })
            )
          )
        ).subscribe({
          next: (updatedGames) => {
            this.gamesDraftList = updatedGames;
          },
        });

        this.gamesPendingReviewList = userGamesResponse.content
          .filter(
            (game) =>
              game.status === 'PUBLISHED' &&
              this.checkIfStagesContentDifferent(
                game.info.review,
                game.info.release
              )
          )
          .map((game) => {
            return this.parseGameDataResponse(game, GAME_STAGE.REVIEW);
          })
          .map((game) => {
            game.status = GAME_STATUS.PENDING_REVIEW;

            return game;
          });

        return userGamesResponse.content;
      })
    );
  }

  constructor(private gameRequestsService: GameRequestsService) {}

  addNewGame(title: string, icon: string) {
    return this.gameRequestsService.createNewGameRequest(title, icon).pipe(
      tap((gameResponse) => {
        const parsedGameData: GameData =
          this.parseGameDataResponse(gameResponse);
        this.gamesList = [...this.games, parsedGameData];
      })
    );
  }

  uploadGameImages(images: File[] | Uint8Array[]): Observable<string[]> {
    const formData = new FormData();

    images.forEach((image) => {
      if (image instanceof File) {
        formData.append('files', image);
      } else if (image instanceof Uint8Array) {
        formData.append(
          'files',
          new Blob([image], {
            type: 'image/png',
          })
        );
      }
    });

    return this.gameRequestsService.uploadGameImagesRequest(formData);
  }

  checkIfStagesContentDifferent(
    stageContent1: GameInfo | null,
    stageContent2: GameInfo | null
  ) {
    if (stageContent1 === null || stageContent2 === null) {
      return false;
    }

    const stage1: GameInfo = JSON.parse(JSON.stringify(stageContent1));
    const stage2: GameInfo = JSON.parse(JSON.stringify(stageContent2));

    stage1.stage = GAME_STAGE.RELEASE;
    stage2.stage = GAME_STAGE.RELEASE;

    return JSON.stringify(stage1) !== JSON.stringify(stage2);
  }

  parseGameDataResponse(
    gameDataResponse: GameDataResponse,
    manualStage: GAME_STAGE | null = null
  ): GameData {
    const { id, developer, status } = gameDataResponse;
    const statusValue: GAME_STATUS = this.getEnumValue(GAME_STATUS, status);

    const targetStage = manualStage || this.getTargetStage(statusValue);
    const formattedStage = this._formatGameStateTitle(targetStage) as
      | 'draft'
      | 'release'
      | 'review'
      | 'readyForRelease';

    const {
      type,
      title,
      stage,
      imageUrls,
      iconUrl,
      shortDescription,
      fullDescription,
      genres,
      chains,
      backgroundImageUrl,
      bannerImageUrl,
      termsAndConditions,
      privacyPolicy,
      eula,
      websiteUrl,
      youtubeUrl,
      twitterUrl,
      discordUrl,
      telegramUrl,
      instagramUrl,
      supportEmail,
      supportWebsiteUrl,
      release,
    } = gameDataResponse.info[formattedStage] ?? {};
    const { date, stage: releaseStage } = release ?? {};
    const genresValue: GAME_GENRES[] =
      genres?.map((genre) => {
        return this.getEnumValue(GAME_GENRES, genre);
      }) || [];
    const chainsValue: GAME_CHAINS[] =
      chains?.map((chain) => {
        return this.getEnumValue(GAME_CHAINS, chain);
      }) || [];
    const releaseStageValue = releaseStage
      ? this.getEnumValue(RELEASE_STAGE, releaseStage)
      : undefined;

    return {
      id,
      status: statusValue,
      developer,
      type,
      info: {
        title: title ?? '',
        imageUris: imageUrls,
        iconUri: iconUrl ?? '',
        shortDescription,
        fullDescription,
        genres: genresValue,
        chains: chainsValue,
        backgroundImageUri: backgroundImageUrl,
        bannerImageUri: bannerImageUrl,
        releaseDate: date ? new Date(date) : null,
        releaseStage: releaseStageValue,
      },
      legal: {
        terms: termsAndConditions,
        license: eula,
        policy: privacyPolicy,
      },
      links: {
        websiteUrl,
        youtubeUrl,
        twitterUrl,
        discordUrl,
        telegramUrl,
        instagramUrl,
        supportWebsiteUrl,
        supportEmail,
      },
    };
  }

  private _formatGameStateTitle(targetStage: GAME_STAGE) {
    return targetStage
      .toLowerCase()
      .split('_')
      .map((word, index) =>
        index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
      )
      .join('') as 'draft' | 'release' | 'review' | 'readyForRelease';
  }

  public getTargetStage(gameStatus: GAME_STATUS): GAME_STAGE {
    switch (gameStatus) {
      case GAME_STATUS.REVIEW:
      case GAME_STATUS.REJECTED: {
        return GAME_STAGE.REVIEW;
      }
      case GAME_STATUS.DRAFT:
      case GAME_STATUS.CHANGES_REQUESTED: {
        return GAME_STAGE.DRAFT;
      }
      case GAME_STATUS.READY_FOR_PUBLISH:
      case GAME_STATUS.PUBLISHING: {
        return GAME_STAGE.READY_FOR_RELEASE;
      }
      default: {
        return GAME_STAGE.RELEASE;
      }
    }
  }

  getEnumKey<T extends object>(enumerable: T, value: T[keyof T]): keyof T {
    return Object.keys(enumerable).find(
      (key) => enumerable[key as keyof T] === value
    ) as keyof T;
  }

  getEnumValue<T extends object>(enumerable: T, key: keyof T): T[keyof T] {
    return enumerable[key];
  }
}
