import { Component } from '@angular/core';

import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../../shared/services/auth/auth.service';

import { AUTH_ACTION } from '../../tools/constants/auth.constants';
import { NgIf, NgStyle } from '@angular/common';
import { ProductsFooterComponent } from '../../shared/components/products-footer/products-footer.component';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from '../../shared/modals/support-dialog/support-dialog.component';
import { MigrationNoticeDialogComponent } from '../../shared/modals/migration-notice-dialog/migration-notice-dialog.component';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  standalone: true,
  imports: [NgStyle, ButtonModule, ProductsFooterComponent, RouterModule, NgIf],
})
export class StartComponent {
  public readonly SIGN_IN: AUTH_ACTION = AUTH_ACTION.SIGN_IN;
  public readonly SIGN_UP: AUTH_ACTION = AUTH_ACTION.SIGN_UP;
  public advantagesContent = [
    {
      title: 'P2P File Sharing',
      text: 'A secure, highly efficient, and affordable solution <br> designed to make file sharing seamless.',
      backgroundUrl:
        'url(../../../../assets/illustrations/sign-in/sharing.webp)',
      fractions: 8,
    },
    {
      title: 'Referral system',
      text: 'Get 30% for any referrals purchases. <br> The ability to earn bonuses.',
      backgroundUrl:
        'url(../../../../assets/illustrations/sign-in/referral.webp)',
      fractions: 4,
    },
    {
      title: 'All game analytics',
      text: 'Access comprehensive insights into game performance, player behavior, and engagement metrics.',
      backgroundUrl:
        'url(../../../../assets/illustrations/sign-in/analytics.webp)',
      fractions: 4,
    },
    {
      title: 'Support 24/7',
      text: 'We understand that every business is unique. <br /> Our services are tailored to fit your specific challenges.',
      backgroundUrl:
        'url(../../../../assets/illustrations/sign-in/support.webp)',
      fractions: 8,
    },
  ];

  showInitialPage: boolean = false;

  constructor(
    public authService: AuthService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.initiateStartPageRedirect();
  }

  public onOpenSupportDialog(event: Event): void {
    event.preventDefault();

    this.dialogService.open(SupportDialogComponent, {});
  }

  public onMigrationInfoClick() {
    this.dialogService.open(MigrationNoticeDialogComponent, {});
  }

  private initiateStartPageRedirect(): void {
    const activeLink: string = window.location.href;

    if (activeLink.includes('qa')) {
      window.location.href =
        'https://gaimin-gg-website-8a6d8ae767e00b67270c0.webflow.io/developer?theme=dark';
    } else if (activeLink.includes('localhost')) {
      this.showInitialPage = true;
    } else {
      window.location.href = 'https://www.gaimin.gg/developer?theme=dark';
    }
  }
}
