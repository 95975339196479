<p class="my-3">
  Gaimin values your privacy and is strongly committed to protecting your
  personal information.
</p>

<p class="my-3">
  This Privacy Policy tells you what to expect when we collect personal
  information from you. It also explains how we will store, handle and keep your
  personal information safe. This Privacy Policy (together with our Terms and
  Conditions and any additional terms of use, applies to your use of our
  websites, platform, applications, products, and services (regardless of where
  you visit us from).
</p>

<p class="my-3">
  We keep our Privacy Policy under regular review to make sure it is up to date
  and accurate.
</p>
<p class="my-3">
  It is important that the personal information we hold about you is accurate
  and current. Please keep us informed if your personal information changes
  during our relationship with you.
</p>

<h2 class="my-3">Important Information about who we are:</h2>

<p class="my-3">
  Our group of companies includes Gaimin Gladiators Ltd (Canada), GAIMIN.io Ltd
  (UK), GAIMIN LDA (Portugal) and GAIMIN AG (Switzerland) (collectively referred
  to as “our Group” in this policy).
</p>
<p class="my-3">
  GAIMIN LDA is the controller for the personal information we process unless
  otherwise stated and is responsible for your personal information,
  collectively referred to as “we”, “us,” “our” or “Gaimin”.
</p>

<h2 class="my-3">You can Contact us at:</h2>
<h3 class="my-3">By post:</h3>

<p class="my-3">
  Gaimin Gladiators Ltd: 80 Galaxy Boulevard, Unit 15, Etobicoke, M9W4Y8,
  Ontario, Canada
</p>

<p class="my-3">GAIMIN.io Ltd: 100 Barbirolli Square, Manchester, M2 3BD, UK</p>
<p class="my-3">GAIMIN AG: Bahnhofstrasse 7, 6300, Zug, Switzerland</p>
<p class="my-3">
  GAIMIN LDA: Rua José Alfredo da Costa Azevedo, Lisbon, 2635 585, Portugal
</p>
<p class="my-3">Email address: info&#64;gaimin.io</p>
<h2 class="my-3">General information</h2>

<p class="my-3">
  All your personal information will be held and used in accordance with the
  relevant data protection laws.
</p>
<p class="my-3">
  Your personal information may be stored in or accessed from multiple
  countries. Whenever we transfer personal information to other jurisdictions,
  we will ensure that the information is transferred in accordance with this
  Privacy Policy and as permitted by applicable data protection laws.
</p>
<p class="my-3">
  For individuals based in the UK, all personal information will be held and
  used in accordance with the UK General Data Protection Regulation (“UK GDPR”)
  and the Data Protection Act 2018 and any other legislation relating to the
  protection of personal information.
</p>
<p class="my-3">
  For individuals based in the European Economic Area (EEA) and Switzerland, all
  personal information will be held and used in accordance with the General Data
  Protection Regulation (“EU GDPR”) and the Swiss Federal Data Protection Act
  and any other legislation relating to the protection of personal information.
</p>
<p class="my-3">
  For individuals based in Canada, all personal information will be held and
  used in accordance with the Personal Information Protection and Electronic
  Documents Act (PIPEDA) and any other legislation relating to the protection of
  personal information.
</p>
<p class="my-3">
  If you are located outside of the United Kingdom, the EEA or Switzerland, or
  Canada, you may have certain rights and protections under applicable laws
  regarding the processing of your personal information. No matter which country
  you are accessing our services from, we will use appropriate safeguards to
  protect your personal information in accordance with this Privacy Policy and
  the applicable data protection laws.
</p>
<h2 class="my-3">Changes to our Privacy Policy</h2>

<p class="my-3">
  Any changes we make to our Privacy Policy in the future will be posted on our
  website (www.gaimin.io) and, where appropriate, notified to you by e-mail.
  Please check back frequently to see any updates or changes to our Privacy
  Policy.
</p>
<h2 class="my-3">Third-party Links</h2>

<p class="my-3">
  Websites, plug-ins and applications may include links enabling third parties
  to collect or share data about you. We do not control these third-party
  websites and are not responsible for their privacy statements or policies.
</p>
<p class="my-3">
  We encourage you to read the Privacy Policy of every website you visit.
</p>
<h2 class="my-3">Collection of your Personal Information</h2>
<p class="my-3">
  We use different methods to collect information from and about you.
</p>
<p class="my-3">
  We collect personal information to provide you with services. We also collect
  personal information when you visit our website, platform, applications or
  contact us by email or via social media.
</p>
<h2 class="my-3">Personal Information That We Collect and Process.</h2>
<p class="my-3">
  When you provide personal information to us, we will treat that information in
  accordance with this Privacy Policy.
</p>
<p class="my-3">
  Personal information or personal data, means any information about an
  individual from which that person can be identified. It does not include data
  where the identity has been removed (anonymous data).
</p>

<p class="my-3">
  We may collect, use, store, and transfer different kinds of personal
  information about you, which we have grouped together as follows:
</p>
<ul class="my-3">
  <li>
    <strong>Identity Data</strong> includes first name, last name, username or
    similar identifier, title.
  </li>
  <li>
    <strong>Contact Data</strong> includes billing address, delivery address,
    email address, and telephone numbers.
  </li>
  <li>
    <strong>Financial Data</strong> includes bank account and/or payment card
    details.
  </li>
  <li>
    <strong>Transaction Data</strong> includes details about payments to and
    from you and other details of products and services you have purchased from
    us.
  </li>
  <li>
    <strong>Technical Data</strong> includes internet protocol (IP) address,
    your login data, browser type and version, time zone setting and location,
    browser plug-in types and versions, operating system and platform, and other
    technology on the devices you use to access this website.
  </li>
  <li>
    <strong>Usage Data</strong> includes information about how you use our
    website, products, and services.
  </li>
  <li>
    <strong>Marketing and Communications Data</strong> includes your preferences
    in receiving marketing from us and our third parties and your communication
    preferences.
  </li>
</ul>
<p class="my-3">
  Most of the personal information we process is provided to us directly by you
  for the purpose of providing you with our services. You provide us with
  personal information and other information which is necessary to provide the
  services requested and to register you as a member on our platform.
</p>
<p class="my-3">
  Where we perform our due diligence checks, and to perform our obligations
  under Know Your Customer (KYC), we may also receive from you information
  including:
</p>
<ul class="my-3">
  <li>Passports,</li>
  <li>
    Identity cards, driving license, and utility bills that confirm your
    identity and/or otherwise prove proof of your home address;
  </li>
  <li>Source of income documents (payslip, invoice)</li>
  <li>
    As well as any other additional documentation as deemed necessary by us.
  </li>
</ul>
<p class="my-3">
  We collect and process your personal information through your use of your
  account, including any information you may provide through this website,
  platform or application, or when you correspond with us by phone, email, live
  chat, social media, or otherwise.
</p>
<p class="my-3">
  We use your information to provide you with the services requested, to follow
  up requests from you, to verify your identity, including for our KYC
  obligations, to contact you when necessary, or when you use other social media
  functions on our website, platform, or applications, or when you enter a
  competition, promotion, or survey, and/or when you report a problem with our
  website, platform, or application.
</p>
<p class="my-3">
  We may also use information to match you with content that is associated with
  your interests and to direct you to additional resources and tools that can
  save you time. We may also use this information to send you our electronic
  newsletter, other information, and content.
</p>
<p class="my-3">
  This list is not exhaustive, and, in specific instances, we may need to
  collect additional information for the purposes set out in this Privacy
  Policy.
</p>

<h2 class="my-3">Sensitive Information</h2>
<p class="my-3">
  Sensitive information or Special Category Data is information related to
  racial or ethnic origin, political opinions, religion or other beliefs,
  health, biometrics, or genetic characteristics, criminal background, or trade
  union membership (“Special Category Data”).
</p>
<p class="my-3">
  We may receive Special Category Data from third-party service providers and
  others in support of due diligence activities we undertake to satisfy various
  legal and regulatory requirements to which we are subject.
</p>

<h2 class="my-3">Financial Information</h2>
<p class="my-3">
  We process your financial information for payments, including where you
  purchase our GMRX tokens, and for onboarding and ongoing client checks, due
  diligence and verification requirements, and tax reporting.
</p>

<h3 class="my-3">Payment Card Information</h3>
<p class="my-3">
  We may process your personal information to process any payments made for the
  provision of services. The information may include information for
  identification and verification, such as your name, account number and sort
  code, or credit, debit, or other card number, card expiration date, and CVV
  code.
</p>
<p class="my-3">
  Any payment transactions carried out by us, or our chosen third-party provider
  of payment processing services will be kept securely.
</p>

<h2 class="my-3">Member Account Information</h2>
<p class="my-3">
  Some personal information is required to set up your Gaimin Members account.
  Whether you set up your account on the website, online, or via the platform,
  personal information including your name, contact details, and email address
  will be required.
</p>
<p class="my-3">
  We may also process information about your browser type, settings, and
  information about how and when you used our services, information about the
  type of device and/or operating system you are using, which videos you have
  watched, what content you like or share, and which adverts you saw and
  responded to.
</p>

<h2 class="my-3">Information Received from Third Parties</h2>
<p class="my-3">
  This is information we receive about you from third parties, for example, we
  receive information from third parties who provide services to us or you,
  including, where appropriate, from our partners or our Group companies.
</p>
<p class="my-3">
  We will only use this information and the combined information where we have a
  lawful basis. We sometimes combine information received from other sources
  with information you give to us and information we collect about you. We will
  notify you when we receive information about you from them and the purposes
  for which we intend to use that information.
</p>
<p class="my-3">
  Any information obtained from third parties will be kept in accordance with
  this Privacy Policy, and with any additional restrictions imposed by the third
  party that shared your personal information.
</p>

<h2 class="my-3">If You Fail to Provide Personal Information</h2>
<p class="my-3">
  Where we need to collect personal information by law, legitimate interest, or
  under the terms of a contract we have with you, and you fail to provide that
  data when requested, we may not be able to perform the contract we have or are
  trying to enter into with you (for example, to provide you with the services
  requested). In this case, we may have to cancel a service you have with us,
  but we will notify you if this is the case at the time.
</p>

<h2 class="my-3">How We Use the Information We Collect</h2>
<p class="my-3">
  Data Protection Laws set out a number of different reasons for which a company
  may collect and process your personal information. We will only use your
  personal information when the law allows us to do so. The lawful bases that we
  most commonly use to collect and process your personal information are:
</p>
<ul class="my-3">
  <li>Where you have consented before the processing.</li>
  <li>
    Where we need to perform a contract, we are about to enter or have entered
    with you.
  </li>
  <li>
    Where it is necessary for our legitimate interests (or those of a third
    party) and your interests and fundamental rights do not override those
    interests.
  </li>
  <li>Where we need to comply with a legal or regulatory obligation.</li>
</ul>
<p class="my-3">
  In certain circumstances, we need your personal information to comply with our
  contractual obligations or to pursue our legitimate interests in a way which
  might be reasonably expected as part of running our business. For example, we
  use your personal information for, but not limited to, the following purposes:
</p>
<ul class="my-3">
  <li>For the purposes of setting up an account on our platform,</li>
  <li>
    For the purposes of managing our relationship with you, including your
    membership and account,
  </li>
  <li>
    To respond to your queries and/or requests you send us to use/receive any of
    our services (including customer support services),
  </li>
  <li>
    To inform our members and potential members about products or services which
    we believe may be of interest, including marketing proposals or offers.
  </li>
</ul>
<p class="my-3">
  Additionally, we and our service providers may use personal information to
  comply with our contractual obligations we have with you, or to enforce our
  agreements, including the investigation of any potential violations of our
  terms.
</p>
<p class="my-3">
  We may use personal information where we need to comply with a legal or
  regulatory obligation to detect, prevent, or otherwise address economic
  criminal activity (including financial crime, fraud, money laundering, etc.).
</p>
<p class="my-3">
  Whenever you have given us your consent to use your personal information, you
  have the right to change your mind at any time and withdraw that consent.
  However, this will not affect the lawfulness of any processing carried out
  before you withdraw your consent.
</p>

<h2 class="my-3">Marketing</h2>
<p class="my-3">
  We strive to provide you with choices regarding certain personal information
  uses, particularly around marketing and advertising.
</p>
<p class="my-3">
  If you have given your consent to receive marketing emails, you can withdraw
  this at any time. Alternatively, if we are relying on our legitimate interests
  to send you marketing, you can object. In either case, just let us know. If
  you have received a direct marketing email from us and no longer wish to do
  so, the easiest way to let us know is to click on the unsubscribe link at the
  bottom of our marketing emails.
</p>

<h2 class="my-3">Cookies</h2>
<p class="my-3">
  When you visit our website, we may collect certain information by automated
  means, such as using cookies.
</p>
<p class="my-3">
  A cookie is a piece of data stored locally on your computer containing
  information about your activities on the Internet. Each website can send its
  own cookie to your web browser if your browser's preferences allow it. Many
  websites do this whenever a user visits their website in order to track online
  traffic flows.
</p>
<p class="my-3">
  For more information about our cookies, what cookies we use, and why we use
  them, please see our cookies policy on our website (<a
    href="https://www.gaimin.io"
    target="_blank"
    >www.gaimin.io</a
  >).
</p>

<h2 class="my-3">Information We Share</h2>
<p class="my-3">
  Personal information may be shared or disclosed to trusted third parties in
  connection with the services we are providing. For example, we may share your
  personal information with a third-party cryptoasset wallet for the transfer of
  a digital asset, or where we have employed other companies to perform
  functions on our behalf.
</p>
<p class="my-3">
  We may also share your personal information with any member of our Group.
  Where we share your personal information within our Group, it is only to the
  extent required and always with the appropriate safeguards in place, so that
  we can provide you with the relevant services.
</p>
<p class="my-3">
  Where personal information is shared with a third-party supplier, we work
  closely with them to ensure that your personal information is secure and
  protected at all times. We do not allow our third-party service providers to
  use your personal information for their own purposes and only permit them to
  process your personal information for specified purposes and in accordance
  with our instructions.
</p>
<p class="my-3">
  Our contracts with third parties make it clear that they must hold personal
  information securely, abide by the principles and provisions of data
  protection laws, and only use information as we instruct them to.
</p>
<p class="my-3">
  In all instances where we disclose your information to third parties, we will
  ensure that your information is appropriately protected. If we stop using
  their services, any of your personal information held by them will either be
  deleted or rendered anonymous.
</p>
<p class="my-3">
  If we are required to do so by law, or in performance of our legal
  obligations, we may share your data with courts, law enforcement agencies, or
  other governmental agencies.
</p>

<h2 class="my-3">Where Your Personal Information May Be Processed</h2>
<p class="my-3">
  Your personal information may be stored in or accessed from multiple
  countries, including the UK, EEA, Switzerland, and Canada. Transfers may also
  take place within the country where you live or to outside that country. All
  transfers are made to provide the services and for the purposes listed in this
  Privacy Policy.
</p>
<p class="my-3">
  If we do undertake transfers, we have procedures in place to ensure your
  personal information receives the protections as stipulated by the relevant
  data protection laws. For example, our contracts with third parties stipulate
  the standards they must follow at all times.
</p>
<p class="my-3">
  For all transfers of your personal information to other jurisdictions, we will
  ensure that the information is transferred in accordance with this Privacy
  Policy and as permitted by applicable data protection laws.
</p>

<h2 class="my-3">How Long We Will Retain Your Personal Information</h2>
<p class="my-3">
  We will only retain your personal information for as long as is necessary for
  the purpose or purposes for which we have collected it.
</p>
<p class="my-3">
  In addition, we limit access to your personal information to those employees,
  agents, contractors, and other third parties who have a business need to know.
  They will only process your personal information on our instructions, and they
  are subject to a duty of confidentiality.
</p>
<p class="my-3">
  In some circumstances, we will anonymise your personal information (so that it
  can no longer be associated with you) for analytical or statistical purposes,
  in which case we may use this information indefinitely without further notice
  to you.
</p>

<h2 class="my-3">Data Security</h2>
<p class="my-3">
  We work hard to keep your information safe. We use a combination of technical,
  administrative, and physical controls to maintain the security of your
  personal information and protect against accidental, unlawful, or unauthorized
  destruction, loss, alteration, access, disclosure, or use.
</p>
<p class="my-3">
  All information you provide to us is stored on secure servers. Some of the
  controls we have in place to protect your personal data include technological
  controls such as firewalls, user verification, and strong data encryption. We
  utilise industry best practice standards to support the maintenance of a
  robust information security management system.
</p>
<p class="my-3">
  We have put in place procedures to deal with any suspected personal
  information data breach and will notify you and any applicable regulator when
  we are legally required to do so.
</p>

<h2 class="my-3">Your Rights</h2>
<p class="my-3">You are also able to exercise your rights which include:</p>

<h3 class="my-3">Your Right to be Informed</h3>
<p class="my-3">
  We aim to be transparent within our Privacy Policy and provide you with
  information about how we use your personal information.
</p>

<h3 class="my-3">Your Right to Object</h3>
<p class="my-3">
  In some circumstances, you can stop the processing of your personal
  information for reasons connected to your individual situation. We must then
  do so unless we believe we have a legitimate overriding reason to continue
  processing your personal information.
</p>
<p class="my-3">
  Where your details are used for marketing, you can opt out at any time. You
  are able to unsubscribe from marketing on each contact or you can contact us
  to object to any processing.
</p>

<h3 class="my-3">Your Right to Rectification</h3>
<p class="my-3">
  You have the right to request the correction of your personal information when
  it is incorrect, out of date, or incomplete. If you notify us that the
  personal information we hold is incomplete or inaccurate, we will correct or
  complete the information as soon as possible.
</p>

<h3 class="my-3">Your Right to Erasure or the Right to be Forgotten</h3>
<p class="my-3">
  You have the right to request that your personal information be deleted,
  including if we no longer need it for the purpose we collected it, you
  withdraw your consent, or you object to its processing.
</p>
<p class="my-3">
  Following your request, we will erase your personal information without undue
  delay unless the continued retention is necessary and permitted by law. If we
  make the personal information public, we shall take reasonable steps to inform
  other data controllers processing about your erasure request.
</p>

<h3 class="my-3">Your Right to Restrict Processing</h3>
<p class="my-3">
  You have the right to request that we restrict the processing of your personal
  information. This can be done in circumstances where we need to verify the
  accuracy of the information, if you do not wish to have the information
  erased, or you have objected to the processing of the information, and we are
  considering this request. Once the processing is restricted, we will only
  continue to process your personal information if you consent, or we have
  another legal basis for doing so.
</p>

<h3 class="my-3">Your Right to Access</h3>
<p class="my-3">
  You have the right to access the personal information we hold about you. Any
  access request will usually be free of charge and responded to within one
  month. We will endeavour to provide information in the format requested, but
  we may charge you a reasonable fee for additional copies.
</p>

<h3 class="my-3">Your Right to Data Portability</h3>
<p class="my-3">
  You have the right to receive a copy of your personal information which you
  gave to us. The copy will be provided in a commonly used and machine-readable
  format. You can also have it transmitted directly from us to another data
  controller, where technically possible.
</p>

<h3 class="my-3">
  The Right Not to be Subject to Automated Decision Making and Profiling
</h3>
<p class="my-3">
  You have the right not to be subject to solely automatic decisions (i.e.,
  decisions that are made about you by computer without any human input) in
  relation to any processes that have a legal or similarly significant effect on
  you.
</p>
<p class="my-3">
  You will be notified if we make a solely automated decision which produces a
  legal effect or significantly affects you.
</p>

<h2 class="my-3">When You Request to Exercise Your Rights</h2>
<p class="my-3">
  You will not have to pay a fee to exercise any of the rights listed above.
  However, we may charge a reasonable fee if your request is clearly unfounded
  or excessive, including where requests are repetitive. Alternatively, we could
  refuse to comply with your request in these circumstances.
</p>
<p class="my-3">
  We may need to request specific information from you to help us confirm your
  identity and ensure your right to access your personal information or to
  exercise any of your other rights. This is a security measure to ensure that
  personal information is not disclosed to any person who has no right to
  receive it. We may also contact you to ask you for further information in
  relation to your request to speed up our response.
</p>
<p class="my-3">
  We try to respond to all legitimate requests within one month. Occasionally,
  it could take us longer than a month if your request is particularly complex
  or you have made a number of requests. In this case, we will notify you and
  keep you updated.
</p>

<h2 class="my-3">How Can We Help?</h2>
<p class="my-3">
  If you have any questions about our use of your personal information, you can
  contact us at <a href="mailto:info@gaimin.io">info&#64;gaimin.io</a> or by
  post using our postal address.
</p>
<p class="my-3">
  For further information on data protection, please visit the
  <a href="https://ico.org.uk" target="_blank"
    >Information Commissioner Office (ICO)</a
  >
  website.
</p>
<p class="my-3">
  The Information Commissioner Office regulates data protection. If you feel
  that your data has not been handled correctly, or you are unhappy with our
  response to any requests you have made to us regarding the use of your
  personal information, you have the right to lodge a complaint with the
  Information Commissioner’s Office.
</p>
<p class="my-3">
  You can contact them by calling <a href="tel:03031231113">0303 123 1113</a> or
  visit their website.
</p>
