import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  message?: string | null;
  percentage?: number | null;

  get isLoading(): boolean {
    return this.isLoading$.value;
  }

  set isLoading(value: boolean) {
    this.isLoading$.next(value);
  }

  setMessage(message: string): void {
    this.message = message;
  }

  setPercentage(percentage: number): void {
    this.percentage = percentage;
  }

  constructor() {}

  showLoader(message?: string, percentage?: number): void {
    this.isLoading$.next(true);
  }

  updateLoaderMessage(message: string, percentage: number): void {
    this.setMessage(message);
    this.setPercentage(percentage);
  }

  hideLoader(): void {
    this.isLoading$.next(false);
    this.message = null;
    this.percentage = null;
  }
}
