<div [formGroup]="socialLinksForm">
  <h3 class="text-white text-2xl mb-5 mt-4">Social media links</h3>
  <div class="mb-4 relative">
    <input
      id="title"
      type="title"
      placeholder="Game Website URL*"
      pInputText
      class="w-full"
      formControlName="websiteUrl"
      [appValidationMessage]="socialLinksForm.get('websiteUrl')"
      [validationMessages]="{
        required: 'Game Website URL is required',
        pattern: 'Invalid URL address'
      }"
    />
  </div>
  <div class="mb-5 relative">
    <input
      id="title"
      type="title"
      placeholder="Game YouTube URL"
      pInputText
      class="w-full"
      formControlName="youtubeUrl"
      [appValidationMessage]="socialLinksForm.get('youtubeUrl')"
      [validationMessages]="{
        pattern: 'Invalid YouTube channel URL address'
      }"
    />
  </div>
  <div class="mb-5 relative">
    <input
      id="title"
      type="title"
      placeholder="Game X (Twitter) URL"
      pInputText
      class="w-full"
      formControlName="twitterUrl"
      [appValidationMessage]="socialLinksForm.get('twitterUrl')"
      [validationMessages]="{
        pattern: 'Invalid Twitter channel URL address'
      }"
    />
  </div>
  <div class="mb-5 relative">
    <input
      id="title"
      type="title"
      placeholder="Game Discord URL"
      pInputText
      class="w-full"
      formControlName="discordUrl"
      [appValidationMessage]="socialLinksForm.get('discordUrl')"
      [validationMessages]="{
        pattern: 'Invalid Discord channel URL address'
      }"
    />
  </div>
  <div class="mb-5 relative">
    <input
      id="title"
      type="title"
      placeholder="Game Telegram URL*"
      pInputText
      class="w-full"
      formControlName="telegramUrl"
      [appValidationMessage]="socialLinksForm.get('telegramUrl')"
      [validationMessages]="{
        required: 'Game Telegram URL is required',
        pattern: 'Invalid Telegram channel URL address'
      }"
    />
  </div>
  <div class="mb-7 relative">
    <input
      id="title"
      type="title"
      placeholder="Game Instagram URL"
      pInputText
      class="w-full"
      formControlName="instagramUrl"
      [appValidationMessage]="socialLinksForm.get('instagramUrl')"
      [validationMessages]="{
        pattern: 'Invalid Instagram channel URL address'
      }"
    />
  </div>

  <h3 class="text-white text-2xl mb-5 mt-4">Game support links</h3>

  <div class="mb-3 relative">
    <label for="support-website" class="text-md block text-gray-400 mb-1">
      Support Website URL*
    </label>
    <input
      id="support-website"
      type="title"
      placeholder="https://www.example.com"
      pInputText
      class="w-full"
      formControlName="supportWebsiteUrl"
      [appValidationMessage]="socialLinksForm.get('supportWebsiteUrl')"
      [validationMessages]="{
        required: 'Support Website URL is required',
        pattern: 'Invalid Support Website URL address'
      }"
    />
  </div>

  <div class="mb-5 relative">
    <label for="support-email" class="text-md block text-gray-400 mb-1">
      Support Email*
    </label>
    <input
      id="support-email"
      type="title"
      placeholder="example@mail.com"
      pInputText
      class="w-full"
      formControlName="supportEmail"
      [appValidationMessage]="socialLinksForm.get('supportEmail')"
      [validationMessages]="{
        required: 'Support Email is required',
        email: 'Invalid format for Support Email'
      }"
    />
  </div>
</div>
