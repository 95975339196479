import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { GamesService } from '../../shared/services/games.service';
import { NgStyle } from '@angular/common';
import { GAME_STATUS } from '../../tools/constants/game.constants';
import { map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-game-reviewing',
  standalone: true,
  imports: [ButtonModule, BodyBackgroundSetterComponent, NgStyle],
  templateUrl: './game-reviewing.component.html',
})
export class GameReviewingComponent implements OnInit {
  title?: string;
  iconUri?: string;

  constructor(
    private router: Router,
    private gamesService: GamesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map((params) => Number(params.get('game-id'))),
      switchMap((gameId) => this.gamesService.getGameByIdRequest(gameId)),
      tap((gameData) => {
        if (gameData.status === GAME_STATUS.REVIEW) {
          this.title = gameData.title;
          this.iconUri = gameData.iconUri;
        } else {
          this.router.navigateByUrl('app/home');
        }
      })
    ).subscribe({
      error: () => {
        this.router.navigateByUrl('app/home');
      }
    });
  }

  goHome(): void {
    this.router.navigateByUrl('app/home');
  }
}
