import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';

@Component({
  selector: 'app-legal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    InputTextModule,
  ],
  templateUrl: './legal.component.html',
})
export class LegalComponent {
  @Input() legalForm!: FormGroup;
}
