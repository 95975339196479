import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DOC_SECTION } from '../../tools/constants/doc.constants';
import { HttpClient } from '@angular/common/http';
import { OpenApiResponse } from '../../tools/interfaces/openapi-response.interface';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../tools/constants/breakpoint.constants';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [RouterLink, NgClass, NgStyle, AsyncPipe],
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DocumentationComponent implements OnInit {
  currentSection: DOC_SECTION = DOC_SECTION.GAME_UPLOADING;
  endpoints: { method: string; path: string; summary: string }[] = [];
  sections: typeof DOC_SECTION = DOC_SECTION;
  SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public breakpointService: BreakpointService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const urlSection = params.get('section') as DOC_SECTION;
      if (this.isValidSection(urlSection)) {
        this.currentSection = urlSection;
      } else {
        this.router.navigate(['app/documentation']);
      }
    });

    this.route.fragment.subscribe((fragment) => {
      setTimeout(() => {
        this.scrollToAnchor(fragment ?? 'top');
      });
    });

    this.http
      .get<OpenApiResponse>(`${environment.gaiminApi}/v3/api-docs`)
      .subscribe((res) => {
        this.endpoints = Object.entries(res.paths)
          .filter(([path]) => path.startsWith('/api/public'))
          .flatMap(([path, methods]) =>
            Object.entries(methods as any).map(([method, details]: any) => ({
              method,
              path,
              summary: details.summary || 'No summary available',
            }))
          );
      });
  }

  scrollToAnchor(id: string) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  private isValidSection(section: DOC_SECTION | null): section is DOC_SECTION {
    return Object.values(DOC_SECTION).includes(section as DOC_SECTION);
  }
}
