@if (gamesList.length) {
<div class="mb-7">
  <h3 class="text-2xl mb-1">Games</h3>
  <p
    class="line-height-3 color-text-400 mb-4"
    [ngStyle]="{ 'max-width': '35%' }"
  >
    To upload new versions of your game, edit any game information or unpublish
    your game, click on your game to begin the editing process
  </p>

  <div
    class="w-full gap-3"
    [ngStyle]="{
      'grid-template-columns': 'repeat(auto-fill, minmax(18.75rem, 1fr))',
      display: 'grid'
    }"
  >
    @for (game of gamesList; track $index) {
    <div class="p-0" [ngStyle]="{ flex: '0 1 calc(25% - 1rem)' }">
      <div
        class="bg-gray-900 border-round-xl cursor-pointer transition-duration-300 hover-scale-05 relative overflow-hidden"
        (click)="redirectToGameReview(game)"
        [buttonStyles]="{ left: 'auto', right: '5%' }"
        [appRemoveButton]="game.status !== GAME_STATUS.UNPUBLISH_REQUESTED"
        (imageRemoved)="onRemoveGame(game)"
      >
        <img
          [src]="
            game?.info?.bannerImageUri ??
            './assets/games/banner-placeholder.svg'
          "
          alt=""
          class="w-full"
          [ngStyle]="{
            'min-width': '300px',
            'aspect-ratio': '16 / 9',
            'object-fit': 'cover',
            'object-position': 'top'
          }"
        />
        <div
          class="flex justify-content-between align-items-center py-2 px-3 text-lg"
        >
          <h4>
            {{ game.info.title }}
          </h4>
          <p-tag
            [value]="game.status | convertEnumFromBE"
            [class]="game.status"
          ></p-tag>
        </div>
      </div>
    </div>
    } @for (game of duplicateGamesList; track $index) {
    <div class="p-0" [ngStyle]="{ flex: '0 1 calc(25% - 1rem)' }">
      <div
        class="bg-gray-900 border-round-xl cursor-pointer transition-duration-300 hover-scale-05 relative overflow-hidden"
        (click)="redirectToGameReview(game)"
        (imageRemoved)="onRemoveGame(game)"
      >
        <img
          [src]="
            game?.info?.bannerImageUri ??
            './assets/games/banner-placeholder.svg'
          "
          alt=""
          class="w-full"
          [ngStyle]="{
            'min-width': '300px',
            'aspect-ratio': '16 / 9',
            'object-fit': 'cover',
            'object-position': 'top'
          }"
        />
        <div
          class="flex justify-content-between align-items-center py-2 px-3 text-lg"
        >
          <h4>
            {{ game.info.title }}
          </h4>
          <p-tag
            [value]="game.status | convertEnumFromBE"
            [class]="game.status"
          ></p-tag>
        </div>
      </div>
    </div>
    }
  </div>
</div>

}

<!-- <h3 class="mb-4">More resources</h3>
<div
  class="pb-3"
  [ngStyle]="{
      display: 'grid',
      'grid-template-columns': 'repeat(auto-fill, minmax(20rem, 1fr))',
      gap: '1rem',
    }"
>
  <ng-container
    *ngIf="gamesList.length ? resources.slice(4) : resources as resourcesList"
  >
    @if((breakpointService.currentRange$ | async) !== SCREEN_SIZE.XSMALL) {
    <div
      class="flex"
      *ngFor="let resource of resourcesList"
      style="align-items: center; gap: 1rem; display: flex"
    >
      <span
        class="pi pi-question-circle align-self-start mt-1"
        [ngStyle]="{ color: '#51576E' }"
      ></span>
      <div>
        <h4 class="color-text-300 font-medium mb-1">
          {{ resource.title }}
        </h4>
        <p class="color-text-400 line-height-2">{{ resource.description }}</p>
      </div>
    </div>
    } @else {
    <p-accordion class="pb-8">
      @for(resource of resourcesList; track resource.title) {
      <p-accordionTab [header]="resource.title">
        <p>{{ resource.description }}</p>
      </p-accordionTab>
      }
    </p-accordion>
    }
  </ng-container>
</div> -->
