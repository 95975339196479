import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ConvertEnumFromBEPipe } from '../../../../shared/pipes/convert-enum-from-be.pipe';
import { ImageModule } from 'primeng/image';
import { GameData } from '../../../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-admin-game-details-tab',
  standalone: true,
  imports: [
    NgStyle,
    PanelModule,
    TabViewModule,
    ConvertEnumFromBEPipe,
    ImageModule,
  ],
  templateUrl: './admin-game-details-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminGameDetailsTabComponent {
  @Input() gameData!: GameData;
}
