import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { ProductsFooterComponent } from '../../shared/components/products-footer/products-footer.component';
import { SupportDialogComponent } from '../../shared/modals/support-dialog/support-dialog.component';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AUTH_ACTION } from '../../tools/constants/auth.constants';
import { RouterModule } from '@angular/router';
import { ContactSalesComponent } from '../../shared/modals/contact-sales/contact-sales.component';
import { CalculatorComponent } from "./calculator/calculator.component";
import { MigrationNoticeDialogComponent } from '../../shared/modals/migration-notice-dialog/migration-notice-dialog.component';

@Component({
  selector: 'app-marketing',
  standalone: true,
  imports: [NgStyle, ButtonModule, ProductsFooterComponent, RouterModule, CalculatorComponent],
  templateUrl: './marketing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingComponent {
  public readonly SIGN_IN: AUTH_ACTION = AUTH_ACTION.SIGN_IN;
  public readonly SIGN_UP: AUTH_ACTION = AUTH_ACTION.SIGN_UP;
  public advantagesContent = [
    {
      title: 'Upload Your Files',
      text: 'Easily upload files through our intuitive web interface. You can upload directly through your dashboard or via our secure API.',
      backgroundUrl:
        'url(../../../../assets/illustrations/marketing/upload.png)',
      hasTitleIcon: false,
    },
    {
      title: 'Decentralized Storage',
      text: 'Your files are split into multiple segments and distributed through a global network for maximum security and resilience and easier geographic distribution.',
      backgroundUrl:
        'url(../../../../assets/illustrations/marketing/storage.png)',
      hasTitleIcon: true,
    },
    {
      title: 'Instant Sharing Anywhere',
      text: 'Share your files anywhere in just <br> a few clicks.',
      backgroundUrl:
        'url(../../../../assets/illustrations/marketing/sharing.png)',
      hasTitleIcon: false,
    },
  ];

  public benefitsContent = [
    {
      title: 'Industry-Standard Data Security',
      text: 'With GAIMIN’s decentralized network, your files are split into multiple segments, and stored across a global network of DePIN devices. This makes data breaches nearly impossible, ensuring that your files are always safe and confidential.',
    },
    {
      title: 'Lightning-Fast Transfers',
      text: "GAIMIN's network harnesses the power of thousands of high-performance computers worldwide, updated to the world’s current demand for computing, to offer you unmatched speed and efficiency. Your files are distributed faster than traditional cloud solutions, ensuring minimal wait time.",
    },
    {
      title: 'Cost-Effective Solution',
      text: 'Enjoy revolutionary pricing, without sacrificing performance. Our unique model allows us to keep costs low while rewarding our community of contributors — passing savings directly to your business. This means you get more for less!',
    },
    {
      title: 'Seamless Integration ',
      text: 'Our file hosting service effortlessly integrates with existing business workflows. Upload your files and our system automatically integrates them into our solution, so no extra work is needed from you.',
    },
  ];

  constructor(
    public authService: AuthService,
    private dialogService: DialogService
  ) {}

  public onOpenSupportDialog(event: Event): void {
    event.preventDefault();

    this.dialogService.open(SupportDialogComponent, {});
  }

  public onMigrationInfoClick(): void {
    this.dialogService.open(MigrationNoticeDialogComponent, {});
  }

  public onContactSalesClick(): void {
    this.dialogService.open(ContactSalesComponent, {});
  }
}
