import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Table, TableModule, TableService } from 'primeng/table';
import { DatePipe, NgStyle } from '@angular/common';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { TreeTableModule } from 'primeng/treetable';
import { DropdownModule } from 'primeng/dropdown';
import { GamesService } from '../../../shared/services/games.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { InputTextModule } from 'primeng/inputtext';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';
import { BlobReader, Entry, ZipReader } from '@zip.js/zip.js';

@Component({
  selector: 'app-installation',
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    FileSizePipe,
    ButtonModule,
    TableModule,
    TreeTableModule,
    DropdownModule,
    InputTextModule,
    ValidationMessageDirective,
  ],
  templateUrl: './installation.component.html',
  providers: [Table, TableService],
})
export class InstallationComponent {
  @Input() installationForm!: FormGroup;
  @Input() gameId!: number;

  selectedGameArchiveName?: string;
  gameExeList?: string[];

  get previouslyPublished(): boolean {
    return this.installationForm.controls['previouslyPublished'].value;
  }

  constructor(private http: HttpClient, public gamesService: GamesService) {}

  ngOnInit(): void {
    this.http
      .get(
        `${environment.devportalApi}/api/storage/private/game/${this.gameId}/archive`
      )
      .subscribe({
        next: (res) => {
          this.installationForm.patchValue({
            previouslyPublished: true,
            // TODO: consider making form valid in other way
            gameArchive: {},
            gameExecutable: {},
          });
          // this.installationForm.setErrors({});
          this.installationForm.updateValueAndValidity();
        },
        error: (err) => {
          if (err.type === 'NOT_FOUND') {
            this.installationForm.patchValue({
              previouslyPublished: false,
            });
          }
        },
      });
  }

  onSelectArchive(event: any): void {
    const gameArchive = (event.target as HTMLInputElement)?.files?.[0];

    if (!gameArchive) return;

    this.installationForm.patchValue({
      previouslyPublished: false,
    });
    this.selectedGameArchiveName = gameArchive.name;
    this.installationForm.patchValue({ gameArchive, gameExecutable: '' });

    this.getExeFilesList(gameArchive).then((exeList) => {
      this.gameExeList = exeList;
    });
  }

  async getExeFilesList(archive: File): Promise<string[]> {
    const zipReader: ZipReader<unknown> = new ZipReader(
      new BlobReader(archive)
    );
    const entries: Entry[] = await zipReader.getEntries();
    const exeList: string[] = entries
      .map((entry) => entry.filename)
      .filter((filename) => filename.endsWith('.exe'));

    return exeList;
  }

  onRemoveArchive(): void {
    this.installationForm.patchValue({
      gameArchive: null,
      gameExecutable: '',
    });

    this.selectedGameArchiveName = '';
  }
}
