import { Component } from '@angular/core';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { DialogService } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../../shared/services/loader.service';
import { AuthRequestsService } from '../../shared/services/auth/auth-requests.service';
import { NotificationService } from '../../shared/services/notification.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { SCREEN_SIZE } from '../../tools/constants/breakpoint.constants';
import { AccountTabComponent } from './account-tab/account-tab.component';
import { DeleteTabComponent } from './delete-tab/delete-tab.component';
import {
  TEXT,
  TEXT_WITHOUT_NUMBERS,
} from '../../tools/constants/pattern.constants';
import { InvoicesTabComponent } from './invoices-tab/invoices-tab.component';
import { PaymentRequestsService } from '../../shared/services/payment-requests.service';
import { Invoice } from '../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TabViewModule,
    PanelModule,
    AccountTabComponent,
    DeleteTabComponent,
    AsyncPipe,
    InvoicesTabComponent,
  ],
  templateUrl: './account-settings.component.html',
})
export class AccountSettingsComponent {
  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;

  accountInfoForm: FormGroup;
  invoices: Invoice[] = [];

  constructor(
    private authRequestsService: AuthRequestsService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private http: HttpClient,
    private notificationService: NotificationService,
    public loaderService: LoaderService,
    public breakpointService: BreakpointService,
    private paymentRequestsService: PaymentRequestsService
  ) {
    this.accountInfoForm = this.fb.group({
      email: [null, [Validators.email]],
      username: [null, [Validators.pattern(TEXT)]],
      displayName: [null, [Validators.pattern(TEXT)]],
      registeredAddress: ['', [Validators.pattern(TEXT)]],
      companyName: [null, [Validators.pattern(TEXT)]],
      phoneNumber: [null],
      firstName: [
        null,
        [Validators.required, Validators.pattern(TEXT_WITHOUT_NUMBERS)],
      ],
      lastName: [
        null,
        [Validators.required, Validators.pattern(TEXT_WITHOUT_NUMBERS)],
      ],
      country: [null, Validators.pattern(TEXT_WITHOUT_NUMBERS)],
    });
  }

  ngOnInit(): void {
    this.getCurrentUserInfo();
    this.disableFields();
    this.paymentRequestsService.getUserInvoicesRequest().subscribe((res) => {
      this.invoices = res.invoices;
    });
  }

  disableFields(): void {
    this.accountInfoForm.get('email')?.disable();
    this.accountInfoForm.get('displayName')?.disable();
  }

  enableFields(): void {
    this.accountInfoForm.get('email')?.enable();
    this.accountInfoForm.get('displayName')?.enable();
  }

  onUpdateUserInfo(): void {
    if (this.accountInfoForm.invalid) {
      return;
    }

    this.enableFields();

    const updatedUserInfo = Object.fromEntries(
      Object.entries(this.accountInfoForm.value).map(([key, value]) => [
        key,
        value === '' ? null : value,
      ])
    ) as {
      email: string | null;
      username: string | null;
      displayName: string | null;
      registeredAddress: string;
      companyName: string | null;
      phoneNumber: string | null;
      firstName: string | null;
      lastName: string | null;
      country: string | null;
    };

    updatedUserInfo.phoneNumber =
      updatedUserInfo.phoneNumber?.match(/\d/g)?.join('') || null;

    this.http
      .patch(`${environment.devportalApi}/api/user/me`, updatedUserInfo)
      .subscribe({
        next: () => {
          this.disableFields();

          this.notificationService.throwSuccessNotification(
            'User data has been successfully updated'
          );
        },
        error: () => {
          this.disableFields();
          this.getCurrentUserInfo();
        },
      });
  }

  private getCurrentUserInfo() {
    return this.authRequestsService
      .getUserInfoRequest()
      .subscribe((userInfo) => {
        const {
          displayName,
          registeredAddress,
          gaiminAuthUser,
          companyName,
          phoneNumber,
          country,
        } = userInfo;
        const { email, username, firstName, lastName } = gaiminAuthUser;

        this.accountInfoForm.patchValue({
          displayName,
          registeredAddress,
          email,
          username,
          companyName,
          phoneNumber,
          firstName,
          lastName,
          country,
        });
      });
  }
}
