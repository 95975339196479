import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SCREEN_SIZE } from '../../tools/constants/breakpoint.constants';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private currentRangeSubject: BehaviorSubject<SCREEN_SIZE> =
    new BehaviorSubject<SCREEN_SIZE>(SCREEN_SIZE.UNKNOWN);
  currentRange$: Observable<SCREEN_SIZE> =
    this.currentRangeSubject.asObservable();

  private targetBreakpoints: string[] = [
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(this.targetBreakpoints)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateBreakpoint());
  }

  private updateBreakpoint(): void {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.currentRangeSubject.next(SCREEN_SIZE.XSMALL);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.currentRangeSubject.next(SCREEN_SIZE.SMALL);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.currentRangeSubject.next(SCREEN_SIZE.MEDIUM);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.currentRangeSubject.next(SCREEN_SIZE.LARGE);
    } else {
      this.currentRangeSubject.next(SCREEN_SIZE.UNKNOWN);
    }
  }
}
