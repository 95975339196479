export const TEXT = /^[a-zA-Z0-9\s@;'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
export const TEXT_WITHOUT_NUMBERS =
  /^(?=.{2,50}$)([a-zA-Z]{2,}(?:-+[a-zA-Z]{2,})*)(?: [a-zA-Z]{2,}(?:-+[a-zA-Z]{2,})*)?$/;
export const QUESTION_TEXT = /^(?=.*[a-zA-Z])(?=.*\S)(?![а-яА-ЯіІїЇєЄ]).*$/;
export const EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NAME = /^[a-zA-Z][a-zA-Z]*(?:[- ][a-zA-Z][a-zA-Z]*)*$/;
export const PHONE_NUMBER =
  /^\+?[0-9]{1,3}?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

// TODO: make a proper regex for password
export const PASSWORD = /^[a-zA-Z0-9\s@;'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

// TODO: reimplement async validation
export const WEBSITE =
  /^(https:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]\\@!$&'()*+,;=]*)?$/;
export const YOUTUBE_LINK =
  /^(https:\/\/)?(www\.)?youtube\.com\/(channel\/|c\/|user\/|@)[a-zA-Z0-9_-]+(\/videos)?$/;
export const TWITTER_LINK =
  /^(https:\/\/)?(www\.)?(x\.com|twitter\.com)\/([a-zA-Z0-9_]{1,15})\/?$/;
export const DISCORD_LINK =
  /^(https:\/\/)?(www\.)?(discord\.(gg|com)\/(invite\/)?)?[a-zA-Z0-9]{2,}$/;
export const TELEGRAM_LINK =
  /^(https:\/\/)?(www\.)?t\.me\/(@?[a-zA-Z0-9_]{5,})$/;
export const INSTAGRAM_LINK =
  /^(https:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9._]{1,30})\/?$/;
