import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextModule } from 'primeng/inputtext';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';

@Component({
  selector: 'app-account-tab',
  standalone: true,
  imports: [
    InputTextModule,
    AvatarModule,
    AvatarGroupModule,
    ReactiveFormsModule,
    ButtonModule,
    InputMaskModule,
    FormsModule,
    ValidationMessageDirective
  ],
  templateUrl: './account-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTabComponent {
  @Input() accountInfoForm!: FormGroup;

  onResetPassword(): void {
    window.location.href = `${environment.gaiminAuthFE}/profile`;
  }
}
