import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgStyle } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HeaderComponent } from '../../../shared/components/header/header.component';

@Component({
  selector: 'app-admin-layout',
  standalone: true,
  imports: [NgStyle, RouterModule, LoaderComponent, OverlayPanelModule, HeaderComponent],
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent {

}
