import { ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling,
} from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { NetworkService } from './shared/services/network.service';
import { AuthService } from './shared/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { authInterceptor } from '../interceptors/auth.interceptor';
import { loaderInterceptor } from '../interceptors/loader.interceptor';
import { httpInterceptor } from '../interceptors/http.interceptor';
import { errorInterceptor } from '../interceptors/error.interceptor';
import { DatePipe } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        httpInterceptor,
        errorInterceptor,
        authInterceptor,
        loaderInterceptor,
      ])
    ),
    DialogService,
    NetworkService,
    AuthService,
    MessageService,
    DatePipe,
  ],
};
