import { Component, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Validators as EditorValidators } from 'ngx-editor';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GamesService } from '../../shared/services/games.service';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { ReviewFormHeaderComponent } from './review-form-header/review-form-header.component';
import { LegalComponent } from './legal/legal.component';
import { InstallationComponent } from './installation/installation.component';
import { LinksComponent } from './links/links.component';
import { switchMap, of, forkJoin, map, take } from 'rxjs';
import { GameData } from '../../tools/interfaces/response.interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import {
  GAME_CHAINS,
  GAME_GENRES,
  GAME_STATUS,
} from '../../tools/constants/game.constants';
import { Subscription } from 'rxjs';
import { EMAIL, TEXT } from '../../tools/constants/pattern.constants';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { DistributionAgreementDialogComponent } from '../../shared/modals/distribution-agreement-dialog/distribution-agreement-dialog.component';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../tools/constants/notification.constants';
import { installationValidator } from '../../shared/validators/installation.validator';
import { ConvertEnumFromBEPipe } from '../../shared/pipes/convert-enum-from-be.pipe';
import { editorRequired } from '../../shared/validators/editor-required.validator';
import { RegexpService } from '../../shared/services/regexp.service';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',

  standalone: true,
  providers: [ConvertEnumFromBEPipe],
  imports: [
    NgStyle,
    ReviewFormHeaderComponent,
    InstallationComponent,
    LegalComponent,
    LinksComponent,
    FormsModule,
    ReactiveFormsModule,
    BodyBackgroundSetterComponent,
    TabViewModule,
    ButtonModule,
    BasicInfoComponent,
    CheckboxModule,
    ConvertEnumFromBEPipe,
    AsyncPipe,
    NgIf,
  ],
})
export class ReviewFormComponent implements OnDestroy {
  gameDraft?: GameData;
  GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  private subscriptions: Subscription = new Subscription();

  basicInfo: FormGroup = this.fb.group({});
  installation: FormGroup = this.fb.group({});
  legal: FormGroup = this.fb.group({});
  links: FormGroup = this.fb.group({});
  gameReviewForm: FormGroup = this.fb.group({});

  get isFormValid(): boolean {
    return this.gameReviewForm.valid && this.gameReviewForm.dirty;
  }

  get isFormDisabled() {
    return (
      this.gameDraft?.status === GAME_STATUS.REVIEW ||
      this.gameDraft?.status === GAME_STATUS.APPROVED ||
      this.gameDraft?.status === GAME_STATUS.PUBLISHED
    );
  }

  activeIndex: number = 0;

  logForm() {
    console.log(this.gameReviewForm.value);
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private gamesService: GamesService,
    private router: Router,
    private http: HttpClient,
    private dialogService: DialogService,
    private messageService: MessageService,
    private convertEnumFromBEPipe: ConvertEnumFromBEPipe,
    public regexpService: RegexpService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.regexpService.isRegexpInitialized.subscribe((isInitializated) => {
        if (isInitializated) {
          this._initializeForm();
        }
      })
    );
  }

  private _initializeForm(): void {
    const {
      websiteRegexp,
      youtubeRegexp,
      twitterRegexp,
      discordRegexp,
      telegramRegexp,
      instagramRegexp,
    } = this.regexpService;

    this.basicInfo = this.fb.group({
      title: [''],
      shortDescription: [
        '',
        [
          Validators.required,
          Validators.pattern(TEXT),
          Validators.minLength(50),
          Validators.maxLength(200),
        ],
      ],
      fullDescription: [
        '',
        [
          editorRequired(),
          Validators.pattern(TEXT),
          EditorValidators.minLength(150),
          EditorValidators.maxLength(3500),
        ],
      ],
      genres: [[], [Validators.required, Validators.maxLength(3)]],
      chains: [[]],
      releaseDate: [null],
      releaseStage: ['', Validators.required],
      screenshots: ['', [Validators.required]],
      bannerImage: ['', [Validators.required]],
      backgroundImage: ['', [Validators.required]],
      developer: [null],
    });

    this.installation = this.fb.group(
      {
        gameArchive: [null],
        gameExecutable: [''],
        previouslyPublished: [false],
        gameLink: ['', Validators.pattern(websiteRegexp)],
      },
      { validators: installationValidator() }
    );

    this.legal = this.fb.group({
      terms: ['', [Validators.required, Validators.pattern(websiteRegexp)]],
      license: ['', [Validators.pattern(websiteRegexp)]],
      policy: ['', [Validators.required, Validators.pattern(websiteRegexp)]],
    });

    this.links = this.fb.group({
      websiteUrl: [
        '',
        [Validators.required, Validators.pattern(websiteRegexp)],
      ],
      youtubeUrl: ['', [Validators.pattern(youtubeRegexp)]],
      twitterUrl: ['', [Validators.pattern(twitterRegexp)]],
      discordUrl: ['', [Validators.pattern(discordRegexp)]],
      telegramUrl: ['', [Validators.pattern(telegramRegexp)]],
      instagramUrl: ['', [Validators.pattern(instagramRegexp)]],
      supportWebsiteUrl: ['', [Validators.pattern(websiteRegexp)]],
      supportEmail: ['', [Validators.pattern(EMAIL)]],
    });

    this.gameReviewForm = this.fb.group({
      basicInfo: this.basicInfo,
      installation: this.installation,
      legal: this.legal,
      links: this.links,
      userConsent: [false, [Validators.requiredTrue]],
    });

    this.loadGameInfo();
  }

  loadGameInfo(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: ParamMap) => {
        const gameId: number = Number(params.get('game-id'));
        if (gameId) {
          this.gamesService.getGameById(gameId)
            ? this.handleGameData(gameId)
            : this.fetchGameData(gameId);
        }
      })
    );
  }

  private handleGameData(gameId: number): void {
    this.gameDraft = this.gamesService.getGameById(gameId) as GameData;
    if (this.gameDraft) {
      this.patchForm(this.gameDraft);
    }
  }

  private fetchGameData(gameId: number): void {
    this.subscriptions.add(
      this.gamesService.getGameByIdRequest(gameId).subscribe({
        next: (gameData: GameData) => {
          this.gameDraft = gameData;
          this.patchForm(gameData);
        },
        error: (err) => {
          console.error('Failed to fetch game data', err);
        },
      })
    );
  }

  patchForm(gameData: GameData): void {
    const {
      backgroundImageUri: backgroundImage,
      bannerImageUri: bannerImage,
      imageUris: screenshots,
      shortDescription,
      fullDescription,
      genres,
      chains,
      release,
    } = gameData?.info ?? {};
    const genreValues = genres?.map(
      (genre) => GAME_GENRES[genre as keyof typeof GAME_GENRES]
    );
    const chainValues = chains?.map(
      (chain) => GAME_CHAINS[chain as keyof typeof GAME_CHAINS]
    );
    const { eula, privacyPolicy, termsAndConditions } = gameData?.legal ?? {};
    const socialLinks = gameData?.links ?? {};
    const gameLink = gameData?.sourceLink?.url ?? '';

    if (gameLink) {
      this.installation.patchValue({
        gameLink,
      });

      this.installation.controls['gameExecutable'].setValidators([]);
      this.installation.controls['gameArchive'].setValidators([]);
    }

    // TODO: clean up
    const releaseStage = {
      label:
        release?.stage === 'TBD'
          ? 'TBD'
          : this.convertEnumFromBEPipe.transform(release?.stage ?? ''),
      value: release?.stage,
    };

    this.gameReviewForm.patchValue({
      basicInfo: {
        title: gameData.title,
        backgroundImage,
        bannerImage,
        screenshots,
        shortDescription,
        fullDescription,
        genres: genreValues,
        chains: chainValues,
        releaseDate: release?.date ? new Date(release.date) : null,
        releaseStage,
        developer: gameData.developer.displayName,
      },
      legal: {
        terms: termsAndConditions,
        license: eula,
        policy: privacyPolicy,
      },
      links: socialLinks,
    });

    if (this.isFormDisabled) {
      this.gameReviewForm.disable();
    }
  }

  updateGameBasicInfo(gameId: number) {
    if (this.basicInfo.invalid) return of(null);

    const {
      shortDescription,
      fullDescription,
      genres,
      screenshots,
      bannerImage,
      backgroundImage,
      chains,
      releaseDate,
      releaseStage,
    } = this.basicInfo.value;

    const genresEnumKeys = Object.keys(GAME_GENRES).filter((key) =>
      genres.includes(GAME_GENRES[key as keyof typeof GAME_GENRES])
    );
    const chainsEnumKeys = Object.keys(GAME_CHAINS).filter((key) =>
      chains?.includes(GAME_CHAINS[key as keyof typeof GAME_CHAINS])
    );

    const basicInfoReqBody = {
      gameId,
      shortDescription,
      fullDescription,
      backgroundImageUri: backgroundImage,
      bannerImageUri: bannerImage,
      genres: genresEnumKeys,
      chains: chainsEnumKeys,
      imageUris: screenshots,
      release: {
        date: releaseDate,
        stage: releaseStage.value,
      },
    };

    return this.http.put<unknown>(
      `${environment.devportalApi}/api/game/create-or-update/basic-info`,
      basicInfoReqBody
    );
  }

  updateGameLegalInfo(gameId: number) {
    if (this.legal.invalid) return of(null);

    const { terms, license, policy } = this.legal.value;

    const legalReqBody = {
      gameId,
      termsAndConditions: terms,
      privacyPolicy: policy,
      eula: license?.length ? license : null,
    };

    return this.http.put<unknown>(
      `${environment.devportalApi}/api/game/create-or-update/legal`,
      legalReqBody
    );
  }

  updateGameSocialLinks(gameId: number) {
    if (this.links.invalid) return of(null);
    const {
      websiteUrl,
      youtubeUrl,
      twitterUrl,
      discordUrl,
      telegramUrl,
      instagramUrl,
      supportWebsiteUrl,
      supportEmail,
    } = this.links.value;

    const socialReqBody = {
      gameId,
      websiteUrl,
      youtubeUrl: youtubeUrl?.length ? youtubeUrl : null,
      twitterUrl: twitterUrl?.length ? twitterUrl : null,
      discordUrl: discordUrl?.length ? discordUrl : null,
      telegramUrl: telegramUrl.length ? telegramUrl : null,
      instagramUrl: instagramUrl?.length ? instagramUrl : null,
      supportWebsiteUrl: supportWebsiteUrl.length ? supportWebsiteUrl : null,
      supportEmail: supportEmail.length ? supportEmail : null,
    };

    return this.http.put<unknown>(
      `${environment.devportalApi}/api/game/create-or-update/links`,
      socialReqBody
    );
  }

  uploadGameLink(id: number, url: string) {
    return this.http.put(
      `${environment.devportalApi}/api/game/create-or-update/source-link`,
      { gameId: id, url }
    );
  }

  onSubmit(): void {
    const gameId = this.gameDraft?.id as number;

    this.saveGameDraft(gameId)
      .pipe(
        switchMap((res: any) => {
          if (this.installation.get('previouslyPublished')?.value) {
            return of(null);
          }

          const gameLinkValue = this.installation.controls['gameLink'].value;

          if (gameLinkValue) {
            return this.uploadGameLink(gameId, gameLinkValue);
          }

          this.messageService.add({
            severity: NOTIFICATION.INFO,
            summary: 'Uploading',
            detail: 'Uploading your game, please hold on',
          });

          const upload$ = this.uploadGameArchive();

          return upload$ ? upload$ : of(null);
        }),
        switchMap((res) => {
          return this.http.put<unknown>(
            `${environment.devportalApi}/api/game/status/review/send/${gameId}`,
            {}
          );
        })
      )
      .subscribe({
        next: (res) => {
          this.gameDraft = res as GameData;

          this.messageService.add({
            severity: NOTIFICATION.SUCCESS,
            summary: 'Success',
            detail: 'Game files were successfully uploaded',
          });

          setTimeout(() => {
            this.messageService.add({
              severity: NOTIFICATION.SUCCESS,
              summary: 'Success',
              detail: 'Thank you for submitting your game for review',
            });
          }, 1000);

          this.router.navigateByUrl(`app/game-reviewing/${gameId}`);
        },
        error: (err) => {
          console.error('Error during game submission:', err);
          this.messageService.add({
            severity: NOTIFICATION.ERROR,
            summary: 'Error',
            detail:
              'There was an issue with your game submission. Please try again later.',
          });
        },
      });
  }

  uploadGameArchive() {
    const gameArchive = this.installation.get('gameArchive')?.value;

    const relativeExePath = this.installation.controls['gameExecutable'].value;

    if (!gameArchive || !relativeExePath || !this.gameDraft?.id)
      return of(null);

    return this.gamesService.uploadGameArchiveByChunks(
      this.gameDraft.id,
      gameArchive,
      relativeExePath
    );
  }

  saveGameDraft(gameId: number) {
    return forkJoin({
      basicInfo: this.updateGameBasicInfo(gameId),
      legal: this.updateGameLegalInfo(gameId),
      social: this.updateGameSocialLinks(gameId),
    }).pipe(
      map((responses) => {
        return Object.assign({}, ...Object.values(responses).filter(Boolean));
      })
    );
  }

  onOpenDistributionAgreement(): void {
    this.dialogService.open(DistributionAgreementDialogComponent, {});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (
      this.gameReviewForm.touched &&
      this.gameDraft?.id &&
      this.gameDraft.status !== GAME_STATUS.REVIEW
    ) {
      this.saveGameDraft(this.gameDraft.id).subscribe();
    }
  }
}
