<div>
  <p class="text-white text-xl line-height-3 my-5">
    Describe the reason for the game's revisions. A detailed description will
    help the game owner successfully resolve the issue.
  </p>

  <textarea
    class="bg-gray-1000 text-white w-full border-1 border-gray-800 mb-6 p-4 border-round-lg custom-scroll"
    style="outline: none; resize: none; font-family: 'Roboto', sans-serif"
    placeholder="Description"
    rows="7"
    [(ngModel)]="feedback"
  ></textarea>

  <div class="flex gap-2 justify-content-end">
    <p-button
      severity="secondary"
      label="Cancel"
      (onClick)="onCancel()"
    ></p-button>
    <p-button label="Send" (onClick)="onRequesRevisionsClick()"></p-button>
  </div>
</div>
