<div
  class="fixed bottom-0 w-screen h-4rem bg-gray-700 text-gray-200 z-1 flex align-items-center justify-content-center"
>
  <div
    class="text-center cursor-pointer flex-1"
    (click)="onOpenSupportCenter()"
  >
    <span class="pi pi-question-circle text-base block mb-1"></span>
    <span>Help Center</span>
  </div>
  <div
    class="text-center cursor-pointer flex-1"
    [routerLink]="['/app/home']"
    routerLinkActive="text-white"
  >
    <span class="pi pi-home text-base block mb-1"></span>
    <span>Home</span>
  </div>
  <div
    class="text-center cursor-pointer flex-1"
    (click)="isMobileSidebarVisible = true"
  >
    <span class="pi pi-bars text-base block mb-1"></span>
    <span>Menu</span>
  </div>
</div>

<p-sidebar [(visible)]="isMobileSidebarVisible">
  <ng-template pTemplate="headless">
    <div class="flex flex-column gap-3 text-gray-300 pt-4 px-3 text-lg">
      <div
        class="p-2 cursor-pointer"
        [routerLink]="['/app/home']"
        routerLinkActive="text-white border-left-2 border-primary"
        (click)="isMobileSidebarVisible = false"
      >
        <span class="pi pi-home mr-2"></span>
        <span> Home </span>
      </div>
      <div
        class="p-2 cursor-pointer"
        [routerLink]="['/app/documentation']"
        routerLinkActive="text-white border-left-2 border-primary"
        (click)="isMobileSidebarVisible = false"
      >
        <span class="pi pi-file-o mr-2"></span>
        <span> Documentation </span>
      </div>
      <div
        class="p-2 cursor-pointer"
        [routerLink]="['/app/referral']"
        routerLinkActive="text-white border-left-2 border-primary"
        (click)="isMobileSidebarVisible = false"
      >
        <span class="pi pi-user-plus mr-2"></span>
        <span> Invite Friends </span>
      </div>
    </div>
  </ng-template>
</p-sidebar>
