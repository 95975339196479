import { NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../shared/services/admin/admin.service';
import { TagModule } from 'primeng/tag';
import { GameData } from '../../../tools/interfaces/response.interfaces';
import {
  GAME_CHAINS,
  GAME_GENRES,
  GAME_STATUS,
} from '../../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [
    NgStyle,
    TagModule,
    ConvertEnumFromBEPipe,
    PaginatorModule,
    MultiSelectModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  templateUrl: './admin-panel.component.html',
})
export class AdminPanelComponent implements OnInit {
  readonly chains = Object.entries(GAME_CHAINS).map(([key, value]) => {
    return { key, value };
  });
  readonly genres = Object.entries(GAME_GENRES).map(([key, value]) => {
    return { key, value };
  });
  readonly status = Object.entries(GAME_STATUS)
    .map(([key, value]) => {
      return { key, value };
    })
    .filter((entry) => entry.key !== GAME_STATUS.DRAFT);

  private games: GameData[] = [];

  paginatedGames: GameData[] = [];
  filteredGames: GameData[] = [];
  rows: number = 10;
  totalRecords: number = 0;
  suggestions: string[] = [];

  gameFilterForm: FormGroup<{
    chains: FormControl<
      {
        key: string;
        value: GAME_CHAINS;
      }[]
    >;
    genres: FormControl<
      {
        key: string;
        value: GAME_GENRES;
      }[]
    >;
    status: FormControl<
      {
        key: string;
        value: GAME_STATUS;
      }[]
    >;
    title: FormControl<string>;
  }>;

  constructor(
    private router: Router,
    private adminService: AdminService,
    private fb: FormBuilder
  ) {
    this.gameFilterForm = this.fb.nonNullable.group({
      chains: [this.chains],
      genres: [this.genres],
      status: [this.status],
      title: [''],
    });
  }

  ngOnInit(): void {
    this.loadGamesForReview();
    this.subscribeToFilterChanges();
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.suggestions = this.filteredGames
      .map((game) => {
        return game.title;
      })
      .filter((title) => {
        return title.startsWith(event.query);
      });
  }

  onPaginate(event: PaginatorState): void {
    const start: number = event.first ?? 0;
    const end: number = start + (event.rows ?? 0);
    this.paginatedGames = this.filteredGames.slice(start, end);
  }

  onGameClick(gameId: number): void {
    this.router.navigateByUrl(`/admin/gameslist/${gameId}`);
  }

  private loadGamesForReview(): void {
    this.adminService.getGamesForReview().subscribe((res) => {
      this.games = res;
      this.filteredGames = this.games.slice();

      this.totalRecords = this.games.length;
      this.onPaginate({ first: 0, rows: this.rows });
    });
  }

  private subscribeToFilterChanges(): void {
    this.gameFilterForm.valueChanges.subscribe(() => {
      const filters = this.gameFilterForm.getRawValue();
      this.applyFilters(filters);

      this.totalRecords = this.filteredGames.length;
      this.onPaginate({ first: 0, rows: this.rows });
    });
  }

  private applyFilters(filters: {
    chains: {
      key: string;
      value: GAME_CHAINS;
    }[];
    genres: {
      key: string;
      value: GAME_GENRES;
    }[];
    status: {
      key: string;
      value: GAME_STATUS;
    }[];
    title: string;
  }): void {
    this.filteredGames = this.games.filter((game) => {
      const allChainsSelected: boolean =
        filters.chains.length === Object.keys(GAME_CHAINS).length;
      const noChainsSelected: boolean = filters.chains.length === 0;

      const matchChains: boolean =
        allChainsSelected || noChainsSelected
          ? filters.chains.some((filterChain) =>
              game.info?.chains.includes(filterChain.key)
            ) || game.info?.chains.length === 0
          : filters.chains.some((filterChain) =>
              game.info?.chains.includes(filterChain.key)
            );

      const matchGenres: boolean =
        filters.genres.length > 0 &&
        filters.genres.some((filterGenre) =>
          game.info?.genres.includes(filterGenre.key)
        );

      const matchStatus: boolean = filters.status
        .map((status) => status.key)
        .includes(game.status);

      const matchName: boolean = game.title.includes(filters.title);

      return matchChains && matchGenres && matchStatus && matchName;
    });
  }
}
