import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-body-background-setter',
  template: '',
  standalone: true,
})
export class BodyBackgroundSetterComponent implements AfterViewInit, OnDestroy {
  @Input() color: string = '#0a0118';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      this.color;
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      '#0b0b0d';
  }
}
