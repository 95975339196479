<h2 class="text-white text-xl my-4">
  Are you sure you want to delete your account?
</h2>

<p class="text-gray-200 text-md line-height-3 mb-5">
  Account and all associated data will be deleted. This action permanent and
  irreversible. Enter "Delete Account" below to delete.
</p>

<form [formGroup]="deleteAccountForm" (ngSubmit)="deleteAccountRequest()">
  <div class="mb-5">
    <input
      type="text"
      placeholder="Delete Account"
      pInputText
      formControlName="confirmation"
      class="p-input w-full"
    />
  </div>

  <div class="flex gap-2 justify-content-end">
    <p-button
      severity="secondary"
      label="Cancel"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      severity="danger"
      label="Delete Account"
      [disabled]="deleteAccountForm.invalid"
      (onClick)="deleteAccountRequest()"
    ></p-button>
  </div>
</form>
