@if (title && iconUri) {
<div class="h-full relative">
  <img
    src="./assets/illustrations/game-reviewing.png"
    alt=""
    class="absolute"
    [ngStyle]="{
      top: '20%',
      left: '50%',
      transform: 'translateX(-50%)'
    }"
  />
  <div
    class="relative flex justify-content-center align-items-center flex-column h-full pt-8"
  >
    <img
      [src]="iconUri"
      class="mb-4"
      alt="game icon"
      width="200"
      height="200"
      [ngStyle]="{
        'aspect-ratio': '1 / 1',
        'object-fit': 'cover',
        'object-position': 'center'
      }"
    />
    <h2 class="text-2xl mb-2">The game {{ title }} is under review</h2>
    <p class="text-lg mb-5">We'll notify you once it's done.</p>
    <p-button
      label="Go home"
      class="p-button-primary p-button-md"
      (onClick)="goHome()"
    ></p-button>
  </div>
</div>
}

<app-body-background-setter></app-body-background-setter>
