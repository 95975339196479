import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ThousandSeparatorPipe } from '../../../shared/pipes/thousand-separator.pipe';
import { UserNep } from '../../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-referral-statistic',
  standalone: true,
  imports: [ButtonModule, ThousandSeparatorPipe],
  templateUrl: './referral-statistic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralStatisticComponent implements OnChanges {
  @Input() userNep!: UserNep | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userNep'] && changes['userNep'].currentValue !== null) {
      const currentValue = changes['userNep'].currentValue;

      console.log(currentValue);

      this.statisticData[0].value = currentValue.nepReward.GMRX.toString();
      this.statisticData[1].value =
        currentValue.numberOfReferrals.toString();
      this.statisticData[2].value = currentValue.hoursMonetized.toString();
    }
  }

  public statisticData = [
    {
      title: 'Total earned',
      icon: 'earned.svg',
      value: '-',
      isGmrx: true,
      hasButton: false,
    },
    {
      title: 'My Referrals',
      icon: 'refferals.svg',
      value: '-',
      isGmrx: false,
      hasButton: false,
    },
    {
      title: 'Hours Monetized | last 7 days',
      icon: 'hours_monetizated.svg',
      value: '-',
      isGmrx: false,
      hasButton: false,
    },
  ];
}
