<div
  class="border-1 border-round-xl border-gray-700 text-center relative cursor-pointer transition-all transition-duration-300 overflow-hidden"
  [ngClass]="classNames"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (click)="fileInput.click()"
>
  <input
    id="file"
    type="file"
    accept="image/*"
    [multiple]="multiple"
    #fileInput
    style="display: none"
    (input)="onFileSelected($event)"
  />
  <div
    class="flex flex-column justify-content-center align-items-center h-full w-full"
  >
    <ng-content></ng-content>
  </div>
</div>
