import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DeleteAccountDialogComponent } from '../../../shared/modals/delete-account-dialog/delete-account-dialog.component';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-delete-tab',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './delete-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteTabComponent {
  constructor(private dialogService: DialogService) {}

  onDeleteAccount(): void {
    this.dialogService.open(DeleteAccountDialogComponent, {});
  }
}
