<div
  class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-3 mb-5"
>
  <div>
    <span class="text-white text-md block line-height-4">Delete account</span>
    <span class="text-md text-gray-400">The account cannot be recovered</span>
  </div>
  <p-button
    (onClick)="onDeleteAccount()"
    label="Delete account"
    severity="secondary"
  >
  </p-button>
</div>
