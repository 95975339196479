import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  AccessToken,
  GaiminProfile,
  Profile,
} from '../../../tools/interfaces/response.interfaces';
import { environment } from '../../../../environments/environment';
import { USER_ROLE } from '../../../tools/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthRequestsService {
  constructor(private http: HttpClient) {}

  getUserInfoRequest(): Observable<Profile> {
    return this.http.get<Profile>(`${environment.devportalApi}/api/user/me`);
  }

  getPlatformUserInfoRequest(): Observable<GaiminProfile> {
    return this.http.get<GaiminProfile>(`${environment.gaiminApi}/api/users/me`);
  }

  getUserRolesRequest(): Observable<USER_ROLE[]> {
    return this.http.get<USER_ROLE[]>(
      `${environment.devportalApi}/api/auth/roles`
    );
  }

  updateUserPassword(): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/update`,
      {}
    );
  }

  resetPasswordRequest(email: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/request-reset`,
      {
        email,
      }
    );
  }

  resetPasswordConfirmationRequest(
    token: string,
    password: string
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/confirm-reset`,
      {
        token,
        password,
      }
    );
  }

  signUpRequest(
    developerTag: string,
    registeredAddress: string,
    accessToken: string
  ) {
    return this.http.post(`${environment.devportalApi}/api/user/sign-up`, {
      jwt: accessToken,
      displayName: developerTag,
      registeredAddress,
    });
  }

  migrateAccountRequest(email: string, password: string, accessToken: string) {
    return this.http.post(
      `${environment.devportalApi}/api/user/migrate`,
      {
        emailOrUsername: email,
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  refreshAccessTokenRequest(refreshToken: string | null) {
    return this.http.post<AccessToken>(
      `${environment.gaiminAuth}/api/auth/token/refresh`,
      {
        refreshToken,
      }
    );
  }
}
