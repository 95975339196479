<div [formGroup]="legalForm">
  <h3 class="text-white text-2xl mb-5 mt-4">Legal documents links</h3>

  <div class="mb-4 relative">
    <label for="title" class="text-md block text-gray-400 mb-1">
      Terms and conditions*
    </label>
    <input
      id="title"
      type="title"
      placeholder="https://www.example.com"
      pInputText
      class="w-full"
      formControlName="terms"
      [appValidationMessage]="legalForm.get('terms')"
      [validationMessages]="{
        required: 'Terms and Conditions are required',
        pattern: 'Invalid URL for Terms and Conditions',
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="title" class="text-md block text-gray-400 mb-1">
      Privacy Policy*
    </label>
    <input
      id="title"
      type="title"
      placeholder="https://www.example.com"
      pInputText
      class="w-full"
      formControlName="policy"
      [appValidationMessage]="legalForm.get('policy')"
      [validationMessages]="{
        required: 'Privacy Policy is required',
        pattern: 'Invalid URL for Privacy Policy',
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="title" class="text-md block text-gray-400 mb-1"
      >End User License Agreement (EULA)</label
    >
    <input
      id="title"
      type="title"
      placeholder="https://www.example.com"
      pInputText
      class="w-full"
      formControlName="license"
      [appValidationMessage]="legalForm.get('license')"
      [validationMessages]="{
        pattern: 'Invalid URL for EULA',
      }"
    />
  </div>
</div>
