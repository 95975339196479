<div class="documentation">
  <h1 class="documentation__title">Documentation</h1>

  <div class="documentation__banner">
    <p class="documentation__banner-text">
      Make your development easier<br />with our Development tool
    </p>
  </div>

  <div class="documentation__wrapper">
    <div class="documentation__sidebar">
      <div class="documentation__sidebar-container">
        <h3 class="documentation__nav-title">Categories</h3>
        <ul class="documentation__nav">
          <li>
            <a
              class="documentation__nav-link"
              [ngClass]="{
                'documentation__nav-link--active':
                  currentSection === sections.GAME_UPLOADING
              }"
              routerLink="/app/documentation/gameuploading"
              >Game Uploading
            </a>

            <ul
              class="documentation__nav-subcategories"
              [ngClass]="{
                'documentation__nav-subcategories--hidden':
                  currentSection !== sections.GAME_UPLOADING
              }"
            >
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="introduction"
                  (click)="scrollToAnchor('introduction')"
                  >Introduction</a
                >
              </li>
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="register"
                  (click)="scrollToAnchor('register')"
                  >Instructions to Register and Add a Game</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              class="documentation__nav-link"
              [ngClass]="{
                'documentation__nav-link--active':
                  currentSection === sections.GETTING_STARTED
              }"
              routerLink="/app/documentation/gettingstarted"
              router
              >Getting started</a
            >

            <ul
              class="documentation__nav-subcategories"
              [ngClass]="{
                'documentation__nav-subcategories--hidden':
                  currentSection !== sections.GETTING_STARTED
              }"
            >
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="account-register"
                  (click)="scrollToAnchor('account-register')"
                  >Register for your account.</a
                >
              </li>
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="game-upload"
                  (click)="scrollToAnchor('game-upload')"
                  >Upload a Game</a
                >
              </li>
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  (click)="scrollToAnchor('more-games')"
                  fragment="more-games"
                  >Adding more games</a
                >
              </li>
              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="settings"
                  (click)="scrollToAnchor('settings')"
                  >Account Settings</a
                >
              </li>

              <li>
                <a
                  class="documentation__nav-subcategory"
                  [routerLink]="[]"
                  fragment="documentation"
                  (click)="scrollToAnchor('documentation')"
                  >Documentation Section</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              class="documentation__nav-link"
              [ngClass]="{
                'documentation__nav-link--active':
                  currentSection === sections.API
              }"
              routerLink="/app/documentation/api"
              >API</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="documentation__content">
      @switch (currentSection) { @case (sections.GETTING_STARTED) {
      <ng-container>
        <h2 class="documentation__tab-content-title" id="getting-started">
          Getting Started
        </h2>

        <h3 class="documentation__section-title" id="account-register">
          Register for your account.
        </h3>

        <ul class="documentation__ul-list">
          <li>Go to the Sign up page</li>
          <li>Enter the requested information</li>
          <li>Accept the Privacy Policy and End User Licence Agreement</li>
          <li>Click Sign up</li>
        </ul>

        <h3 class="documentation__section-title" id="game-upload">
          Upload a Game
        </h3>

        <ol class="documentation__num-list">
          <li>From the Home page click on “Add game”</li>
          <li>
            Enter the game details
            <ol class="documentation__num-list" type="a">
              <li>Game Title</li>
              <li>Game logo, make sure the icon's aspect ratio is 1:1</li>
            </ol>
          </li>
          <li>
            Click “Add game” - this will create a profile area for the game and
            allow later uploading of game assets
          </li>
        </ol>

        <p class="documentation__paragraph">
          You are now ready to start to upload your game’s assets in readiness
          for GAIMIN’s review. Note for any graphics uploads you can either
          upload directly or drag and drop.
        </p>

        <p class="documentation__paragraph">
          You are responsible for checking any text you enter; GAIMIN will use
          this within the Games Launcher “as given”. We may edit and amend to
          fit any style formats, and we may correct spelling and grammar if we
          spot anything however we cannot guarantee to correct all errors!
          Please follow all instructions regarding word/character counts, image
          formats etc.
        </p>

        <ol class="documentation__num-list">
          <li>
            Click on “Go to GAIMIN Review” and add the following information:

            <ol class="documentation__num-list" type="a">
              <li>
                Short Description - Add a brief description of your game (50-200
                characters)
              </li>
              <li>
                Full Description - Add a full description of your game (150-2300
                characters)
              </li>
              <li>Select the genre of the game from the drop-down list</li>
              <li>
                Select the supported chains from the drop-down list (if
                applicable)
              </li>

              <li id="images">
                <h3>Media Content</h3>

                <p>
                  <b>
                    The maximum size for an item of media content is 1 MB.
                    You can upload up to 10 MB in one request.
                  </b>
                </p>

                <h4>Icon</h4>
                <p>
                  The Game Icon is featured on the platform's home page in the
                  games section. Ensure the aspect ratio is 1:1 for the best
                  visual fit.
                </p>

                <h4>Banner</h4>
                <p>
                  The Game Banner is featured on the platform's game listing
                  page, where all available games are displayed. Ensure the
                  aspect ratio is 16:9 for the best visual fit.
                </p>

                <h4>Background</h4>
                <p>
                  The Game Background image is displayed as the background on
                  the game page. You can preview how it appears in the game page
                  preview. The recommended aspect ratio is 16:9 for optimal
                  display.
                </p>

                <h4>Images</h4>
                <p>
                  Game Images are used to showcase screenshots or visuals that
                  represent key elements of the game.
                </p>
              </li>

              <li>Press the “Next step” button</li>
              <li>
                Upload the Game - Select the game archive which contains the
                game files. Please note we only currently support .zip files so
                please ensure you have created a .zip file with all the
                necessary software components for a successful game
                installation.
              </li>
              <li>Select the target game executable:</li>
              <li>
                <p>
                  Once the game archive is selected, a list of included exe
                  files will be available from the dropdown selector. You can
                  choose the correct one, that will launch your game.
                </p>

                <p>
                  Executable name also include relative path, to ensure the
                  difference between files with the same name within inner
                  directories.
                </p>
              </li>

              <li>Press the “Next step” button</li>
              <li>
                Add any legal documentation links for your game
                <ol class="documentation__num-list" type="i">
                  <li>
                    Terms and Conditions, and Privacy Policy are mandatory
                  </li>
                  <li>End User License Agreement is optional</li>
                </ol>
              </li>
              <li>
                Add any social media links for your game including the game
                website.
                <ol class="documentation__num-list" type="i">
                  <li>Game website and Game telegram URL are mandatory</li>
                  <li>Other social media links are optional</li>
                </ol>
              </li>
              <li>Add links to your support channels</li>
              <li>
                Read the Terms and Conditions document for Game Distribution and
                assuming you are comfortable with the obligations, tick the box
                to accept the terms and conditions. However, we cannot proceed
                if you do not accept the terms and conditions.
              </li>
              <li>
                Please check all submissions. Content can be edited and amended
                after submission but to avoid delays, please check everything
                before submission.
              </li>
            </ol>
          </li>

          <li>
            <p class="documentation__paragraph">
              Click on the “Send to Review” button.
            </p>

            <p class="documentation__paragraph">
              The GAIMIN Games Team will review your submission and undertake
              various checks including game installation, game play, compliance
              to acceptable use policy (including but not limited to illegal,
              inappropriate or discriminatory content). During the review
              process you may be asked to provide more information - this is
              requested through the “View Feedback” button. To avoid delays in
              the review of your game, please respond quickly to any request. To
              amend or update information, please go to “Games Settings”, update
              or amend the information and resend it.
            </p>
            <p class="documentation__paragraph">
              If the GAIMIN Games team accepts your game for inclusion on the
              Games Launcher, they will liaise with you to agree a launch date
              and any objectives for inclusion or any further requirements for
              launching the game. If GAIMIN decides not to include your game on
              the Games Launcher you will be provided feedback to explain the
              decision.
            </p>
          </li>

          <li>
            Game Setting Options

            <ol class="documentation__num-list" type="a">
              <li>
                Delete the game - To delete the game, enter the game title and
                select the “Delete” button.
              </li>
              <li>
                Unpublish (stop the publishing of the game) - Use the “Delete”
                game controls
              </li>
              <li>
                Please update the Games review team with feedback to explain
                your decision to delete or unpublish the game.
              </li>
            </ol>
          </li>
        </ol>

        <h3 class="documentation__section-title" id="more-games">
          Adding more games
        </h3>

        <p class="documentation__paragraph">
          Once you have set up your account you can upload more games. You only
          need to agree the Privacy Policy and EULA on account set up, however
          you need to agree the Games Distribution Agreement for each game you
          submit for inclusion on the Games Launcher.
        </p>

        <h3 class="documentation__section-title" id="settings">
          Account Settings
        </h3>

        <p class="documentation__paragraph">In “Account Settings” you can:</p>

        <ul class="documentation__num-list">
          <li>Edit account information</li>
          <li>Change password</li>
          <li>
            Delete the account (Delete account is only possible if it was set up
            with basic credentials, accounts created with social media accounts
            cannot be deleted)
          </li>
        </ul>

        <h3 class="documentation__section-title" id="documentation">
          Documentation Section
        </h3>

        <p class="documentation__paragraph">
          In “Documentation” you will find instructions to:
        </p>

        <ul class="documentation__num-list">
          <li>Use the Game Developer Portal</li>
          <li>Add a game</li>
          <li>List of GAIMIN APIs and instructions on how to use them</li>
        </ul>
      </ng-container>

      } @case (sections.API) {
      <ng-container>
        <h2 class="documentation__tab-content-title">API</h2>
        <h3 class="documentation__section-title">Actions</h3>

        <table class="documentation__table">
          @for (endpoint of endpoints; track $index) {
          <tr>
            <td class="documentation__table-cell">
              <p
                class="documentation__method documentation__method--get"
                [ngClass]="'documentation__method--' + endpoint.method"
              >
                {{ endpoint.method.toUpperCase() }}
              </p>
            </td>

            <td class="documentation__table-cell">
              {{ endpoint.path }}
            </td>

            <td class="documentation__table-cell">
              {{ endpoint.summary }}
            </td>
          </tr>
          }
        </table>
      </ng-container>
      } @case (sections.GAME_UPLOADING) {
      <ng-container>
        <h2 class="documentation__tab-content-title">
          Game Uploading and User Documentation
        </h2>

        <h3 class="documentation__section-title" id="introduction">
          Introduction
        </h3>

        <p class="documentation__paragraph">
          GAIMIN’s Games Developer Portal allows a Game Developer to upload
          their game(s) for potential inclusion on the GAIMIN Game Launcher.
          Before uploading a game, the game developer needs to set up an account
          and register with GAIMIN, confirming they have read and accepted 2
          agreements - the Privacy Policy and the End User Licence Agreement
          (EULA) for use of the Game Developer Portal.
        </p>
        <p class="documentation__paragraph">
          Following registration, the Game Developer will be requested to upload
          their first game, including any associated assets and confirm
          acceptance of the Game Distribution Agreement.
        </p>

        <p class="documentation__paragraph">
          GAIMIN will review the game and, on the basis it meets the appropriate
          criteria, will agree to launch the game on the Games Launcher.
        </p>

        <p class="documentation__paragraph">
          GAIMIN reserves the right to not accept a game on its Games Launcher.
          If an application to launch a game is rejected, GAIMIN will advise the
          Game Developer of the reason for rejection.
        </p>

        <h3 class="documentation__section-title" id="register">
          Instructions to Register and Add a Game
        </h3>

        <p class="documentation__paragraph">
          The Game Developer Portal can be accessed at:
          https://developer.gaimin.gg/
        </p>

        <p class="documentation__paragraph">
          You can register as either a corporate game developer (with a legal
          company entity) or a personal game developer (private individual).
        </p>

        <p class="documentation__paragraph">You need to provide:</p>

        <ul class="documentation__ul-list">
          <li>Email address & Password</li>
          <li>Username</li>
          <li>Developer Tag (Company name)</li>
          <li>Registered address</li>
        </ul>

        <p class="documentation__paragraph">
          As part of our audit requirements you may be required to submit
          KYC/AML information so please ensure that should this be requested you
          can provide proof of identification and proof of address for the
          information used to register your account.
        </p>
      </ng-container>
      } }
    </div>
  </div>
</div>
