import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Input } from '@angular/core';
import { GamesService } from '../../services/games.service';
import { IMAGE_TYPE } from '../../../tools/constants/image.constants';
import { MessageService } from 'primeng/api';
import { filter, from, map, mergeMap, Observable, toArray } from 'rxjs';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';

@Component({
  selector: 'app-media-upload',
  standalone: true,
  imports: [NgClass],
  templateUrl: './media-upload.component.html',
})
// TODO: refactor completely
export class MediaUploadComponent {
  @ViewChild('fileInput')
  fileInput!: ElementRef<HTMLInputElement>;

  @Input() multiple: boolean = false;
  @Input() classNames: string = '';
  @Input() gameId?: number;
  @Input() imageType!: IMAGE_TYPE;

  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() imagesUploaded: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  constructor(
    private gamesService: GamesService,
    private messageService: MessageService
  ) {}

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.add('border-white');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('border-white');
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('border-white');

    if (!event.dataTransfer?.files) return;

    this.handleFileSelection(event.dataTransfer.files);
  }

  onFileSelected(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();

    const input = event.target as HTMLInputElement;
    if (!input.files) return;

    this.handleFileSelection(input.files);

    input.value = '';
  }

  convertFileListToArray(files: FileList): File[] {
    const filesArray: File[] = Array.from(files as ArrayLike<File>);
    return filesArray;
  }

  handleFileSelection(files: FileList): void {
    const filesArr: File[] = this.convertFileListToArray(files);
    const filesSize = filesArr.reduce((acc, item) => acc + item.size, 0);
    const maxFilseSize = 10 * 1024 * 1024; // 10 MB

    if (filesSize > maxFilseSize) {
      this.throwErrorNotification(
        `You can upload not more than 10 MB per one request`
      );

      return;
    }

    this.validateImages(filesArr).subscribe((validImages: File[]) => {
      this.filesSelected.emit(validImages);

      if (!this.gameId) return;

      if (validImages.length) {
        this.gamesService
          .uploadGameImages(validImages, this.gameId)
          .subscribe((images: string[]) => {
            this.imagesUploaded.emit(images);
          });
      }
    });
  }

  getIsValidImage(
    file: File,
    properties: { width: number; height: number; size: number }
  ): boolean {
    if (!file.type.startsWith('image')) {
      return false;
    }

    const minWidth = 480;
    const minHeight = 250;
    const minBannerWidth = 300;
    const minBannerHeight = 140;
    const minIconWidth = 200;
    const minIconHeight = 200;
    const maxWidth = 4096;
    const maxHeight = 2160;
    const minAspectRatio = 16 / 10;
    const maxAspectRatio = 21 / 9;
    const maxSize = 1 * 1024 * 1024; // 1 MB

    const { width, height, size } = properties;
    const aspectRatio = width / height;

    if (size > maxSize) {
      this.throwErrorNotification(`Max size for ${this.imageType} is 1MB`);

      return false;
    }

    if (width > maxWidth || height > maxHeight) {
      this.throwErrorNotification(`Max dimensions for images are 4096x2160`);

      return false;
    }

    switch (this.imageType) {
      case IMAGE_TYPE.ICON:
        if (aspectRatio !== 1) {
          this.throwErrorNotification('Game icon aspect ratio must be 1:1');

          return false;
        }

        if (width < minIconWidth || height < minIconHeight) {
          this.throwErrorNotification(
            'Game icon dimensions must be not less then 200x200'
          );

          return false;
        }

        return true;

      case IMAGE_TYPE.BANNER:
        if (width < minBannerWidth || height < minBannerHeight) {
          this.throwErrorNotification(
            'Game banner dimensions must be not less then 300x140'
          );

          return false;
        }

        if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
          this.throwErrorNotification(
            'Game banner aspect ratio must be in range from 16:10 to 21:9'
          );

          return false;
        }

        return true;

      case IMAGE_TYPE.BACKGROUND:
      case IMAGE_TYPE.IMAGE:
        if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
          this.throwErrorNotification(
            `Game ${this.imageType} aspect ratio must be in range from 16:10 to 21:9`
          );

          return false;
        }

        if (width < minWidth || height < minHeight) {
          this.throwErrorNotification(
            `Game ${this.imageType} dimensions must be not less then 480x250`
          );

          return false;
        }

        return true;

      default:
        return false;
    }
  }

  validateImages(images: File[]): Observable<File[]> {
    return from(images).pipe(
      mergeMap((image) =>
        this.getImageProperties(image).pipe(
          filter((dimensions) => this.getIsValidImage(image, dimensions)),
          map(() => image)
        )
      ),
      toArray()
    );
  }

  private getImageProperties(
    image: File
  ): Observable<{ width: number; height: number; size: number }> {
    return new Observable((observer) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event: any) => {
        img.src = event.target.result;
      };

      img.onload = () => {
        observer.next({
          width: img.width,
          height: img.height,
          size: image.size,
        });
        observer.complete();
      };

      reader.readAsDataURL(image);
    });
  }

  private throwErrorNotification(message: string) {
    this.messageService.add({
      severity: NOTIFICATION.ERROR,
      detail: message,
    });
  }
}
