import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-review-feedback-dialog',
  standalone: true,
  imports: [ButtonModule, NgStyle],
  templateUrl: './review-feedback-dialog.component.html',
})
export class ReviewFeedbackDialogComponent {
  get feedback(): string {
    return this.config.data.feedback;
  }

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.config.styleClass = 'bg-gray-1000 w-600px';
    this.config.header = 'Review Feedback';
  }

  onCancel(): void {
    this.ref.close();
  }
}
