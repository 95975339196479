@if (!comingSoon && route) {
<div
  class="p-4 text-center flex justify-content-center align-items-center cursor-pointer text-xl"
  [routerLink]="[route]"
  routerLinkActive="border-left-2 border-primary filter-white"
  (click)="router.navigateByUrl(route)"
  [pTooltip]="alt"
>
  <img [src]="'./assets/icons/' + icon" [alt]="alt" />
</div>
} @else {
<div
  class="p-4 text-center flex justify-content-center align-items-center text-xl"
  pTooltip="Coming Soon"
>
  <img [src]="'./assets/icons/' + icon" [alt]="alt" />
</div>
}
