import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LayoutSidebarMenuitemComponent } from './layout-sidebar-menuitem/layout-sidebar-menuitem.component';

@Component({
  selector: 'app-layout-sidebar',
  standalone: true,
  imports: [RouterModule, LayoutSidebarMenuitemComponent],
  templateUrl: './layout-sidebar.component.html',
})
export class LayoutSidebarComponent {
  readonly menuItems: {
    route?: string;
    icon: string;
    alt: string;
    comingSoon?: boolean;
  }[] = [
    { route: '/app/home', icon: 'home-icon.svg', alt: 'Home' },
    {
      route: '/app/documentation',
      icon: 'documentation-icon.svg',
      alt: 'Documentation',
    },
    {
      route: '/app/referral',
      icon: 'dollar-icon.svg',
      alt: 'Referral',
    },
    { comingSoon: true, icon: 'cloud-icon.svg', alt: 'Cloud' },
    {
      comingSoon: true,
      icon: 'analytics-icon.svg',
      alt: 'Analytics',
    },
  ];

  constructor(public router: Router) {}
}
