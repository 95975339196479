<div class="m-auto" [ngStyle]="{ 'max-width': '1200px' }">
  <ng-container *ngIf="gameData$ | async as gameData; else noGameData">
    <div
      class="flex align-items-center border-bottom-1 border-gray-800 py-4 mb-5 gap-3"
    >
      <img
        [src]="gameData.iconUri"
        class="w-5rem h-5rem border-round-md"
        alt="Game Icon"
      />

      <div class="flex flex-column justify-content-between gap-2">
        <span class="text-2xl text-white"> {{ gameData.title }} </span>

        <p-tag
          [value]="gameData.status | convertEnumFromBE"
          [class]="gameData.status"
        ></p-tag>
      </div>
    </div>

    <div class="flex gap-6 justify-content-between">
      <p-tabView class="vertical-tabview pb-8">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="flex gap-3 align-items-center">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1923_1265)">
                  <path
                    d="M8.804 0.492188C8.65678 0.191406 8.34567 0 8.004 0C7.66233 0 7.354 0.191406 7.204 0.492188L5.41789 4.10977L1.429 4.68945C1.09567 4.73867 0.817887 4.96836 0.71511 5.28281C0.612332 5.59727 0.695665 5.94453 0.934554 6.17695L3.829 8.99609L3.14567 12.9801C3.09011 13.3082 3.229 13.6418 3.504 13.8359C3.779 14.0301 4.14289 14.0547 4.44289 13.8988L8.00678 12.0258L11.5707 13.8988C11.8707 14.0547 12.2346 14.0328 12.5096 13.8359C12.7846 13.6391 12.9234 13.3082 12.8679 12.9801L12.1818 8.99609L15.0762 6.17695C15.3151 5.94453 15.4012 5.59727 15.2957 5.28281C15.1901 4.96836 14.9151 4.73867 14.5818 4.68945L10.5901 4.10977L8.804 0.492188Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1923_1265">
                    <rect width="16" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              Basic&nbsp;info
            </span>
          </ng-template>

          <app-admin-game-details-tab
            [gameData]="gameData"
          ></app-admin-game-details-tab>
        </p-tabPanel>

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="flex gap-3 align-items-center">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.57143 0C3.15179 0 2 1.17578 2 2.625V11.375C2 12.8242 3.15179 14 4.57143 14H12.2857H13.1429C13.617 14 14 13.609 14 13.125C14 12.641 13.617 12.25 13.1429 12.25V10.5C13.617 10.5 14 10.109 14 9.625V0.875C14 0.391016 13.617 0 13.1429 0H12.2857H4.57143ZM4.57143 10.5H11.4286V12.25H4.57143C4.09732 12.25 3.71429 11.859 3.71429 11.375C3.71429 10.891 4.09732 10.5 4.57143 10.5ZM5.42857 3.9375C5.42857 3.69688 5.62143 3.5 5.85714 3.5H11C11.2357 3.5 11.4286 3.69688 11.4286 3.9375C11.4286 4.17812 11.2357 4.375 11 4.375H5.85714C5.62143 4.375 5.42857 4.17812 5.42857 3.9375ZM5.85714 5.25H11C11.2357 5.25 11.4286 5.44688 11.4286 5.6875C11.4286 5.92812 11.2357 6.125 11 6.125H5.85714C5.62143 6.125 5.42857 5.92812 5.42857 5.6875C5.42857 5.44688 5.62143 5.25 5.85714 5.25Z"
                  fill="currentColor"
                />
              </svg>

              Legal
            </span>
          </ng-template>

          <app-admin-game-legal-tab
            [gameData]="gameData"
          ></app-admin-game-legal-tab>
        </p-tabPanel>

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="flex gap-3 align-items-center">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8.5668V8.63789C0 10.1486 1.2075 11.375 2.695 11.375C3.9325 11.375 5.01 10.5193 5.31 9.30059L5.4 8.9375H10.6L10.69 9.30059C10.99 10.5193 12.0675 11.375 13.305 11.375C14.7925 11.375 16 10.1486 16 8.63789V8.5668C16 8.40684 15.9875 8.24942 15.96 8.09199L15.1425 3.24492C14.9275 1.97031 14.12 0.878516 12.885 0.566211C11.7375 0.276758 10.095 0 8 0C5.905 0 4.2625 0.276758 3.1175 0.566211C1.88 0.878516 1.0725 1.97031 0.8575 3.24492L0.04 8.09199C0.0125 8.24942 0 8.40684 0 8.5668ZM10.8 5.07813C11.0652 5.07813 11.3196 5.18513 11.5071 5.3756C11.6946 5.56606 11.8 5.82439 11.8 6.09375C11.8 6.36311 11.6946 6.62144 11.5071 6.81191C11.3196 7.00237 11.0652 7.10938 10.8 7.10938C10.5348 7.10938 10.2804 7.00237 10.0929 6.81191C9.90536 6.62144 9.8 6.36311 9.8 6.09375C9.8 5.82439 9.90536 5.56606 10.0929 5.3756C10.2804 5.18513 10.5348 5.07813 10.8 5.07813ZM11.4 3.65625C11.4 3.38689 11.5054 3.12856 11.6929 2.93809C11.8804 2.74763 12.1348 2.64063 12.4 2.64063C12.6652 2.64063 12.9196 2.74763 13.1071 2.93809C13.2946 3.12856 13.4 3.38689 13.4 3.65625C13.4 3.92561 13.2946 4.18394 13.1071 4.37441C12.9196 4.56487 12.6652 4.67188 12.4 4.67188C12.1348 4.67188 11.8804 4.56487 11.6929 4.37441C11.5054 4.18394 11.4 3.92561 11.4 3.65625ZM5.4 3.45313V4.26563H6.2C6.5325 4.26563 6.8 4.5373 6.8 4.875C6.8 5.2127 6.5325 5.48438 6.2 5.48438H5.4V6.29688C5.4 6.63457 5.1325 6.90625 4.8 6.90625C4.4675 6.90625 4.2 6.63457 4.2 6.29688V5.48438H3.4C3.0675 5.48438 2.8 5.2127 2.8 4.875C2.8 4.5373 3.0675 4.26563 3.4 4.26563H4.2V3.45313C4.2 3.11543 4.4675 2.84375 4.8 2.84375C5.1325 2.84375 5.4 3.11543 5.4 3.45313Z"
                  fill="currentColor"
                />
              </svg>

              Game Access
            </span>
          </ng-template>

          <app-admin-game-access-tab
            [gameData]="gameData"
          ></app-admin-game-access-tab>
        </p-tabPanel>

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="flex gap-3 align-items-center">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4947 7.26781C15.9072 5.83324 15.9072 3.50999 14.4947 2.07542C13.2447 0.805893 11.2747 0.640854 9.83719 1.68441L9.79719 1.71234C9.43719 1.97386 9.35469 2.48167 9.61219 2.84476C9.86969 3.20785 10.3697 3.29417 10.7272 3.03265L10.7672 3.00472C11.5697 2.42328 12.6672 2.51468 13.3622 3.22308C14.1497 4.02289 14.1497 5.31781 13.3622 6.11761L10.5572 8.97152C9.76969 9.77132 8.49469 9.77132 7.70719 8.97152C7.00969 8.26312 6.91969 7.14847 7.49219 6.33597L7.51969 6.29535C7.77719 5.92972 7.69219 5.42191 7.33469 5.16292C6.97719 4.90394 6.47469 4.98773 6.21969 5.35082L6.19219 5.39144C5.16219 6.84886 5.32469 8.84964 6.57469 10.1192C7.98719 11.5537 10.2747 11.5537 11.6872 10.1192L14.4947 7.26781ZM1.50469 6.67367C0.0921875 8.10824 0.0921875 10.4315 1.50469 11.866C2.75469 13.1356 4.72469 13.3006 6.16219 12.2571L6.20219 12.2291C6.56219 11.9676 6.64469 11.4598 6.38719 11.0967C6.12969 10.7336 5.62969 10.6473 5.27219 10.9088L5.23219 10.9368C4.42969 11.5182 3.33219 11.4268 2.63719 10.7184C1.84969 9.91605 1.84969 8.62113 2.63719 7.82132L5.44219 4.96996C6.22969 4.17015 7.50469 4.17015 8.29219 4.96996C8.98969 5.67835 9.07969 6.793 8.50719 7.60804L8.47969 7.64867C8.22219 8.01429 8.30719 8.5221 8.66469 8.78109C9.02219 9.04007 9.52469 8.95628 9.77969 8.5932L9.80719 8.55257C10.8372 7.09261 10.6747 5.09183 9.42469 3.8223C8.01219 2.38773 5.72469 2.38773 4.31219 3.8223L1.50469 6.67367Z"
                  fill="currentColor"
                />
              </svg>

              Links
            </span>
          </ng-template>

          <app-admin-game-links-tab
            [gameData]="gameData"
          ></app-admin-game-links-tab>
        </p-tabPanel>
      </p-tabView>

      <!-- TODO: move to a separate component -->
      <div class="flex flex-column gap-3 w-max">
        @if (gameData.status === GAME_STATUS.REVIEW) {
        <p-button
          class="p-fluid"
          label="Approve"
          [outlined]="true"
          severity="success"
          (onClick)="onApproveGame()"
        ></p-button>
        <p-button
          class="p-fluid w-max"
          label="Request Revisions"
          [outlined]="true"
          severity="warning"
          (onClick)="onOpenReviewRequiredModal()"
        ></p-button>
        <p-button
          class="p-fluid"
          label="Reject"
          [outlined]="true"
          severity="danger"
          (onClick)="onOpenRejectModal()"
        ></p-button>
        } @else if (gameData.status === GAME_STATUS.APPROVED) {
        <p-button
          class="p-fluid"
          label="Publish"
          [outlined]="true"
          severity="info"
          (onClick)="onPublishGame()"
        ></p-button>
        <p-button
          class="p-fluid w-max"
          label="Reset Status"
          [outlined]="true"
          severity="warning"
          (onClick)="onResetGameStatus()"
        ></p-button>
        } @if (gameData.status === GAME_STATUS.APPROVED || gameData.status ===
        GAME_STATUS.REJECTED || gameData.status === GAME_STATUS.REVIEW) {
        <p-button
          class="p-fluid"
          label="Delete"
          [outlined]="true"
          severity="danger"
          (onClick)="onDeleteGame()"
        ></p-button>
        } @if (gameData.status === GAME_STATUS.PUBLISHED) {
        <p-button
          class="p-fluid"
          label="Unpublish"
          [outlined]="true"
          severity="danger"
          (onClick)="onUnpublishGame()"
        ></p-button>
        }
      </div>
    </div>
  </ng-container>

  <ng-template #noGameData>
    <h2
      class="text-3xl text-white text-center pt-8"
      *ngIf="!loaderService.isLoading"
    >
      No game with such id
    </h2>
  </ng-template>
</div>
