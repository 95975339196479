<div class="m-auto pb-8" [ngStyle]="{ 'max-width': '1200px' }">
  <h2 class="text-md mt-4 mb-5">Gaimin Games Developer Tool</h2>

  <div [formGroup]="gameFilterForm">
    <div class="mb-2">
      <p-autoComplete
        placeholder="Search"
        [suggestions]="suggestions"
        (completeMethod)="onSearch($event)"
        class="p-fluid"
        formControlName="title"
        [completeOnFocus]="true"
      />
    </div>
    <div class="flex gap-2">
      <p-multiSelect
        placeholder="Genres"
        optionLabel="value"
        [options]="genres"
        [showHeader]="true"
        class="capitalize"
        formControlName="genres"
      />
      <p-multiSelect
        placeholder="Chains"
        optionLabel="value"
        [options]="chains"
        [showHeader]="true"
        class="capitalize"
        formControlName="chains"
      />
      <p-multiSelect
        placeholder="Status"
        optionLabel="value"
        [options]="status"
        [showHeader]="true"
        class="capitalize"
        formControlName="status"
      />
    </div>
  </div>

  @if(filteredGames.length) {

  <ul class="flex flex-column gap-3 p-0">
    <li class="flex align-items-center gap-4 px-3 py-3">
      <span [ngStyle]="{ 'flex-basis': '42%' }">Name</span>
      <span [ngStyle]="{ 'flex-basis': '25%' }">Genres</span>
      <span [ngStyle]="{ 'flex-basis': '10%' }">Chains</span>
      <span
        [ngStyle]="{ flex: '1', 'max-width': 'fit-content' }"
        class="ml-auto"
      >
        Status
      </span>
    </li>

    @for (game of paginatedGames; track $index) {
    <li
      class="flex align-items-center gap-4 bg-gray-900 border-round-lg px-3 py-3 hover-scale-05 transition-duration-200 cursor-pointer"
      (click)="onGameClick(game.id)"
    >
      <img
        [src]="game.info?.bannerImageUri"
        [alt]="game.title + ' Banner Image'"
        [ngStyle]="{
          'object-fit': 'cover',
          'object-position': 'top',
          'aspect-ratio': '16 / 9',
          width: '10%',
          'flex-basis': '10%'
        }"
      />
      <h3 class="m-0" [ngStyle]="{ 'flex-basis': '30%' }">
        {{ game.title }}
      </h3>
      <div class="flex gap-2" [ngStyle]="{ 'flex-basis': '25%' }">
        @for (genre of game.info?.genres?.slice(0 ,3); track $index) {
        <span class="bg-gray-700 p-2 border-round-md">
          {{ genre | convertEnumFromBE }}
        </span>
        }
      </div>
      <div class="flex gap-2" [ngStyle]="{ 'flex-basis': '10%' }">
        @for (chain of game.info?.chains?.slice(0, 2); track $index) {
        <span class="bg-gray-700 p-2 border-round-md">
          {{ chain | convertEnumFromBE }}
        </span>
        }
      </div>
      <p-tag
        [value]="game.status | convertEnumFromBE"
        [class]="game.status"
        [ngStyle]="{
          flex: '1',
          'max-width': 'fit-content',
        }"
        class="ml-auto"
      ></p-tag>
    </li>
    }
  </ul>

  <p-paginator
    [rows]="rows"
    [totalRecords]="totalRecords"
    (onPageChange)="onPaginate($event)"
    [rowsPerPageOptions]="[10, 20, 50]"
    [showFirstLastIcon]="true"
    styleClass="pagination"
  ></p-paginator>
  } @else {
  <p class="mt-3 text-2xl">No results found.</p>
  }
</div>
