import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { NOTIFICATION } from './tools/constants/notification.constants';
import { AuthService } from './shared/services/auth/auth.service';
import { RegexpService } from './shared/services/regexp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterModule, ToastModule],
})
export class AppComponent {
  public readonly NOTIFICATION: typeof NOTIFICATION = NOTIFICATION;

  constructor(
    private authService: AuthService,
    private regexpService: RegexpService
  ) {
    authService.initialize();
  }
}
