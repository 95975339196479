import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenString',
  standalone: true,
})
export class ShortenStringPipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    return value.length > maxLength
      ? value.slice(0, maxLength - 1) + '…'
      : value;
  }
}
