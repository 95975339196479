import { Component } from '@angular/core';
import { NgStyle } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../../../shared/services/auth/auth.service';

import { AUTH_ACTION } from '../../../tools/constants/auth.constants';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  standalone: true,
  imports: [NgStyle, ButtonModule, RouterModule],
})
export class SignUpComponent {
  public readonly SIGN_UP: AUTH_ACTION = AUTH_ACTION.SIGN_UP;

  constructor(public authService: AuthService) {}
}
