<div
  class="flex justify-content-center align-items-center flex-column h-full relative"
>
  <img
    src="./assets/illustrations/new-game.png"
    class="w-screen"
    [ngStyle]="{
      'max-width': '60rem',
      transform: 'scale(1.5)'
    }"
    alt=""
  />
  <h2
    class="text-4xl line-height-3 text-center mb-2 z-1"
    [ngStyle]="{ 'max-width': '25rem' }"
  >
    Publish your game to reach 500k GAIMIN users
  </h2>
  <p-button label="Add game" (onClick)="addFirstGame()"></p-button>

  <img
    src="./assets/illustrations/background-lights.png"
    class="absolute bottom-0 left-0 w-full"
    [ngStyle]="{ 'z-index': '-1' }"
    alt=""
  />
</div>

<app-body-background-setter></app-body-background-setter>
