export enum GAME_TYPE {
  DOWNLOADABLE = 'DOWNLOADABLE',
  WEB = 'WEB',
}

export enum GAME_STAGE {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  RELEASE = 'RELEASE',
  READY_FOR_RELEASE = 'READY_FOR_RELEASE',
}

export enum GAME_STATUS {
  DRAFT = 'Draft',
  REVIEW = 'Review',
  REJECTED = 'Rejected',
  READY_FOR_PUBLISH = 'Ready for Publish',
  PUBLISHING = 'Publishing',
  PUBLISHED = 'Published',
  CHANGES_REQUESTED = 'Changes Requested',
  UNPUBLISH_REQUESTED = 'Unpublish Requested',
  PUBLISH_REQUESTED = 'Publish Requested',
  PENDING_REVIEW = 'Pending Review'
}

export enum GAME_GENRES {
  ACTION = 'Action',
  ADVENTURE = 'Adventure',
  RPG = 'RPG',
  STRATEGY = 'Strategy',
  SPORTS = 'Sports',
  MMORPG = 'MMORPG',
  RACING = 'Racing',
  FIGHTING = 'Fighting',
  SHOOTER = 'Shooter',
  HORROR = 'Horror',
  WEB3 = 'Web3',
  SURVIVAL = 'Survival',
  BATTLE_ROYALE = 'Battle Royal',
  MOBA = 'MOBA',
  TOWER_DEFENSE = 'Tower Defense',
  CARDS = 'Cards',
  PLAY_TO_EARN = 'Play to Earn',
  FANTASY = 'Fantasy',
  OPEN_WORLD = 'Open World',
  TACTICAL = 'Tactical',
  MULTIPLAYER = 'Multiplayer',
  SCI_FI = 'Sci-Fi',
  PVP = 'PvP',
  SIMULATION = 'Simulation',
  PUZZLE = 'Puzzle',
  METAVERSE = 'Metaverse',
  CASUAL = 'Casual',
  IDLE = 'Idle',
  ESPORTS = 'Esports',
  ARCADE = 'Arcade',
  AUTO_BATTLER = 'Auto Battler',
  NFT = 'NFT',
  HARDCORE = 'Hardcore',
}

export enum GAME_CHAINS {
  ETHEREUM = 'Ethereum',
  BSC = 'BSC',
  SOLANA = 'Solana',
  POLYGON = 'Polygon',
  HEDERA = 'Hedera',
}

export enum RELEASE_STAGE {
  TBD = 'TBD',
  BETA = 'Beta',
  ALPHA = 'Alpha',
  EARLY_ACCESS = 'Early Access',
  RELEASED = 'Released',
}
