import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GamesService } from '../../../shared/services/games/games.service';
import { Router } from '@angular/router';
import { NgClass, NgStyle } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-home-banners',
  standalone: true,
  imports: [NgStyle, NgClass, TooltipModule],
  templateUrl: './home-banners.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeBannersComponent {
  @Input() hasAvailableGames!: boolean;

  readonly homeBanners = [
    {
      image: './assets/illustrations/home/home-banner-1.png',
      smallImage: './assets/illustrations/home/home-banner-small-1.png',
      title: 'Add game',
      link: 'Submit form to publish game',
      route: this.gamesService.gamesList.length
        ? '/app/new-game'
        : '/app/add-first-game',
    },
    {
      image: './assets/illustrations/home/home-banner-2.png',
      smallImage: './assets/illustrations/home/home-banner-small-2.png',
      title: 'Referral system',
      link: 'Invite friends',
      route: '/app/referral',
    },
    {
      image: './assets/illustrations/home/home-banner-3.png',
      smallImage: './assets/illustrations/home/home-banner-small-3.png',
      title: 'Public API',
      link: 'Full list of our public API’s',
      route: '/app/documentation/api',
    },
    {
      image: './assets/illustrations/home/home-banner-4.png',
      smallImage: './assets/illustrations/home/home-banner-small-4.png',
      title: 'File Sharing',
      link: 'Access shared files instantly',
      route: '/app/file-sharing',
      isComingSoon: true,
    },
  ];

  constructor(public router: Router, public gamesService: GamesService) {}

  onRedirect(link: string | null): void {
    if (!link) return;

    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
    }
  }
}
