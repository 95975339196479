import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReferalService } from '../../services/referal.service';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-referal-dialog',
  standalone: true,
  imports: [ButtonModule, NgStyle],
  templateUrl: './referal-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferalDialogComponent {
  constructor(
    private config: DynamicDialogConfig,
    private referalService: ReferalService,
    private ref: DynamicDialogRef
  ) {
    this.config.header = 'GAIMIN Platform is not installed';
    this.config.contentStyle = {
      width: '600px',
    };
  }

  connectToReferal() {
    this.ref.close();
    this.referalService.connectToReferal();
  }
}
