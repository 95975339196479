<div class="flex relative h-screen w-screen p-3">
  <div class="flex flex-column flex-1 justify-content-center">
    <router-outlet></router-outlet>
  </div>

  <div class="flex-1 h-full">
    <div
      class="bg-gray-1000 text-center border-round-xl flex flex-column h-full"
    >
      <img
        src="./assets/illustrations/auth-sidebar.png"
        alt=""
        class="w-full mt-auto"
      />
      <h2 class="text-2xl font-medium mb-2">
        Game publishing service for the GAIMIN platform
      </h2>
      <p class="text-md font-normal">
        An easy and fast way to upload and update games.
      </p>

      <div
        class="flex justify-content-between mt-auto gap-2 px-6 pt-0 pb-6 text-sm"
      >
        <div class="text-left max-w-260px">
          <h3 class="font-medium text-gray-200 mb-2">Quick review</h3>
          <p class="font-normal text-left text-gray-400 line-height-3">
            Detailed feedback from the GAIMIN team regarding the game review
          </p>
        </div>
        <div class="text-left max-w-260px">
          <h3 class="font-medium text-gray-200 mb-2">Support 24/7</h3>
          <p class="font-normal text-left text-gray-400 line-height-3">
            We are always ready to help solve problems faced by users
          </p>
        </div>
        <div class="text-left max-w-260px">
          <h3 class="font-medium text-gray-200 mb-2">All game analytics</h3>
          <p class="font-normal text-left text-gray-400 line-height-3">
            Monitor user reactions using detailed statistics to improve the
            product.
          </p>
        </div>
      </div>
    </div>
  </div>

  <p-button
    class="absolute"
    [ngStyle]="{ bottom: '1%' }"
    severity="secondary"
    icon="pi pi-question"
    rounded="true"
    (onClick)="onOpenSupportDialog()"
  ></p-button>
</div>

<app-loader></app-loader>
