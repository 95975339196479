import { Component, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from '../../modals/support-dialog/support-dialog.component';
import { AuthService } from '../../services/auth/auth.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TooltipOptions } from 'primeng/api';
import { AsyncPipe, NgStyle } from '@angular/common';
import { BreakpointService } from '../../services/breakpoint.service';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgStyle,
    AsyncPipe,
    OverlayPanelModule,
    RouterModule,
    TooltipModule,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @ViewChild('op') overlayPanel!: OverlayPanel;

  tooltipOptions: TooltipOptions = {
    tooltipPosition: 'bottom',
    positionTop: 10,
  };

  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    public router: Router,
    public breakpointService: BreakpointService
  ) {}

  onSignOut(): void {
    this.authService.signOut();
  }

  onOpenSettings(): void {
    this.router.navigateByUrl('/app/account-settings');
    this.overlayPanel.hide();
  }

  openSupportDialog(): void {
    this.dialogService.open(SupportDialogComponent, {});
  }
}
