import { Component } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { NgStyle } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DialogService } from 'primeng/dynamicdialog';
import { ChangePasswordDialogComponent } from '../../shared/modals/change-password-dialog/change-password-dialog.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DeleteAccountDialogComponent } from '../../shared/modals/delete-account-dialog/delete-account-dialog.component';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../tools/constants/notification.constants';
import { LoaderService } from '../../shared/services/loader.service';
import { AuthRequestsService } from '../../shared/services/auth/auth-requests.service';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TabViewModule,
    PanelModule,
    InputTextModule,
    AvatarModule,
    AvatarGroupModule,
  ],
  templateUrl: './account-settings.component.html',
})
export class AccountSettingsComponent {
  accountInfoForm: FormGroup;

  constructor(
    private authService: AuthService,
    private authRequestsService: AuthRequestsService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private http: HttpClient,
    private messageService: MessageService,
    public loaderService: LoaderService
  ) {
    this.accountInfoForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      username: [null, [Validators.required]],
      displayName: [null, [Validators.required]],
      registeredAddress: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCurrentUserInfo();
    this.disableFields();
  }

  disableFields(): void {
    this.accountInfoForm.get('email')?.disable();
    this.accountInfoForm.get('displayName')?.disable();
    this.accountInfoForm.get('registeredAddress')?.disable();
  }

  enableFields(): void {
    this.accountInfoForm.get('email')?.enable();
    this.accountInfoForm.get('displayName')?.enable();
    this.accountInfoForm.get('registeredAddress')?.enable();
  }

  onUpdateUserInfo(): void {
    if (this.accountInfoForm.invalid) return;
    this.enableFields();

    const updatedUserInfo: {
      email: string;
      username: string;
      displayName: string;
      registeredAddress: string;
    } = this.accountInfoForm.value;
    this.http
      .patch(`${environment.devportalApi}/api/user/me`, updatedUserInfo)
      .subscribe({
        next: () => {
          this.disableFields();

          this.messageService.add({
            severity: NOTIFICATION.SUCCESS,
            summary: 'Success',
            detail: 'User data has been successfully updated',
          });
        },
        error: () => {
          this.disableFields();
          this.getCurrentUserInfo();
        },
      });
  }

  onResetPassword(): void {
    window.location.href = `${environment.gaiminAuth}/profile`;
  }

  onDeleteAccount(): void {
    this.dialogService.open(DeleteAccountDialogComponent, {});
  }

  private getCurrentUserInfo() {
    return this.authRequestsService
      .getUserInfoRequest()
      .subscribe((userInfo) => {
        const { displayName, registeredAddress, gaiminAuthUser } = userInfo;
        const { email, username } = gaiminAuthUser;

        this.accountInfoForm.patchValue({
          displayName,
          registeredAddress,
          email,
          username,
        });
      });
  }
}
