@if (loaderService.isLoading) {
<div
  [ngStyle]="{
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    'background-color': 'rgba(0, 0, 0, 0.7)'
  }"
  class="flex justify-content-center align-items-center flex-column fixed"
  [@fade]="'show'"
>
  <p-progressSpinner ariaLabel="loading" class="mb-5" />

  @if ( loaderService.percentage != null ) {
  <div
    class="bg-gray-200 border-round w-16rem h-1rem relative overflow-hidden mb-3"
  >
    <div
      class="h-full absolute left-0 transition-all transition-duration-150"
      [ngStyle]="{
        background: 'linear-gradient(90deg, #1A2980 0%, #26D0CE 100%)',
        width: loaderService.percentage + '%'
      }"
    ></div>
  </div>
  } @if (loaderService.message) {
  <p>{{ loaderService.message }}</p>
  }
</div>
}
