<div>
  <p class="text-white text-xl line-height-3 my-5">
    Reject this game permanently?
  </p>

  <div class="flex gap-2 justify-content-end">
    <p-button
      severity="secondary"
      label="Cancel"
      (onClick)="onCancel()"
    ></p-button>
    <p-button label="Reject game" (onClick)="onGameReject()"></p-button>
  </div>
</div>
