import { NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderService } from '../../services/loader.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [NgStyle, ProgressSpinnerModule],
  templateUrl: './loader.component.html',
  animations: [
    trigger('fade', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      transition('void <=> show', [animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
