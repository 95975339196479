<div class="pt-4">
  <img
    class="mb-4 w-full"
    src="../../../../assets/illustrations/request-unpublish.png"
    alt=""
    height="221"
  />

  <h2 class="mb-3 font-medium text-2xl text-white text-center">
    Unpublish request
  </h2>

  <p
    class="text-center text-lg mb-4"
    [ngStyle]="{ color: '#8f95a8', 'line-height': '157%' }"
  >
    Our admins will be notified to unpublish your game. When unpublished your
    game will no longer be displayed on our platform, however you may request to
    republish at any time.
  </p>

  <div
    class="pt-4 flex justify-content-between"
    [ngStyle]="{ 'border-top': '1px solid #333b4d' }"
  >
    <p-button
      autofocus="false"
      severity="secondary"
      label="Cancel"
      class="w-full"
      (onClick)="onCancelClick()"
    ></p-button>

    <p-button
      autofocus="false"
      label="Confirm"
      (onClick)="requestUnpublish()"
    ></p-button>
  </div>
</div>
