<h3 class="font-medium text-4xl text-white mb-3">Links</h3>
<p class="font-normal text-md text-gray-500 mb-4">
  Social networks for fans of the game
</p>

<ul class="p-0 flex flex-column gap-4">
  @if (gameData.links?.websiteUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
                height: '2rem',
                width: '2rem',
              }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/web.svg"
        alt="website"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.websiteUrl)"
    >
      {{ gameData.links?.websiteUrl }}
    </a>
  </li>
  } @if (gameData.links?.youtubeUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
                height: '2rem',
                width: '2rem',
              }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/youtube.svg"
        alt="youtube"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.youtubeUrl)"
    >
      {{ gameData.links?.youtubeUrl }}
    </a>
  </li>
  } @if (gameData.links?.twitterUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
                height: '2rem',
                width: '2rem',
              }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/twitter.svg"
        alt="twitter"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.twitterUrl)"
    >
      {{ gameData.links?.twitterUrl }}
    </a>
  </li>
  } @if (gameData.links?.discordUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
        height: '2rem',
        width: '2rem'
      }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/discord.svg"
        alt="discord"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.discordUrl)"
    >
      {{ gameData.links?.discordUrl }}
    </a>
  </li>
  } @if (gameData.links?.instagramUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
        height: '2rem',
        width: '2rem'
      }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/instagram.svg"
        alt="instagram"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.instagramUrl)"
    >
      {{ gameData.links?.instagramUrl }}
    </a>
  </li>
  } @if (gameData.links?.telegramUrl) {
  <li class="flex align-items-center gap-3">
    <div
      class="flex align-items-center justify-center border-1 border-round-lg"
      [ngStyle]="{
        height: '2rem',
        width: '2rem'
      }"
    >
      <img
        width="30"
        height="30"
        class="p-2"
        src="../../../../assets/icons/social/telegram.svg"
        alt="telegram"
      />
    </div>

    <a
      class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
      (click)="adminService.onRedirect(gameData.links?.telegramUrl)"
    >
      {{ gameData.links?.telegramUrl }}
    </a>
  </li>
  }
</ul>

<dl class="flex flex-column gap-6 my-8">
  <div>
    <dt class="font-normal text-lg text-gray-400 line-height-3">
      Support website URL
    </dt>
    <dd>
      <a
        class="font-normal underline cursor-pointer text-md text-white line-height-3 m-0 transition-colors transition-duration-200 hover:text-gray-300"
        (click)="adminService.onRedirect(gameData.links?.supportWebsiteUrl)"
      >
        {{ gameData.links?.supportWebsiteUrl }}
      </a>
    </dd>
  </div>

  <div>
    <dt class="font-normal text-lg text-gray-400 line-height-3">
      Support email
    </dt>
    <dd>
      @if (gameData.links?.supportEmail) {
      <a
        class="font-normal text-lg text-white line-height-3 transition-colors transition-duration-200 hover:text-gray-300"
        [href]="'mailto:' + gameData.links?.supportEmail"
        target="_blank"
      >
        {{ gameData.links?.supportEmail }}
      </a>
      } @else { No Support email provided }
    </dd>
  </div>
</dl>
