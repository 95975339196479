@if (invoices.length) {
<div
  [ngStyle]="{ 'max-width': '50rem' }"
  class="border-round-lg border-gray-800 overflow-hidden border-1"
>
  <p-table [value]="invoices">
    <ng-template pTemplate="caption">
      <div class="flex items-center justify-between">
        <span class="text-xl font-bold">Invoices</span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Date</th>
        <th>Description</th>
        <th>Download</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice>
      <tr>
        <td>{{ invoice.created | date : 'dd.MM.yy' }}</td>
        <td>
          {{ invoice.subscriptionName }} subscription -
          {{ invoice.expiresAt | date : 'MMMM' }}
        </td>
        <td>
          <a class="text-primary" [href]="invoice.pdfLink" target="_blank"
            >Invoice</a
          >
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
} @else {
<h2 class="mt-4 text-4xl text-white font-bold text-center">No invoices yet.</h2>
}
