import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PASSWORD, TEXT } from '../../../tools/constants/pattern.constants';
import { AuthService } from '../../services/auth/auth.service';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';
import { AuthRequestsService } from '../../services/auth/auth-requests.service';

@Component({
  selector: 'app-account-migration-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    InputTextModule,
    PasswordModule,
  ],
  templateUrl: './account-migration-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMigrationDialogComponent {
  migrationForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private authService: AuthService,
    private authServiceRequests: AuthRequestsService,
    private messageService: MessageService
  ) {
    this.config.header = 'Finish Migration';
    this.config.contentStyle = {
      width: '100vh',
      'max-width': '448px',
      'background-color': '#18181E',
    };
  }

  get accessToken(): string {
    return this.config.data.accessToken;
  }

  get refreshToken(): string {
    return this.config.data.refreshToken;
  }

  onSubmit(): void {
    const { email, password } = this.migrationForm.value;

    this.authServiceRequests
      .migrateAccountRequest(email, password, this.accessToken)
      .subscribe({
        next: () => {
          this.ref.close();

          this.authService.authorizeUser(this.accessToken, this.refreshToken);

          this.messageService.add({
            severity: NOTIFICATION.SUCCESS,
            detail: 'Account data was migrated successfully!',
          });
        },
      });
  }
}
