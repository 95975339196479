import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ReviewFeedbackDialogComponent } from '../../shared/modals/review-feedback-dialog/review-feedback-dialog.component';
import { TagModule } from 'primeng/tag';
import { GAME_STATUS } from '../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../shared/pipes/convert-enum-from-be.pipe';
import { GamesService } from '../../shared/services/games.service';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-review-feedback',
  standalone: true,
  imports: [
    NgStyle,
    BodyBackgroundSetterComponent,
    ButtonModule,
    TagModule,
    ConvertEnumFromBEPipe,
  ],
  templateUrl: './review-feedback.component.html',
})
export class ReviewFeedbackComponent implements OnInit {
  GAME_STATUS = GAME_STATUS;
  id = 0;
  feedback = '';

  constructor(
    private dialogService: DialogService,
    private gameService: GamesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('game-id') || 0;
      const isUserGame = this.gameService.gamesList
        .map((game) => game.id)
        .includes(+id);

      const isRevisionsRequired =
        this.gameService.getGameById(+id)?.status ===
        GAME_STATUS.REVISIONS_REQUIRED;

      if (id) {
        this.id = +id;
      }

      if (!isUserGame || !isRevisionsRequired) {
        this.router.navigateByUrl('/app/home');
      }

      this.gameService.getReviewFeedback(this.id).subscribe((res) => {
        this.feedback = res.review;
      });
    });
  }

  viewFeedback(): void {
    this.dialogService.open(ReviewFeedbackDialogComponent, {
      data: {
        id: this.id,
        feedback: this.feedback,
      },
    });
  }
}
