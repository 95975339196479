import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  Invoice,
  OrderSummary,
  PrepaidBundle,
  SubscriptionType,
} from '../../tools/interfaces/response.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestsService {
  constructor(private http: HttpClient) {}

  getUserInvoicesRequest() {
    return this.http.get<{ invoices: Invoice[] }>(
      `${environment.devportalApi}/api/invoices`
    );
  }

  getPrepaidBundles() {
    return this.http.get<PrepaidBundle[]>(
      `${environment.devportalApi}/api/subscription/types`
    );
  }

  getActiveSubscription() {
    return this.http.get<SubscriptionType>(
      `${environment.devportalApi}/api/subscription/user?projectType=FILE_SHARING`
    );
  }

  createOrderSummaryRequest(orderSummary: OrderSummary) {
    return this.http.post<SubscriptionType>(
      `${environment.devportalApi}/api/payment/terms-and-conditions`,
      orderSummary
    );
  }

  getPurchaseLinkRequest(subscriptionTypeId: number) {
    return this.http
      .post<{ url: string }>(`${environment.devportalApi}/api/payment`, {
        subscriptionTypeId,
      });
  }
}
