<div class="relative overflow-hidden">
  <img [src]="game.pageData.background" class="game-background" alt="" />
  <div class="game">
    <h2 class="back-link" (click)="onClose()">
      <span class="pi pi-chevron-left mr-2"></span>
      Back
    </h2>

    <div class="game-wrapper">
      <div class="game__header">
        <h1 class="game__header-title">
          {{ game.name }}
        </h1>
        <p class="game__header-description">{{ game.pageData.description }}</p>
        <div class="game__header-genres">
          <span *ngIf="game.isInBeta" class="tag-beta">Beta</span>
          <span *ngFor="let genre of game.genres">
            {{ genre }}
          </span>
        </div>
        <ng-content select="app-game-control-panel"></ng-content>
        <ng-content select="app-button"></ng-content>

        <button class="game__header__play-btn">Play</button>
      </div>

      <div class="game__description">
        <div class="game__description-screenshots custom-scroll">
          <img
            *ngFor="let screenshot of game.pageData.screenshots; let i = index"
            [src]="screenshot"
            alt=""
          />
        </div>

        <hr class="separator" />

        <div class="game__description-details">
          <div *ngFor="let detail of game.pageData.details">
            <h3>{{ detail.title }}</h3>
            <span class="link" #developerLink>{{ detail.content }}</span>
          </div>
          <ng-container *ngIf="game.pageData.version">
            <div>
              <h3>Version</h3>
              <span>{{ game.pageData.version }}</span>
            </div>
          </ng-container>
        </div>

        <hr class="separator" />

        <div class="game__description-about">
          <h3 class="game__description-about-title">About {{ game.name }}</h3>
          <hr class="separator__gradient" />
          <div
            class="game__description-about-content"
            [innerHTML]="game.pageData.detailedDescription"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
