<h3 class="font-medium text-4xl text-white mb-3">Basic Info</h3>
<p class="font-normal text-md text-gray-500 mb-6">
  Will be displayed in game page in Gaimin Launcher
</p>

<dl
  class="flex flex-column gap-5 mb-6"
  [ngStyle]="{ maxWidth: '80vh', overflow: 'hidden' }"
>
  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      Game title
    </dt>
    <dd class="font-normal text-md text-white line-height-3 m-0">
      {{ gameData.title }}
    </dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      Short description
    </dt>
    <dd class="font-normal text-md text-white line-height-3 m-0">
      @if (gameData.info?.shortDescription) {
      {{ gameData.info?.shortDescription }}
      } @else { - }
    </dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">
      Full description
    </dt>
    <dd
      class="font-normal text-md text-white line-height-3 m-0"
      [innerHTML]="gameData.info?.fullDescription ?? '-'"
    ></dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">Genres</dt>
    <dd class="font-normal text-md text-white line-height-3 m-0">
      <div class="flex align-items-center gap-2">
        @if(gameData.info?.genres?.length) { @for (genre of
        gameData.info?.genres; track $index) {
        <p class="p-2 bg-gray-700 border-round-md line-height-1 capitalize">
          {{ genre | convertEnumFromBE }}
        </p>
        } } @else {
        <p>-</p>
        }
      </div>
    </dd>
  </div>

  <div>
    <dt class="font-normal text-md text-gray-200 line-height-3 mb-1">Chains</dt>
    <dd class="font-normal text-md text-white m-0">
      <div class="flex align-items-center gap-2">
        @if(gameData.info?.chains?.length) { @for (chain of
        gameData.info?.chains; track $index) {
        <p class="p-2 bg-gray-700 border-round-md capitalize">
          {{ chain | convertEnumFromBE }}
        </p>
        } } @else {
        <p>-</p>
        }
      </div>
    </dd>
  </div>
</dl>

<h3 class="font-medium text-4xl text-white mb-3">Media</h3>

<div class="flex gap-3 mb-4">
  <div>
    <h4 class="font-normal text-md text-gray-500">Game banner</h4>

    @if (gameData.info?.bannerImageUri) {
    <p-image
      [src]="gameData.info?.bannerImageUri"
      alt="Icon"
      [imageStyle]="{
        width: '224px',
        objectFit: 'contain',
        objectPosition: 'center'
      }"
      [preview]="true"
    />
    } @else {
    <p>No game icon provided</p>
    }
  </div>

  <div>
    <h4 class="font-normal text-md text-gray-500">Background</h4>

    @if (gameData.info?.backgroundImageUri) {
    <p-image
      [src]="gameData.info?.backgroundImageUri"
      alt="Icon"
      [imageStyle]="{
        height: '224px',
        width: '396px',
        objectFit: 'cover',
        objectPosition: 'center'
      }"
      [preview]="true"
    />
    } @else {
    <p>No game background provided</p>
    }
  </div>
</div>

<h4 class="font-normal text-md text-gray-500 mb-3">Images</h4>

<div class="flex gap-3 flex-wrap">
  @if (gameData.info?.imageUris) { @for (img of gameData.info?.imageUris; track
  $index) {
  <p-image
    [src]="img"
    alt="Icon"
    [imageStyle]="{
      height: '84px',
      width: 'auto',
      objectFit: 'contain',
      objectPosition: 'center'
    }"
    [preview]="true"
  />
  } } @else {
  <p>No images provided</p>
  }
</div>
