import { Component } from '@angular/core';
import { GamesService } from '../../shared/services/games.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { GameData } from '../../tools/interfaces/response.interfaces';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-game-added',
  templateUrl: './game-added.component.html',
  standalone: true,
  imports: [NgStyle, ButtonModule, BodyBackgroundSetterComponent],
})
export class GameAddedComponent {
  addedGame?: GameData;

  constructor(
    private gamesService: GamesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const gameId: number = Number(params.get('game-id'));
      const addedGame: GameData | null = this.gamesService.getGameById(gameId);
      if (!addedGame) return;
      this.addedGame = addedGame;
    });
  }

  redirectHome(): void {
    this.router.navigateByUrl('/app/home');
  }

  redirectReview(): void {
    if (this.addedGame?.id) {
      this.router.navigateByUrl(`/app/review/${this.addedGame.id}`);
    }
  }
}
