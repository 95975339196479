<div
  class="flex border-round-xl relative mb-8 align-items-center justify-content-between"
  [ngStyle]="{
    'border': '1px solid rgba(255, 255, 255, 0.13)',
    'padding': '40px 110px 40px 60px',
    'backdrop-filter': 'blur(10px)',
    'background':
      'linear-gradient(221deg, rgba(52, 78, 86, 0.24) 0%, rgba(29, 30, 37, 0.24) 100%)',
  }"
>
  <form
    [formGroup]="calculatorForm"
    (ngSubmit)="onSubmit()"
    [ngStyle]="{
      width: '40%'
    }"
  >
    <h2 class="font-bold text-4xl">
      How much can you save with GAIMIN File Sharing?
    </h2>

    <div class="mb-3 mt-4 relative">
      <label for="capacity" class="block mb-3 text-md text-gray-200">
        How much bandwidth do you use to distribute your file?
      </label>
      <div class="relative mb-5">
        <input
          id="capacity"
          formControlName="capacity"
          type="number"
          placeholder="Enter capacity"
          pInputText
          class="w-full"
        />

        <p
          class="absolute"
          [ngStyle]="{
            color: '#8f95a8',
            top: '50%',
            right: '20px',
            transform: 'translateY(-50%)',
            'border-left': '1px solid #343638',
            'padding-left': '10px'
          }"
        >
          GB
        </p>
      </div>
    </div>

    <p-button label="Calculate" class="p-fluid" type="submit" />
  </form>

  <div
    class="flex justify-content-center align-items-center"
    [ngStyle]="{
      width: '50%'
    }"
  >
    <div class="border-round-xl bg-primary py-4 px-5 flex-grow-1">
      <div
        class="flex pb-4"
        [ngStyle]="{
          'border-bottom': '1px solid rgba(255, 255, 255, 0.2)'
        }"
      >
        <img src="../../../../assets/icons/logo.png" alt="" />
      </div>

      <div class="flex flex-column gap-3 pt-3">
        <div class="flex flex-column">
          <h3 class="font-normal text-base text-white opacity-60">
            Price per month
          </h3>

          @if (gaiminPrice) {
          <p class="font-bold text-2xl text-white">
            {{ gaiminPrice | currency:'USD':'symbol':'1.2-2' }}
          </p>

          } @else {
          <p class="font-bold text-2xl text-white">-</p>
          }
        </div>

        <div class="flex flex-column">
          <h3 class="font-normal text-base text-white opacity-60">Savings</h3>

          @if (savingsPercentage) {
          <p class="font-bold text-2xl text-white">{{ savingsPercentage }}%</p>
          } @else {
          <p class="font-bold text-2xl text-white">-</p>
          }
        </div>
      </div>
    </div>

    <div
      class="py-4 px-5 flex-grow-1"
      [ngStyle]="{
      'background-color': '#24242d',
      'border-top-right-radius': '8px 8px',
      'border-bottom-right-radius': '8px 8px',
    }"
    >
      <div
        class="flex pb-4"
        [ngStyle]="{
          'border-bottom': '1px solid rgba(255, 255, 255, 0.2)'
        }"
      >

      <h2 class="font-medium text-xl">AWS</h2>

      </div>

      <div class="flex flex-column gap-3 pt-3">
        <div class="flex flex-column">
          <h3 class="font-normal text-base text-white opacity-60">
            Price per month
          </h3>

          @if (awsPrice) {
          <p class="font-bold text-2xl text-white">{{ awsPrice | currency:'USD':'symbol':'1.2-2' }}</p>
          } @else {
          <p class="font-bold text-2xl text-white">-</p>
          }
        </div>
      </div>
    </div>
  </div>
</div>
