import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';

import { HomeGamesListComponent } from './home-games-list/home-games-list.component';
import { HomeBannersComponent } from './home-banners/home-banners.component';

import { GamesService } from '../../shared/services/games/games.service';
import { Observable } from 'rxjs';
import {
  GameData,
  GameDataResponse,
} from '../../tools/interfaces/response.interfaces';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [
    RouterModule,
    NgStyle,
    NgIf,
    AsyncPipe,
    HomeGamesListComponent,
    HomeBannersComponent,
  ],
})
export class HomeComponent {
  games: GameData[] = [];
  duplicateGames: GameData[] = [];

  constructor(public gamesService: GamesService) {}

  ngOnInit(): void {
    this.initializeGamesListSubscription();
  }

  onGameRemoved(): void {
    this.initializeGamesListSubscription();
  }

  private initializeGamesListSubscription(): void {
    this.gamesService.getUsersGames().subscribe(() => {
      this.games = [...this.gamesService.gamesList];

      this.duplicateGames = [
        ...this.gamesService.gamesDraftList,
        ...this.gamesService.gamesPendingReviewList,
      ];
    });
  }
}
