@if (gamesService.gamesList.length) {

<div class="mb-4">
  <h2 class="text-white font-medium text-3xl mb-5 pl-3">
    GAIMIN Games Developer Tool
  </h2>

  <div class="flex flex-wrap gap-3 justify-content-between">
    @for (banner of homeBanners; track $index) {
    <div
      class="bg-gray-950 relative cursor-pointer p-3 border-round-md transition-duration-300 flex-shrink-0 flex-grow-1 flex justify-content-between"
      (click)="onRedirect(banner.route)"
      [ngStyle]="{
        'min-width': '20rem'
      }"
      [ngClass]="{ 'hover-scale-05': !banner.isComingSoon }"
      [pTooltip]="banner?.isComingSoon ? 'Coming Soon' : undefined"
      tooltipPosition="top"
    >
      <div class="card-small-text align-self-end z-1">
        <h3 class="text-md font-medium mt-0 mb-1">
          {{ banner.title }}
        </h3>
        <a class="text-primary text-md font-normal">{{ banner.link }}</a>
      </div>
      <img
        [src]="banner.smallImage"
        alt=""
        class="absolute z-0"
        [ngStyle]="{
          transform: 'translateY(-20px)',
          right: '2%'
        }"
      />
    </div>
    }
  </div>
</div>

} @else {
<div class="mb-8">
  <h2 class="text-white font-medium text-4xl mb-4">
    Getting Started with GAIMIN
  </h2>

  <div class="flex gap-3 justify-content-between flex-wrap">
    @for (banner of homeBanners; track $index) {
    <div
      class="bg-gray-950 p-4 border-round-2xl cursor-pointer transition-duration-300 flex-1 text-center"
      (click)="onRedirect(banner.route)"
      [pTooltip]="banner?.isComingSoon ? 'Coming Soon' : undefined"
      tooltipPosition="top"
      [ngClass]="{ 'hover-scale-05': !banner.isComingSoon }"
    >
      <img [src]="banner.image" alt="" class="border-round-xl" />

      <div class="flex justify-content-between text-center text-left">
        <div>
          <h3 class="text-xl font-medium mb-1 mt-4">{{ banner.title }}</h3>
          <a class="text-primary text-md font-normal">{{ banner.link }}</a>
        </div>
        <span
          class="pi pi-arrow-up-right align-self-end text-xl text-gray-500"
        ></span>
      </div>
    </div>
    }
  </div>
</div>

}
