<form [formGroup]="companyInformationForm" (ngSubmit)="onSubmit()">
  <div class="mb-4 pt-3 relative">
    <label for="companyName" class="block mb-1 text-md text-gray-200">
      Company name*
    </label>
    <input
      id="companyName"
      formControlName="companyName"
      placeholder="Enter your company name"
      pInputText
      class="w-full"
      [appValidationMessage]="companyInformationForm.controls['companyName']"
      [validationMessages]="{
        required: 'Company name is required',
        pattern: 'Invalid format for Company name'
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="companyAddress" class="block mb-1 text-md text-gray-200">
      Company Address*
    </label>
    <input
      id="companyAddress"
      formControlName="companyAddress"
      type="text"
      placeholder="Enter your company Address"
      pInputText
      class="w-full"
      [appValidationMessage]="companyInformationForm.controls['companyAddress']"
      [validationMessages]="{
        required: 'Company Address is required',
        pattern: 'Invalid format for Company Address'
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="representativeName" class="block mb-1 text-md text-gray-200">
      Representative Name*
    </label>
    <input
      id="representativeName"
      formControlName="representativeName"
      type="text"
      placeholder="Enter name"
      pInputText
      class="w-full"
      [appValidationMessage]="
        companyInformationForm.controls['representativeName']
      "
      [validationMessages]="{
        required: 'Representative Name is required',
        pattern: 'Invalid format for Representative Name'
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="representativeEmail" class="block mb-1 text-md text-gray-200">
      Representative email*
    </label>
    <input
      id="representativeEmail"
      formControlName="representativeEmail"
      type="text"
      placeholder="Enter email"
      pInputText
      class="w-full"
      [appValidationMessage]="
        companyInformationForm.controls['representativeEmail']
      "
      [validationMessages]="{
        required: 'Representative email is required',
        pattern: 'Invalid format for Representative email'
      }"
    />
  </div>

  <div class="mb-5 relative">
    <label for="representativePhone" class="block mb-1 text-md text-gray-200">
      Representative phone number
    </label>
    <p-inputMask
      id="representativePhone"
      formControlName="representativePhone"
      mask="+99 (999) 999-9999"
      placeholder="Enter phone number"
      class="p-fluid"
      [appValidationMessage]="
        companyInformationForm.controls['representativePhone']
      "
      [validationMessages]="{
        pattern: 'Invalid format for Representative phone'
      }"
    />
  </div>

  <div class="flex justify-content-end gap-2">
    <p-button severity="secondary" (onClick)="ref.close()">Cancel</p-button>
    <p-button type="submit" severity="primary" [disabled]="companyInformationForm.invalid">
      Confirm
    </p-button>
  </div>
</form>
