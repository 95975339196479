import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameData } from '../../../../tools/interfaces/response.interfaces';
import { ButtonModule } from 'primeng/button';
import { AdminService } from '../../../../shared/services/admin/admin.service';
import { GamesService } from '../../../../shared/services/games.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-game-access-tab',
  standalone: true,
  imports: [NgIf, AsyncPipe, ButtonModule],
  templateUrl: './admin-game-access-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminGameAccessTabComponent {
  @Input() gameData!: GameData;

  public gameDownloadLink$!: Observable<{ url: string }>;

  constructor(
    public adminService: AdminService,
    public gamesService: GamesService
  ) {}

  ngOnInit(): void {
    this.gameDownloadLink$ = this.gamesService.getGameDownloadLink(
      this.gameData.id
    );
  }

  onDownloadGame(downloadLink: string): void {
    window.location.href = downloadLink;
  }
}
