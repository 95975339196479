import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-layout-sidebar-menuitem',
  standalone: true,
  imports: [RouterModule, TooltipModule],
  templateUrl: './layout-sidebar-menuitem.component.html',
})
export class LayoutSidebarMenuitemComponent {
  @Input() icon!: string;
  @Input() alt!: string;
  @Input() route?: string;
  @Input() comingSoon?: boolean;

  constructor(public router: Router) {}
}
