@if (isReferralUsers) {
<div class="border-round overflow-hidden border-1 border-gray-700">
  <p-table
    [value]="tableData"
    [paginator]="isPaginationVisible"
    [rows]="itemsPerPage"
  >
    <ng-template pTemplate="caption"> My referrals </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="cursor-pointer select-none" (click)="sortByName()">
          Name
          <span
            class="pi"
            [class.pi-arrows-v]="isSortstateNameOriginal"
            [class.pi-arrow-up]="isSortstateNameDesc"
            [class.pi-arrow-down]="isSortstateNameAsc"
          ></span>
        </th>
        <th>Email</th>
        <th class="cursor-pointer select-none" (click)="sortByGmrx()">
          Reward GMRX
          <span
            class="pi"
            [class.pi-arrows-v]="isSortstateGmrxOriginal"
            [class.pi-arrow-up]="isSortstateGmrxDesc"
            [class.pi-arrow-down]="isSortstateGmrxAsc"
          ></span>
        </th>
        <th class="cursor-pointer select-none" (click)="sortByRegisterDate()">
          Created date
          <span
            class="pi"
            [class.pi-arrows-v]="isSortstateRegisterDateOriginal"
            [class.pi-arrow-up]="isSortstateRegisterDateDesc"
            [class.pi-arrow-down]="isSortstateRegisterDateAsc"
          ></span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.fullName }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.reward }}</td>
        <td>{{ user.referralDate | date: 'dd.MM.yy, HH:mm' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
} @else {
<p class="p-4 text-center text-4xl">No referrals yet</p>
}
