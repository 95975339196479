<div
  [ngStyle]="{
    padding:
      (breakpoingService.currentRange$ | async) === SCREEN_SIZE.XSMALL
        ? '3rem 0'
        : '6.25rem 0',
    'min-height': 'calc(100vh - 3.5rem)'
  }"
  class="relative px-3"
  *ngIf="gameData$ | async as gameData"
>
  <app-game-draft-header
    [gameAssets]="assetsSubform.getRawValue()"
    [status]="
      isDraftPage
        ? GAME_STATUS.DRAFT
        : isPendingReviewPage
        ? GAME_STATUS.PENDING_REVIEW
        : gameData.status
    "
    [isPagePreviewDisabled]="assetsSubform.invalid"
    [gameId]="gameData.id"
    [icon]="gameData.info.iconUri"
    [title]="gameData.info.title"
    [developer]="gameData.developer.displayName"
  ></app-game-draft-header>

  <form
    [formGroup]="gameDataForm"
    class="m-auto"
    [ngStyle]="{ 'max-width': '840px' }"
  >
    @if((breakpoingService.currentRange$ | async) === SCREEN_SIZE.XSMALL) {
    <div class="flex justify-content-between">
      <p class="mb-3">{{ activeTabIndex + 1 }}. {{ tabs[activeTabIndex] }}</p>
      @if (activeTabIndex !== tabs.length - 1) {
      <p
        class="mb-3 text-gray-400"
        (click)="activeTabIndex = activeTabIndex + 1"
      >
        {{ activeTabIndex + 2 }}. {{ tabs[activeTabIndex + 1] }}
      </p>
      }
    </div>
    }
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="Basic info">
        <ng-template pTemplate="header">
          <div>
            @if((breakpoingService.currentRange$ | async) !==
            SCREEN_SIZE.XSMALL) {
            <p class="mb-3">1. Basic Info</p>
            }
            <div
              class="border-round-md w-full"
              [ngClass]="{ 'bg-green-500': assetsSubform.valid }"
            ></div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-game-basic-info-subform
            [basicInfoForm]="assetsSubform"
            [gameId]="gameData.id"
          ></app-game-basic-info-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Installation files">
        <ng-template pTemplate="header">
          <div>
            @if((breakpoingService.currentRange$ | async) !==
            SCREEN_SIZE.XSMALL) {
            <p class="mb-3">2. Installation</p>
            }
            <div
              class="border-round-md w-full"
              [ngClass]="{
                'bg-green-500': getInstallationSubformValidationStatus(
                  gameData.status,
                  gameManifest$ | async
                )
              }"
            ></div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          @if(gameData.status === GAME_STATUS.CHANGES_REQUESTED ||
          gameData.status === GAME_STATUS.DRAFT) {
          <app-game-access-subform
            [installationForm]="installationSubform"
            [externalLinkForm]="externalPlayLinkSubform"
            [gameId]="gameData.id"
            [filesPreviouslyUploaded]="gameManifest$ | async"
          ></app-game-access-subform>
          } @else {
          <p class="text-white text-xl mt-0 mb-4">
            Game assets were successfully provided.
          </p>
          }
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Legal">
        <ng-template pTemplate="header">
          <div>
            @if((breakpoingService.currentRange$ | async) !==
            SCREEN_SIZE.XSMALL) {
            <p class="mb-3">3. Legal</p>
            }
            <div
              class="border-round-md w-full"
              [ngClass]="{ 'bg-green-500': legalLinksSubform.valid }"
            ></div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-game-legal-links-subform
            [legalForm]="legalLinksSubform"
          ></app-game-legal-links-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Social & Support">
        <ng-template pTemplate="header">
          <div>
            @if((breakpoingService.currentRange$ | async) !==
            SCREEN_SIZE.XSMALL) {
            <p class="mb-3">4. Social & Support</p>
            }
            <div
              class="border-round-md w-full"
              [ngClass]="{ 'bg-green-500': socialLinksSubform.valid }"
            ></div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-game-social-links-subform
            [socialLinksForm]="socialLinksSubform"
          ></app-game-social-links-subform>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Send to Review">
        <ng-template pTemplate="header">
          <div>
            @if((breakpoingService.currentRange$ | async) !==
            SCREEN_SIZE.XSMALL) {
            <p class="mb-3">5. Send to Review</p>
            }
            <div class="border-round-md w-full"></div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="text-center mt-6">
            @if (gameData.status === GAME_STATUS.REVIEW) {
            <p class="text-white text-xl mt-0 mb-4">
              Your request is under review. We'll notify you once it's done.
            </p>
            } @else {
            <h2 class="text-white text-2xl mb-4">
              Send {{ gameData.info.title }} to GAIMIN Review
            </h2>
            <div class="text-center mb-4">
              <p class="text-gray-400 text-md">
                <p-checkbox
                  [binary]="true"
                  inputId="user-consent"
                  formControlName="userConsent"
                />

                By checking, you agree to our
                <a
                  class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
                  (click)="onOpenDistributionAgreement()"
                >
                  Distribution Agreement
                </a>
              </p>
            </div>
            <p-button
              class="p-button-primary p-button-md"
              (onClick)="onSubmitGameForReview(gameData.id)"
              [disabled]="!isFormDataValid(gameManifest$ | async)"
            >
              Send to review
            </p-button>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </form>
  @if (activeTabIndex !== 4) {
  <div
    class="p-2 bottom-0 right-0 fixed w-full"
    [ngStyle]="{
      'background-color': '#0A0118',
      height:
        (breakpoingService.currentRange$ | async) === SCREEN_SIZE.XSMALL
          ? '8rem'
          : null,
  }"
  >
    <div
      class="m-auto flex justify-content-between gap-2"
      [ngStyle]="{ 'max-width': '1000px' }"
    >
      <div>
        @if (gameData.status === GAME_STATUS.PUBLISHED && !isDraftPage &&
        !isPendingReviewPage) {
        <p-button
          severity="danger"
          [outlined]="true"
          label="Unpublish game"
          (onClick)="onUnpublishRequest(gameData.id)"
        ></p-button>
        }
      </div>

      <div class="flex gap-4">
        @if (gameData.status === GAME_STATUS.CHANGES_REQUESTED ||
        gameData.status === GAME_STATUS.DRAFT || isDraftPage) {
        <p-button
          severity="secondary"
          label="Save in draft"
          (onClick)="saveGameDraft(gameData.id).subscribe()"
        ></p-button>
        } @if (gameData.status === GAME_STATUS.PUBLISHED && !isDraftPage &&
        !isPendingReviewPage && !isGameOnReview) {
        <p-button
          severity="secondary"
          label="Edit Information"
          (onClick)="editGameInfo(gameData.id)"
        ></p-button>
        }

        <p-button
          label="Next step"
          (onClick)="activeTabIndex = activeTabIndex + 1"
        ></p-button>
      </div>
    </div>
  </div>
  }

  <img
    class="absolute left-0 w-full rotate-180"
    [ngStyle]="{ top: '0px', 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />

  <img
    class="absolute left-0 bottom-0 w-full"
    [ngStyle]="{ 'z-index': -1 }"
    src="./assets/illustrations/background-lights.png"
    alt=""
  />
</div>

<app-body-background-setter></app-body-background-setter>
