import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SCREEN_SIZE } from '../../../tools/constants/breakpoint.constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { switchMap } from 'rxjs';
import { BreakpointService } from '../../services/breakpoint.service';
import {
  NgStyle,
  NgClass,
  AsyncPipe,
  DatePipe,
  CurrencyPipe,
} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PrepaidBundle } from '../../../tools/interfaces/response.interfaces';
import { PaymentRequestsService } from '../../services/payment-requests.service';

@Component({
  selector: 'app-order-summary-dialog',
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgClass,
    AsyncPipe,
    ButtonModule,
    CheckboxModule,
    DatePipe,
    CurrencyPipe,
  ],
  templateUrl: './order-summary-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryDialogComponent {
  gaiminDetails = {
    companyName: 'GAIMIN AG',
    companyAddress: 'Bahnhofstrasse 7 6300 Zug',
    registrationNumber: 'CHE-174.355.502',
  };

  readonly SCREEN_SIZE: typeof SCREEN_SIZE = SCREEN_SIZE;
  userConsent: boolean = false;

  get userData(): {
    companyName: string;
    companyAddress: string;
    representativeName: string;
    representativeEmail: string;
    representativePhone: string;
  } {
    return this.config.data.userData;
  }

  get bundle(): PrepaidBundle {
    return this.config.data.bundle;
  }

  get orderSummary() {
    return {
      companyName: this.userData.companyName,
      companyAddress: this.userData.companyAddress,
      representativeName: this.userData.representativeName,
      representativeEmail: this.userData.representativeEmail,
      representativePhone: this.userData.representativePhone,
      subscriptionProject: 'File Sharing',
      subscriptionName: this.bundle.name,
      subscriptionPrice: this.bundle.subscriptionPrices[0].price,
      currency: 'USD',
      agreementStatus: true,
      paymentDate: new Date().toString(),
    };
  }

  constructor(
    public breakpointService: BreakpointService,
    public config: DynamicDialogConfig,
    private paymentRequestsService: PaymentRequestsService
  ) {}

  onOpenTermsAndConditions() {
    window.open(
      'https://legal.gaimin.gg/terms-and-conditions-games-mar-2025',
      '_blank'
    );
  }

  public onContinueClick() {
    this.paymentRequestsService
      .createOrderSummaryRequest(this.orderSummary)
      .pipe(
        switchMap(() =>
          this.paymentRequestsService.getPurchaseLinkRequest(this.bundle.id)
        )
      )
      .subscribe((res) => {
        window.location.href = res.url;
      });
  }
}
