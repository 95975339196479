import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router, UrlSegment } from '@angular/router';
import {
  AccessToken,
  SignUp,
} from '../../../tools/interfaces/response.interfaces';
import { environment } from '../../../../environments/environment';
import {
  AUTH_ACTION,
  USER_ROLE,
} from '../../../tools/constants/auth.constants';
import { GamesService } from '../games.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MigrationNoticeDialogComponent } from '../../modals/migration-notice-dialog/migration-notice-dialog.component';
import { AuthRequestsService } from './auth-requests.service';
import { BasicInfoDialogComponent } from '../../modals/basic-info-dialog/basic-info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  accessToken: string | null = null;
  refreshToken: string | null = null;

  //TODO: consider usage sessionStorage
  isAdmin$: BehaviorSubject<boolean>;
  isLoggedIn$: BehaviorSubject<boolean>;

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem('accessToken', token);
  }

  setRefreshToken(token: string): void {
    this.refreshToken = token;
    localStorage.setItem('refreshToken', token);
  }

  constructor(
    private router: Router,
    private gamesService: GamesService,
    private dialogService: DialogService,
    private authRequestsService: AuthRequestsService
  ) {
    // TODO: consider renaming
    const previouslyAuthenticated: boolean =
      this.retreiveTokensFromLocalStorage();
    this.isLoggedIn$ = new BehaviorSubject<boolean>(previouslyAuthenticated);
    this.isAdmin$ = new BehaviorSubject<boolean>(true);
  }

  refreshAccessToken(): Observable<AccessToken> {
    const refreshToken: string | null = this.getRefreshToken();

    return this.authRequestsService
      .refreshAccessTokenRequest(refreshToken)
      .pipe(
        tap((tokenResponse: AccessToken) => {
          const { access_token: accessToken, refresh_token: refreshToken } =
            tokenResponse;
          this.setAccessToken(accessToken);
          this.setRefreshToken(refreshToken);
        })
      );
  }

  private retreiveTokensFromLocalStorage(): boolean {
    const accessToken: string = localStorage.getItem('accessToken') || '';
    const refreshToken: string = localStorage.getItem('refreshToken') || '';

    if (!refreshToken || !accessToken) return false;

    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);

    return true;
  }

  signOut(): void {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.setItem('accessToken', '');
    localStorage.setItem('refreshToken', '');
    this.isLoggedIn$.next(false);
    this.isAdmin$.next(false);

    const loginUrl: string = btoa(`${environment.devportalUrl}/auth`);
    const params = new HttpParams().set('returnURL', loginUrl);
    const gaiminAuthLogoutUrl = `${
      environment.gaiminAuth
    }/logout?${params.toString()}`;

    window.location.href = gaiminAuthLogoutUrl;
  }

  // TODO: refactor
  handleAccessTokenResponse(accessToken: string, refreshToken: string): void {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
    this.isLoggedIn$.next(true);
    this.authRequestsService.getUserRolesRequest().subscribe({
      next: (userRoles) => {
        if (userRoles.includes(USER_ROLE.ADMIN)) {
          this.isAdmin$.next(true);
          this.router.navigateByUrl('/admin/gameslist');
        } else {
          this.gamesService.getUsersGames().subscribe((games) => {
            if (games.length) {
              this.router.navigateByUrl('/app/home');
            } else {
              this.router.navigateByUrl('/app/add-first-game');
            }
          });
        }
      },
      error: (e) => {
        if (e.type === 'UNAUTHENTICATED') {
          this.dialogService.open(BasicInfoDialogComponent, {
            data: {
              accessToken,
              refreshToken,
            },
            closable: false,
          });
        }
      },
    });
  }

  authActionRedirect(authActionType: AUTH_ACTION): void {
    const redirectUrl: string = btoa(
      `${environment.devportalUrl}/auth/callback/${authActionType}`
    );
    const params: HttpParams = new HttpParams().set('returnURL', redirectUrl);
    const authUrl: string = this.getAuthUrl(authActionType, params);

    window.location.href = authUrl;
  }

  openMigrationNotice(): void {
    this.dialogService.open(MigrationNoticeDialogComponent, {});
  }

  private getAuthUrl(authActionType: AUTH_ACTION, params: HttpParams): string {
    switch (authActionType) {
      case AUTH_ACTION.SIGN_IN:
      case AUTH_ACTION.MIGRATE_ACCOUNT: {
        return `${environment.gaiminAuth}/signin?${params.toString()}`;
      }
      case AUTH_ACTION.SIGN_UP:
      case AUTH_ACTION.MIGRATE_AND_CREATE: {
        const signUpUrl: string = btoa(
          `${environment.gaiminAuth}/signup?${params.toString()}`
        );
        return `${environment.gaiminAuth}/logout?returnURL=${signUpUrl}
        `;
      }
    }
  }
}
