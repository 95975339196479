import { Component } from '@angular/core';
import { NgStyle } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../../../shared/services/auth/auth.service';

import { AUTH_ACTION } from '../../../tools/constants/auth.constants';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  imports: [NgStyle, ButtonModule, RouterModule],
})
export class SignInComponent {
  public readonly SIGN_IN: AUTH_ACTION = AUTH_ACTION.SIGN_IN;

  constructor(public authService: AuthService) {}
}
