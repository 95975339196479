<div class="p-5">
  <div class="flex justify-content-between align-items-center mb-4">
    <h2>Order Summary</h2>
    <img src="./assets/icons/gaimin-logo.svg" alt="" class="print-only" />
  </div>
  <div class="flex gap-4 mb-4 flex-wrap">
    <div class="bg-gray-800 flex-1 border-round-md p-4">
      <h3>Client company details</h3>

      <hr class="border-top-1 border-gray-700 mb-4" />

      <div class="flex flex-column gap-4">
        <div>
          <p class="text-base text-gray-400 mb-2">Company name</p>
          <p class="text-lg">{{ orderSummary.companyName }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">Company Address</p>
          <p class="text-lg">{{ orderSummary.companyAddress }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">Representative name</p>
          <p class="text-lg">{{ orderSummary.representativeName }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">Representative email</p>
          <p class="text-lg">{{ orderSummary.representativeEmail }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">
            Representative phone number
          </p>
          <p class="text-lg">{{ orderSummary.representativePhone }}</p>
        </div>
      </div>
    </div>

    <div
      class="flex-1 bg-gray-800 p-4 border-round-md"
      [ngStyle]="{ height: 'fit-content' }"
    >
      <h3>GAIMIN details</h3>

      <hr class="border-top-1 border-gray-700" />

      <div class="flex flex-column gap-4">
        <div>
          <p class="text-base text-gray-400 mb-2">Company name</p>
          <p class="text-lg">{{ gaiminDetails.companyName }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">Company Address</p>
          <p class="text-lg">{{ gaiminDetails.companyAddress }}</p>
        </div>

        <div>
          <p class="text-base text-gray-400 mb-2">Registration Number</p>
          <p class="text-lg">{{ gaiminDetails.registrationNumber }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-800 p-3 border-round-md mb-3">
    <h3>Billing details</h3>

    <hr class="border-top-1 border-gray-700" />

    <div class="flex justify-content-between gap-4 flex-wrap">
      <div>
        <p class="text-base text-gray-400 mb-2">Service</p>
        <p class="text-lg flex align-items-center gap-2">
          <img
            src="./assets/icons/file-sharing.svg"
            class="print-only"
            alt=""
          />
          {{ orderSummary.subscriptionProject }}
        </p>
      </div>

      <div>
        <p class="text-base text-gray-400 mb-2">Subscription Level</p>
        <p class="text-lg">{{ orderSummary.subscriptionName }}</p>
      </div>

      <div>
        <p class="text-base text-gray-400 mb-2">Subscription</p>
        <p class="text-lg">Monthly</p>
      </div>

      <div>
        <p class="text-base text-gray-400 mb-2">Payment date</p>
        <p class="text-lg">
          {{ orderSummary.paymentDate | date : 'MMMM d y' }}
        </p>
      </div>

      <div>
        <p class="text-base text-gray-400 mb-2">Price</p>
        <p class="text-lg">
          {{
            orderSummary.subscriptionPrice / 100
              | currency : 'USD' : 'symbol' : '1.0-0'
          }}
        </p>
      </div>
    </div>
  </div>

  <!-- TODO: adapt for mobile layout  -->
  <div class="no-print">
    <p class="mb-2">
      <p-checkbox
        [binary]="true"
        inputId="user-consent"
        [(ngModel)]="userConsent"
      />

      By signing you agree to our
      <a
        class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
        (click)="onOpenTermsAndConditions()"
      >
        Tearms And Conditions
      </a>
      for our File Sharing Service.
    </p>
    <p class="text-gray-400 mb-2">The section you are agreeing to include:</p>
  </div>
</div>

<div
  class="flex gap-2 justify-content-end p-3 border-top-1 border-gray-700 pb-8 no-print"
  [ngClass]="{
    'pb-8': (breakpointService.currentRange$ | async) === SCREEN_SIZE.XSMALL
  }"
>
  <p-button severity="secondary"> Cancel </p-button>
  <p-button (onClick)="onContinueClick()" severity="primary" [disabled]="!userConsent"> Continue </p-button>
</div>
