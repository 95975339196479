<h2 class="my-3">Key Terms Document</h2>

<h3 class="my-3">Game Developer</h3>
<p class="my-3">
  The company or private individual name used to register the account under
  which the game is uploaded to the GAIMIN Platform.
  <br />
  <small
    >[This can either be a Company Name with the Company Registered address or a
    private individual and the home address for the individual.]</small
  >
</p>

<h3 class="my-3">Game Distributor</h3>
<p class="my-3">
  GAIMIN LDA incorporated and registered in Portugal whose registered office is
  at Rua José Alfredo da Costa Azevedo, Lisbon, 2635 585, Portugal.
</p>

<h3 class="my-3">Background</h3>
<p class="my-3">
  The Game Distributor owns certain proprietary technology that implements the
  Game Publishing Service, which enables individuals to stream game content to
  their computer devices;
  <br />
  The Game Developer is engaged in the business of developing, publishing,
  licensing and/or distributing entertainment software products, and has the
  right to license and distribute interactive software games; and
  <br />
  The Game Distributor wishes to receive from the Game Developer, and the Game
  Developer wishes to grant to the Game Distributor, a license for the use,
  display, and distribution of the Game subject to this Key Terms Document and
  the GD Ts&Cs.
</p>

<h3 class="my-3">Game</h3>
<p class="my-3">
  The video game to be uploaded in the GAIMIN Games Development Portal.
</p>

<h3 class="my-3">Initial Term</h3>
<p class="my-3">12 months</p>

<h3 class="my-3">Launch Date</h3>
<p class="my-3">
  The date on which the Game is accepted by GAIMIN to be launched by GAIMIN on
  the GAIMIN Games Launching Platform and as notified to the Game Developer.
</p>

<h3 class="my-3">Fees</h3>
<p class="my-3">
  €1 (One Euro) - the receipt and sufficiency of which is hereby acknowledged by
  the Game Distributor.
</p>

<h3 class="my-3">Key Performance Indicators</h3>
<p class="my-3">
  As set out in separate documents and as agreed by both parties in writing.
</p>

<p class="my-3">
  The Agreement between the Game Developer and the Game Distributor consists of
  this Key Terms Document and the GD Ts&Cs. The Game Developer confirms that it
  has read this Key Terms Document and the GD Ts&Cs and agrees to be bound by
  the terms of both. Unless the context otherwise requires, definitions used in
  the GD Ts&Cs have the same meaning in this Key Terms Document. The Agreement
  between the Game Developer and the Game Distributor shall be legally formed
  and the parties legally bound when the Game Distributor accepts in writing the
  Game Developer’s submission of this Key Terms Document.
</p>

<h2 class="my-3">Game Distribution Terms and Conditions (“GD Ts&Cs”)</h2>

<h3 class="my-3">Interpretation</h3>

<p class="my-3">
  The definitions and rules of interpretation in this clause 1 apply in this
  Agreement:
</p>

<ul class="my-3">
  <li>
    <strong>Agreement:</strong> the agreement between the Game Developer and the
    Game Distributor, made up of the Key Terms Document and these GD Ts&Cs as
    well as any other document mentioned in the Key Terms Document or these GD
    Ts & Cs;
  </li>
  <li>
    <strong>Commencement Date:</strong> the date this Agreement is signed by
    both parties;
  </li>
  <li>
    <strong>Confidential Information:</strong> any information in any form or
    medium obtained by or on behalf of either party from or on behalf of the
    other party in relation to the Agreement which is expressly marked as
    confidential or which a reasonable person would consider to be confidential
    or proprietary, whether disclosed or obtained before, on, or after the date
    of this Agreement, together with any reproductions of such information or
    any part of it;
  </li>
  <li>
    <strong>Consumer Devices:</strong> television set-top boxes, smart TVs,
    video and media streaming devices, personal computers, handheld devices such
    as smartphones and tablets, and any other devices which are capable of
    delivering interactive entertainment software products to end users via
    broadband, internet, over-the-top delivery, or directly or indirectly
    through intermediary private networks;
  </li>
  <li>
    <strong>Documentation:</strong> any user documentation, manuals, and/or
    written product specifications available for the Game;
  </li>
  <li>
    <strong>Extended Term:</strong> has the meaning set out in clause 9.1;
  </li>
  <li>
    <strong>Fees:</strong> the fees paid and/or payable by the Game Developer to
    the Game Distributor, as outlined in the Key Terms Document;
  </li>
  <li>
    <strong>Game:</strong> the interactive software game outlined in the Key
    Terms Document;
  </li>
  <li>
    <strong>Game Distributor:</strong> the party responsible for distributing
    the Game to its Subscribers, as outlined in the Key Terms Document;
  </li>
  <li>
    <strong>Game Developer:</strong> the party who has developed the Game, as
    outlined in the Key Terms Document;
  </li>
  <li>
    <strong>Game Publishing Service:</strong> the delivery of Games to
    Subscribers via Consumer Devices;
  </li>
  <li><strong>Indemnitees:</strong> has the meaning set out in clause 7.2;</li>
  <li>
    <strong>Initial Term:</strong> the initial minimum duration of this
    Agreement, as outlined in the Key Terms Document;
  </li>
  <li><strong>Instructions:</strong> has the meaning set out in clause 3.4;</li>
  <li>
    <strong>Intellectual Property Rights:</strong> patents, utility models,
    rights to inventions, copyright and neighboring related rights, moral
    rights, trademarks, service marks, business names, domain names, rights in
    get-up, trade dress, goodwill, the right to sue for passing off or unfair
    competition, rights in designs, rights in computer software, database
    rights, rights to use and protect the confidentiality of confidential
    information (including know-how and trade secrets) and all other
    intellectual property rights, in each case whether registered or
    unregistered, and including all applications and rights to apply for and be
    granted, renewals or extensions of, and rights to claim priority from, such
    rights and all similar or equivalent rights or forms of protection which
    subsist or will subsist now or in the future in any part of the world;
  </li>
  <li><strong>Licence:</strong> has the meaning set out in clause 3.1;</li>
  <li>
    <strong>Launch Date:</strong> the date that the Game shall first be made
    available for access via the Platform;
  </li>
  <li>
    <strong>Platform:</strong> the Game Distributor’s proprietary technology
    that implements the Game Publishing Service;
  </li>
  <li>
    <strong>Subscriber:</strong> any third-party individual end-user who
    receives access to the Platform or any similar technology through a Consumer
    Device;
  </li>
  <li><strong>Term:</strong> the Initial Term and/or any Extended Term; and</li>
  <li>
    <strong>Virus:</strong> any thing or device (including any software, code,
    file, or programme) which may: prevent, impair, or otherwise adversely
    affect the operation of any computer software, hardware, or network, any
    telecommunications service, equipment, or network, or any other service or
    device; prevent, impair, or otherwise adversely affect access to or the
    operation of any programme or data, including the reliability of any
    programme or data (whether by re-arranging, altering, or erasing the
    programme or data in whole or part or otherwise); or adversely affect the
    user experience, including worms, trojan horses, viruses, and other similar
    things or devices.
  </li>
</ul>

<h2 class="my-3">Clause Interpretation</h2>
<ul class="my-3">
  <li>
    Clause headings in these GD Ts&Cs shall not affect the interpretation of
    this Agreement.
  </li>
  <li>
    A reference to legislation or a legislative provision is a reference to it
    as amended, extended, or re-enacted from time to time.
  </li>
  <li>References to clauses are to the clauses of these GD Ts&Cs.</li>
  <li>
    A person includes a natural person, corporate or unincorporated body
    (whether or not having separate legal personality).
  </li>
  <li>
    This Agreement shall be binding on, and enure to the benefit of, the parties
    to this Agreement and their respective personal representatives, successors,
    and permitted assigns, and references to any party shall include that
    party’s personal representatives, successors, and permitted assigns.
  </li>
  <li>A reference to writing or written excludes fax but not email.</li>
  <li>
    Any obligation on a party not to do something includes an obligation not to
    allow that thing to be done.
  </li>
  <li>
    Any words following the terms including, include, in particular, for example
    or any similar expression shall be construed as illustrative and shall not
    limit the sense of the words preceding those terms.
  </li>
</ul>

<h2 class="my-3">Basis of Agreement</h2>
<ul class="my-3">
  <li>
    The terms of this Agreement apply to the exclusion of any terms and
    conditions submitted, proposed, or stipulated by the Game Developer in
    whatever form and at whatever time. If the Game Developer provides the Game
    Distributor with a purchase order (and/or any other terms and conditions),
    it shall be purely for the Game Developer’s administrative purposes and
    shall not form part of this Agreement.
  </li>
  <li>
    The Game Developer’s submission to the Game Distributor of the Key Terms
    Document shall be deemed to be an offer made by the Game Developer to the
    Game Distributor for the Game to be made available to Subscribers via the
    Platform, subject to the provisions of these GD Ts&Cs, and the Game
    Distributor shall accept such an offer by confirming such acceptance in
    writing to the Game Developer. This Agreement shall be legally formed, and
    the parties legally bound when the Game Distributor accepts in writing the
    Game Developer’s submission of the Key Terms Document.
  </li>
  <li>
    Save as expressly provided in this Agreement, this Agreement shall operate
    to the entire exclusion of any other agreement, understanding, or
    arrangement of any kind between the parties preceding the date of this
    Agreement and in any way relating to the subject matter of this Agreement
    and to the exclusion of any representations not expressly stated in this
    Agreement except for any fraudulent misrepresentations or any
    misrepresentation as to a fundamental matter. Each of the parties
    acknowledges that it has not entered into this Agreement based on any
    representation that is not expressly incorporated into this Agreement.
  </li>
  <li>
    This Agreement constitutes the whole agreement and understanding of the
    parties as to the subject matter of this Agreement and there are no
    provisions, terms, conditions, or obligations, whether oral or written,
    express or implied, other than those contained or referred to in this
    Agreement.
  </li>
  <li>
    The Game Developer hereby grants to the Game Distributor a non-exclusive,
    sub-licensable (solely with respect to clause 3.1.1 to clause 3.1.4) licence
    during the Term:
    <ol class="my-3">
      <li>
        to use, exhibit, distribute, provide, and otherwise make available the
        Game to Subscribers via the Platform;
      </li>
      <li>to advertise, promote, publicly perform, and market the Game; and</li>
      <li>
        to use any trademarks and logos that appear in the Game in connection
        with the Game Distributor’s exercise of the rights granted under clause
        3.1.1 to clause 3.1.3;
      </li>
    </ol>
  </li>
  <li>
    The Game Developer further grants to the Game Distributor a licence to use
    all necessary and applicable Intellectual Property Rights in the Game to
    carry out the purpose and intent detailed under this Agreement.
  </li>
  <li>
    The Game Developer shall be solely responsible for obtaining all clearances
    and licences necessary for the Game Distributor to perform its obligations
    under this Agreement.
  </li>
  <li>
    The Game Developer shall provide the Game Distributor, free of charge, with
    written instructions and documentation as may be necessary or desirable to
    set up and install the Game or any part thereof, as such are made available
    to the Game Distributor (the Instructions).
  </li>
  <li>
    Where any new version or update of the Game is developed by the Game
    Developer, the Game Developer shall promptly provide the details of the new
    version or update of the Game to the Game Distributor and provide the Game
    Distributor with reasonable assistance to enable the Game Distributor to
    modify the version of the Game made available to Subscribers via the
    Platform.
  </li>
</ul>

<h2 class="my-3">Fees</h2>
<ul class="my-3">
  <li>
    If the Key Terms Document outlines that the fees paid to the Game
    Distributor by the Game Developer shall be greater than €1, the following
    clause shall be applicable to this Agreement.
  </li>
  <li>
    In consideration of the Game Distributor’s performance of its obligations
    under this Agreement, the Game Developer shall pay the Fees to the Game
    Distributor.
  </li>
  <li>
    The Fees shall be as set out in, and/or as calculated in accordance with,
    the charging mechanism set out in the Key Terms Document.
  </li>
  <li>
    The Game Distributor may charge the Game Developer for any reasonable
    third-party costs incurred in the course of performing its obligations under
    this Agreement. Such costs shall not be considered to be included as part of
    the Fees and shall be incurred and charged to the Game Developer on an ad
    hoc basis.
  </li>
  <li>
    The Game Distributor shall be entitled to increase the Fees on an annual
    basis on each anniversary of the Commencement Date. Any such increase in the
    Fees shall be limited to the percentage increase in Consumer Prices Index in
    the preceding 12-month period, plus 5%.
  </li>
  <li>
    The Game Developer shall pay the Fees (and third-party costs) to the Game
    Distributor within 30 days of each invoice’s date. However, if the Game
    Distributor is to incur significant third-party costs on the Game
    Developer’s behalf, the parties may agree (such agreement not to be
    unreasonably conditioned, withheld, or delayed) on a case-by-case basis that
    the Game Developer shall pay the Game Distributor in advance before the Game
    Distributor has incurred the expense.
  </li>
  <li>
    Unless otherwise set out in the Key Terms Document, all sums due under this
    Agreement are exclusive of VAT or other sales, import, or export duties or
    taxes (if applicable) which shall be payable in addition at the same time as
    payment of any sums due.
  </li>
  <li>
    The Game Developer shall pay the Game Distributor all sums due under this
    Agreement by any payment method that the Game Distributor may stipulate from
    time to time. No payment shall be considered paid until the Game Distributor
    has received it in cleared funds in full.
  </li>
  <li>
    Payment of sums due under this Agreement shall be in the currency stipulated
    on the applicable invoice from time to time.
  </li>
  <li>
    All amounts due under this Agreement shall be paid by the Game Developer to
    the Game Distributor in full without any set-off, counterclaim, deduction,
    or withholding (other than any deduction or withholding of tax as required
    by law).
  </li>
  <li>
    If the Game Developer is late in paying any part of any monies due to the
    Game Distributor under this Agreement, the Game Distributor may (without
    prejudice to any other right or remedy available to us whether under this
    Agreement or by any statute, regulation, or bye-law) do any or all of the
    following:
  </li>
  <ul>
    <li>
      charge interest on the overdue amount due but unpaid at the annual rate of
      interest set under Section 6 of the Late Payment of Commercial Debts
      (Interest) Act 1998 from time to time from the due date until payment
      (after as well as before judgment), such interest to run from day to day
      and to be compounded monthly;
    </li>
    <li>
      recover its costs, expenses, and charges (including legal and debt
      collection fees and costs) in collecting the late payment; and/or
    </li>
    <li>
      suspend performance of this Agreement until payment in full has been made.
    </li>
  </ul>
</ul>

<h2 class="my-3">Marketing Materials</h2>
<ul class="my-3">
  <li>
    The Game Developer shall provide relevant, up-to-date marketing materials to
    the Game Distributor for the Game in a commercially standard format. The
    Game Developer hereby grants to the Game Distributor, for the Term, a
    non-exclusive, sub-licensable right to distribute and display such marketing
    materials in support of the Game.
  </li>
  <li>
    In the event that the Game Distributor creates its own marketing materials
    in connection with the Game and such marketing materials use or incorporate
    the Game Developer’s Intellectual Property Rights, the Game Distributor
    shall submit an example of each such marketing material to the Game
    Developer for its approval. The Game Developer shall, in good faith, approve
    or reasonably reject the marketing material and specify the basis for the
    rejection within 7 days after its receipt of such example materials. The
    Game Developer’s failure to respond within 7 days to the Game Distributor’s
    request for approval in connection with marketing materials shall be deemed
    to be the Game Developer’s approval of such marketing materials.
  </li>
  <li>
    The Game Developer shall use best endeavors to promote the Platform in order
    to encourage supporters of the Game Developer and the Game to download the
    Platform.
  </li>
  <li>
    The Game Developer acknowledges and agrees that the Game Distributor shall
    be entitled to terminate this Agreement upon written notice to the Game
    Developer if the Game Developer fails to meet or exceed the key performance
    indicators set out in the Key Terms Document.
  </li>
</ul>

<h2 class="my-3">Ownership</h2>
<ul class="my-3">
  <li>
    The Game Developer shall retain all right, title, and interest in and to the
    Game, including all Intellectual Property Rights in the Game. Except as
    outlined in this Agreement, nothing in this Agreement shall be deemed to
    grant or assign to the Game Distributor any Intellectual Property Rights in
    or to the Game.
  </li>
  <li>
    Notwithstanding clause 6.1, the Game Developer acknowledges and agrees that
    the Game Distributor, and its licensors, owns all right, title, and interest
    (including all Intellectual Property Rights) in and to the Platform, the
    Game Publishing Service, and in and to any work product and other materials
    originally developed or created by the Game Distributor either before,
    after, or pursuant to this Agreement.
  </li>
</ul>

<h2 class="my-3">Warranties and Indemnities</h2>
<ul class="my-3">
  <li>
    The Game Developer warrants, undertakes, and agrees that:
    <ul class="my-3">
      <li>it is entitled to grant the Licence to the Game Distributor;</li>
      <li>
        the Game, and the exploitation by the Game Distributor of the Licence,
        will not infringe the rights (including the Intellectual Property
        Rights) of any third party;
      </li>
      <li>
        the Game does not, and shall not at any point during the Term:
        <ul class="my-3">
          <li>contain any Viruses;</li>
          <li>
            contain any material that is unlawful, harmful, threatening,
            defamatory, obscene, infringing, harassing, or racially or
            ethnically offensive;
          </li>
          <li>facilitate illegal activity;</li>
          <li>depict sexually explicit images;</li>
          <li>promote unlawful violence; or</li>
          <li>
            discriminate based on race, gender, colour, religious belief, sexual
            orientation, or disability;
          </li>
        </ul>
      </li>
      <li>
        the Game shall be of high quality and will conform to the functional
        descriptions for the Game contained in the Documentation and the
        Instructions and will comply with all applicable laws and regulations in
        each jurisdiction that a Subscriber may access the Game from.
      </li>
    </ul>
  </li>
  <li>
    The Game Developer shall be liable and will compensate, indemnify, defend,
    and hold harmless the Game Distributor, its directors, officers, employees,
    agents, affiliates, distributors, and sub-licensees (Indemnitees) against
    any and all claims, suits, demands, awards, liabilities, costs, damages,
    losses, or expenses, including reasonable legal fees incurred by or imposed
    upon the Indemnitees in connection with or arising from any breach of clause
    7.1.
  </li>
  <li>
    The Game Developer shall obtain and pay for all licences and make all
    returns required for the public performance, mechanical reproduction, and
    other exploitation of the music incorporated in the Game in accordance with
    the rights licensed to the Game Distributor under this Agreement due to PRS,
    SOCAN, MCPS, or related collecting societies and the Game Developer hereby
    indemnifies the Game Distributor against any and all liability, loss,
    damage, or expense arising from the performance, mechanical reproduction, or
    other exploitation of such music.
  </li>
</ul>

<h2 class="my-3">Game Distributor’s Obligations</h2>
<ol class="my-3">
  <li>
    Subject to the Game Developer’s compliance with its obligations under this
    Agreement, the Game Distributor shall, from the Launch Date and for the
    remainder of the Term, make the Game available to Subscribers via the
    Platform.
  </li>

  <li>
    The Game Distributor reserves the right to remove the Game from the
    Platform, and/or terminate this Agreement, without notice, where it
    reasonably suspects or has reasonable cause for concern that:
    <ol class="my-3">
      <li>the Game does not comply with any applicable laws;</li>
      <li>
        the Game Developer (or the Game) fails to comply with any warranty set
        out in clause 7.1; or
      </li>
      <li>
        the Game Distributor may be exposed to disrepute, contempt, scandal or
        ridicule, or if the Game being available on the Platform, or the Game
        Distributor having a commercial relationship with the Game Developer,
        would tend to shock, insult or offend the public, reflect unfavourably
        on the Game Distributor’s image or reputation, or prejudice the Game
        Distributor in any way.
      </li>
    </ol>
  </li>

  <li>
    The Game Distributor reserves the right, at any time, to carry out repairs,
    maintenance, amend or introduce new facilities and functions in respect of
    all or any part of the Platform or the Game Publishing Service.
  </li>

  <li>
    It is the Game Developer’s responsibility to ensure that it provides the
    Game Distributor with the information required to enable the Game
    Distributor to properly make the Game available to Subscribers via the
    Platform, and to perform the Game Distributor’s obligations under this
    Agreement. The Game Distributor shall not be responsible or have any
    liability for any failure to make the Game available to Subscribers via the
    Platform to the extent caused by the Game Developer’s failure to properly
    ensure the provision of the relevant information to the Game Distributor.
  </li>

  <li>
    The Game Distributor may, at its absolute discretion, from time to time
    either host the Platform or the Game Streaming Service on its own servers or
    use third-party suppliers to do so in whole or in part.
  </li>

  <li>
    Whilst the Game Distributor shall endeavour to ensure that information and
    materials on or provided through the Platform are correct and accurate, no
    warranty or representation, express or implied, is given that they are
    complete, accurate, up-to-date, fit for a particular purpose and, to the
    extent permitted by law, the Game Distributor shall not have any liability
    for any errors, omissions or inaccuracy in such information and materials.
  </li>
</ol>

<h2 class="my-3">Term and Termination</h2>
<ol class="my-3">
  <li>
    This Agreement shall, unless terminated earlier in accordance with this
    clause 9, begin on the Commencement Date and shall automatically continue
    for a further 12-month period (an Extended Term) at the end of the Initial
    Term or the previous Extended Term, as the case may be.
  </li>

  <li>
    Either party may give written notice to the other party, not later than 30
    days before the end of the Initial Term or the relevant Extended Term, to
    terminate this Agreement at the end of the Initial Term or the relevant
    Extended Term, as the case may be.
  </li>

  <li>
    Without affecting any other right or remedy available to it, the Game
    Distributor may terminate this Agreement with immediate effect by giving
    written notice to the Game Developer if the Game Developer undergoes a
    change of control.
  </li>

  <li>
    Without affecting any other right or remedy available to it, either party
    may terminate this Agreement with immediate effect by giving written notice
    to the other party if:
    <ol class="my-3">
      <li>
        the other party is in material breach of any of its obligations under
        this Agreement, and, where such material breach is capable of remedy,
        the other party fails to remedy such breach within a period of 30 days
        of being notified of such breach;
      </li>
      <li>
        the other party gives notice to any of its creditors that it has
        suspended or is about to suspend payment or if it shall be unable to pay
        its debts within the meaning of Section 123 of the Insolvency Act 1986,
        or an order is made or a resolution is passed for the winding-up of the
        other party or an administration order is made or an administrator is
        appointed to manage the affairs, business and property of the other
        party or a receiver and/or manager or administrative receiver is
        appointed in respect of all or any of the other party’s assets or
        undertaking or circumstances arise which entitle the court or a creditor
        to appoint a receiver and/or manager or administrative receiver or
        administrator or which entitle the court to make a winding-up or
        bankruptcy order or the other party takes or suffers any similar or
        analogous action in consequence of debt in any jurisdiction;
      </li>
      <li>
        the other party’s financial position deteriorates so far as to
        reasonably justify the opinion that its ability to give effect to the
        terms of this Agreement is in jeopardy; and/or
      </li>
      <li>
        the other party suspends or ceases, or threatens to suspend or cease, to
        carry on all or a substantial part of its business.
      </li>
    </ol>
  </li>

  <li>
    On termination of this Agreement:
    <ol class="my-3">
      <li>
        any rights, remedies, obligations or liabilities of the parties that
        have accrued up to the date of termination, including the right to claim
        damages in respect of any breach of this Agreement which existed at or
        before the date of termination, shall not be affected;
      </li>
      <li>
        all licences granted under this Agreement shall immediately terminate;
      </li>
      <li>
        where applicable, the Game Developer shall immediately pay to the Game
        Distributor all of the Game Distributor’s outstanding unpaid invoices
        and interest and, in respect of any obligations performed by the Game
        Distributor but for which no invoice has been submitted, the Game
        Distributor shall submit an invoice, which shall be payable by the Game
        Developer immediately on receipt; and
      </li>
      <li>
        any provision of this Agreement that expressly or by implication is
        intended to come into or continue in force on or after termination of
        this Agreement shall remain in full force and effect.
      </li>
    </ol>
  </li>
</ol>

<h2 class="my-3">Limitation of Liability</h2>
<ol class="my-3">
  <li>
    Nothing in this Agreement shall limit or exclude the liability of either
    party:
    <ol class="my-3">
      <li>
        for death or personal injury caused by its negligence, or the negligence
        of its employees, agents or subcontractors (as applicable);
      </li>
      <li>for fraud or fraudulent misrepresentation; or</li>
      <li>
        for any matter in respect of which it would be unlawful to exclude or
        restrict liability.
      </li>
    </ol>
  </li>

  <li>
    Subject to clause 10.1 above:
    <ol class="my-3">
      <li>
        the Game Distributor shall under no circumstances whatsoever be liable
        to the Game Developer, whether in contract, tort (including negligence),
        breach of statutory duty, or otherwise, for:
        <ol class="my-3">
          <li>any loss of profit, sales, revenue, or business;</li>
          <li>loss of wasted expenditure;</li>
          <li>loss of anticipated savings;</li>
          <li>loss of or damage to goodwill;</li>
          <li>loss of agreements or contracts;</li>
          <li>loss of use or corruption of software, data or information;</li>
          <li>
            any loss arising out of the lawful termination of this Agreement; or
          </li>
          <li>
            any loss that is an indirect or secondary consequence of any act or
            omission of the party in question;
          </li>
        </ol>
      </li>

      <li>
        the Game Distributor’s total aggregate liability arising out of or in
        connection with all other claims in aggregate (including warranty claims
        and losses relating to the breach of warranty) shall be limited to the
        greater of:
        <ol class="my-3">
          <li>
            100 (one hundred) per cent of all amounts paid and total other sums
            payable, in aggregate, by the Game Developer to the Game Distributor
            under this Agreement in the 12 months prior to the date on which the
            claim first arose; or
          </li>
          <li>€1,000 (One thousand Euros).</li>
        </ol>
      </li>
    </ol>
  </li>
</ol>

<h2 class="my-3">Force Majeure</h2>
<ol class="my-3">
  <li>
    Neither party shall be in breach of this Agreement nor liable for delay in
    performing, or failure to perform, any of its obligations under this
    Agreement if such delay or failure results from events, circumstances or
    causes beyond its reasonable control. In such circumstances, the time for
    performance shall be extended by a period equivalent to the period during
    which performance of the obligation has been delayed or failed to be
    performed.
  </li>

  <li>
    If the period of delay or non-performance continues for 30 days, the party
    not affected may terminate this Agreement with immediate effect.
  </li>
</ol>

<h2 class="my-3">Confidentiality</h2>
<ol class="my-3">
  <li>
    Each party must keep the other party’s Confidential Information confidential
    and must not:
    <ol class="my-3">
      <li>
        use such Confidential Information except for the purpose of exercising
        or performing its rights and obligations under this Agreement; or
      </li>
      <li>
        disclose such Confidential Information in whole or in part to any third
        party, except as expressly permitted by this clause 12.
      </li>
    </ol>
  </li>

  <li>
    Each party must use adequate procedures and security measures to protect the
    other party’s Confidential Information from inadvertent disclosure or
    release to unauthorized persons.
  </li>

  <li>
    A party may disclose the other party’s Confidential Information to those of
    its employees, agents, and subcontractors who need to know such Confidential
    Information, provided that:
    <ol class="my-3">
      <li>
        it informs such employees, agents, and subcontractors of the
        confidential nature of the Confidential Information before disclosure;
        and
      </li>
      <li>
        it does so subject to obligations equivalent to those set out in this
        clause 12.
      </li>
    </ol>
  </li>

  <li>
    A party may disclose the Confidential Information of the other party to the
    extent such Confidential Information is required to be disclosed by law, by
    any governmental or other regulatory authority, or by a court or other
    authority of competent jurisdiction, provided that, to the extent it is
    legally permitted to do so:
    <ol class="my-3">
      <li>
        it gives the other party as much notice of such disclosure as possible;
        and
      </li>
      <li>
        where notice of disclosure is not prohibited and is given in accordance
        with this clause 12.4, it takes into account the reasonable requests of
        the other party in relation to the content of such disclosure.
      </li>
    </ol>
  </li>

  <li>
    The obligations of confidentiality in this clause 12 shall not extend to any
    matter which either party can show:
    <ol class="my-3">
      <li>
        is in, or has become part of, the public domain other than as a result
        of a breach of the confidentiality obligations of this Agreement;
      </li>
      <li>was independently developed by it;</li>
      <li>
        was independently disclosed to it by a third party entitled to disclose
        the same; or
      </li>
      <li>was in its written records prior to receipt.</li>
    </ol>
  </li>

  <li>
    Each party reserves all rights in its Confidential Information. No rights or
    obligations in respect of a party’s Confidential Information other than
    those expressly stated in this Agreement are granted to the other party or
    are to be implied from this Agreement.
  </li>

  <li>
    On termination of this Agreement, each party must:
    <ol class="my-3">
      <li>
        return to the other party all documents and materials (and any copies)
        containing, reflecting, incorporating, or based on the other party’s
        Confidential Information; and
      </li>
      <li>
        erase all the other party’s Confidential Information from its computer
        systems (to the extent possible).
      </li>
    </ol>
  </li>

  <li>
    The provisions of this clause 12 shall continue to apply after termination
    of this Agreement.
  </li>

  <li>
    Whilst the parties shall cooperatively draft an announcement of the Game
    being made available to Subscribers on the Platform, neither party shall
    make any such announcement without the prior written consent of the other
    party (such consent not to be unreasonably withheld, conditioned, or
    delayed).
  </li>
</ol>

<h2 class="my-3">No Partnership or Agency</h2>
<ol class="my-3">
  <li>
    Nothing in this Agreement is intended to, or shall be deemed to, establish
    any partnership or joint venture between any of the parties, constitute any
    party as the agent of another party, or authorize any party to make or enter
    into any commitments for or on behalf of any other party.
  </li>

  <li>
    Each party confirms it is acting on its own behalf and not for the benefit
    of any other person.
  </li>
</ol>

<h2 class="my-3">Variation</h2>
<p class="my-3">
  This Agreement may only be amended or varied by a document in writing signed
  by a duly authorized person on behalf of each party.
</p>

<h2 class="my-3">Assignment</h2>
<p class="my-3">
  The Game Developer may not assign, transfer, mortgage, charge, subcontract,
  declare a trust over, or deal in any other manner with any of its rights and
  obligations under this Agreement without the Game Distributor’s written
  consent.
</p>

<h2 class="my-3">No Waiver</h2>
<ol class="my-3">
  <li>
    A waiver of any right or remedy under this Agreement is only effective if
    given in writing and shall not be deemed a waiver of any subsequent breach
    or default. No failure or delay by a party to exercise any right or remedy
    provided under this Agreement or by law shall constitute a waiver of that or
    any other right or remedy, nor shall it preclude or restrict the further
    exercise of that or any other right or remedy. No single or partial exercise
    of such right or remedy shall preclude or restrict the further exercise of
    that or any other right or remedy.
  </li>
  <li>
    Except as expressly provided in this Agreement, the rights and remedies
    provided under this Agreement are in addition to, and not exclusive of, any
    rights or remedies provided by law.
  </li>
</ol>

<h2 class="my-3">Severance</h2>
<ol class="my-3">
  <li>
    If any court or competent authority finds that any provision of this
    Agreement (or part of any provision) is invalid, illegal, or unenforceable,
    that provision or part-provision shall, to the extent required, be deemed to
    be deleted, and the validity and enforceability of the other provisions of
    this Agreement shall not be affected.
  </li>
  <li>
    If any invalid, unenforceable, or illegal provision of this Agreement would
    be valid, enforceable, and legal if some part of it were deleted, the
    provision shall apply with the minimum modification necessary to make it
    legal, valid, and enforceable.
  </li>
</ol>

<h2 class="my-3">Notices</h2>
<ol class="my-3">
  <li>
    Any notice given to either party under or in connection with this Agreement
    shall be in writing, addressed to the relevant party at its registered
    office or such other address as that party may have specified to the other
    party in writing, and shall be delivered personally, sent by pre-paid
    first-class post, recorded delivery, commercial courier, or by email.
  </li>
  <li>
    A notice shall be deemed to have been received:
    <ol class="my-3">
      <li>
        if delivered personally, when left at the address referred to in clause
        18.1;
      </li>
      <li>
        if sent by pre-paid first class post or recorded delivery, at 9.00 am on
        the 2nd working day after posting;
      </li>
      <li>
        if delivered by commercial courier, on the date and at the time that the
        courier's delivery receipt is signed;
      </li>
      <li>
        if delivered by email, at the time of transmission provided that a
        confirmatory copy is sent to the other party by first class post or
        recorded delivery on the 1st working day following transmission.
      </li>
    </ol>
  </li>
  <li>
    The provisions of clause 18.1 and clause 18.2 shall not apply to the service
    of any proceedings or other documents in any legal action.
  </li>
</ol>

<h2 class="my-3">Third Party Rights</h2>
<p class="my-3">
  No one other than a party to this Agreement, their successors, and permitted
  assignees, shall have any right to enforce any of its terms.
</p>

<h2 class="my-3">Governing Law and Jurisdiction</h2>
<p class="my-3">
  This Agreement and any dispute or claim arising out of or in connection with
  it or its subject matter or formation (including non-contractual disputes or
  claims) shall be governed by and construed in accordance with the law of
  Portugal.
</p>
<p class="my-3">
  Each party irrevocably agrees that the courts of Portugal shall have exclusive
  jurisdiction to settle any dispute or claim arising out of or in connection
  with this Agreement or its subject matter or formation (including
  non-contractual disputes or claims).
</p>
