<form
  [formGroup]="resetForm"
  (ngSubmit)="onSubmit()"
  class="form m-auto w-400px h-screen flex flex-column justify-content-center"
>
  <div class="form-header mb-7">
    <h2 class="text-6xl mb-3 font-medium">Reset password</h2>
  </div>

  <div class="mb-5 relative">
    <label for="email" class="block mb-2 text-2xl text-gray-200"
      >New password</label
    >
    <p-password
      id="password"
      placeholder="Enter your password"
      formControlName="password"
      [toggleMask]="true"
      [feedback]="false"
      [appValidationMessage]="resetForm.get('password')"
      [validationMessages]="{
        required: 'Password is required',
        pattern: 'Invalid format for password'
      }"
    ></p-password>
  </div>

  <div class="mb-7 relative">
    <label for="password" class="block mb-2 text-2xl text-gray-200"
      >Repeat new password</label
    >
    <p-password
      id="passwordRepeat"
      placeholder="Repeat your password"
      formControlName="passwordRepeat"
      [toggleMask]="true"
      [feedback]="false"
      [appValidationMessage]="resetForm.get('passwordRepeat')"
      [validationMessages]="{
        required: 'Password is required',
        pattern: 'Invalid format for password'
      }"
    ></p-password>
  </div>

  <p-button
    [disabled]="isButtonDisabled"
    type="submit"
    label="Reset password"
    class="p-button-primary p-button-md w-full block mb-6"
  ></p-button>
</form>