<div class="border-right-1 border-gray-800 h-full">
  <div class="flex flex-column">
    @for (menuItem of menuItems; track $index) {
    <app-layout-sidebar-menuitem
      [alt]="menuItem.alt"
      [icon]="menuItem.icon"
      [comingSoon]="menuItem.comingSoon"
      [route]="menuItem.route"
    >
    </app-layout-sidebar-menuitem>
    }
  </div>
</div>
