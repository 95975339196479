import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-distribution-agreement-dialog',
  standalone: true,
  imports: [],
  templateUrl: './distribution-agreement-dialog.component.html',
})
export class DistributionAgreementDialogComponent {
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.config.contentStyle = {
      width: '800px',
    };
    this.config.header = 'Distribution Agreement';
  }
}
