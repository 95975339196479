<form [formGroup]="supportForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-column mb-4">
    <h3 class="text-white text-lg my-3">We are always ready to help you</h3>

    <div class="mb-3">
      <p class="text-md text-gray-200 mt-0 mb-4">
        Select the category to which your question belongs and describe it. The
        response will be sent to the provided email address.
      </p>

      <div class="flex flex-column gap-3">
        @for (item of reasons; track $index) {
        <div
          class="checkbox-wrapper flex align-items-center gap-3 text-md px-4 py-3 border-round border-1 border-gray-800"
        >
          <p-radioButton
            formControlName="subject"
            [value]="item"
            [inputId]="item"
          />

          <label [for]="item" class="cursor-pointer">
            {{ item }}
          </label>
        </div>
        }
      </div>
    </div>

    <div class="flex flex-column gap-2 mb-3">
      <label for="email" class="text-sm text-gray-200">
        The email where you will receive a response*
      </label>
      <div class="relative mb-3">
        <input
          id="email"
          type="email"
          pInputText
          formControlName="email"
          class="w-full autofill-gray-950"
          [appValidationMessage]="supportForm.get('email')"
          [validationMessages]="{
            required: 'Email is required',
            pattern: 'Invalid format for Email'
          }"
        />
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <label class="text-sm text-gray-200" for="question">Question*</label>

      <div class="relative w-full">
        <textarea
          id="question"
          class="w-full"
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="description"
          style="resize: none"
          [appValidationMessage]="supportForm.get('description')"
          [validationMessages]="{
            required: 'Question is required',
            pattern: 'Invalid format for question'
          }"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="flex gap-2 justify-content-end">
    <p-button
      type="button"
      label="Cancel"
      severity="secondary"
      (onClick)="onClose()"
    ></p-button>
    <p-button
      type="submit"
      label="Submit"
      [disabled]="supportForm.invalid"
      [loading]="loaderService.isLoading$ | async"
      severity="primary"
    ></p-button>
  </div>
</form>
