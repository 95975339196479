import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export function installationValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const gameArchive = control.get('gameArchive')?.value;
    const gameExecutable = control.get('gameExecutable')?.value;
    const gameLink = control.get('gameLink')?.value;
    const previouslyPublished = control.get('previouslyPublished')?.value;

    const isGameSelected = gameArchive && gameExecutable;
    const isLinkProvided = !!gameLink;

    if (isGameSelected || isLinkProvided || previouslyPublished) {
      return null;
    } else {
      return { gameFormInvalid: true };
    }
  };
}
