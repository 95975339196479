import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameData } from '../../../../tools/interfaces/response.interfaces';
import { NgStyle } from '@angular/common';
import { AdminService } from '../../../../shared/services/admin/admin.service';

@Component({
  selector: 'app-admin-game-links-tab',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './admin-game-links-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminGameLinksTabComponent {
  @Input() gameData!: GameData;

  constructor(public adminService: AdminService) {}
}
