@if (!loaderService.isLoading ) {
<div class="border-bottom-1 border-gray-800 py-2 mb-3">
  <div
    [ngStyle]="{ 'max-width': '1000px' }"
    class="m-auto flex justify-content-between align-items-center"
  >
    <h2 class="text-xl">Account</h2>
    <p-button
      label="Save"
      severity="primary"
      size="small"
      (onClick)="onUpdateUserInfo()"
      [disabled]="accountInfoForm.pristine"
    ></p-button>
  </div>
</div>
<div [ngStyle]="{ 'max-width': '1000px' }" class="m-auto">
  <p-tabView class="vertical-tabview">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex gap-2">
          <span class="pi pi-user"></span>
          <span>My account</span>
        </div>
      </ng-template>
      <div class="flex-1">
        <h2 class="text-2xl mt-0 mb-5 text-white">My account</h2>
        <p-avatar
          [label]="accountInfoForm.get('username')?.value?.[0]"
          class="mb-6"
          [style]="{
            'background-color': '#ece9fc',
            color: '#2a1261',
            width: '100px',
            height: '100px',
            'font-size': '3rem',
            'margin-bottom': '2rem'
          }"
        ></p-avatar>

        <form [formGroup]="accountInfoForm">
          <div class="mb-3">
            <label for="username" class="block mb-1 text-md text-gray-200">
              Username
            </label>
            <input
              id="username"
              type="username"
              placeholder="Enter your username"
              pInputText
              formControlName="username"
              class="p-input w-full"
            />
          </div>
          <div class="mb-3">
            <label for="email" class="block mb-1 text-md text-gray-200">
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              pInputText
              formControlName="email"
              class="autofill-main-black w-full"
            />
          </div>

          <div class="mb-3">
            <label for="email" class="block mb-1 text-md text-gray-200">
              Developers Tag
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              pInputText
              formControlName="displayName"
              class="p-input w-full"
            />
          </div>

          <div class="mb-4">
            <label for="address" class="block mb-1 text-md text-gray-200">
              Registered Address
            </label>
            <input
              id="address"
              formControlName="registeredAddress"
              type="text"
              placeholder="Enter your registered address"
              pInputText
              class="w-full"
            />
          </div>

          <div
            class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
          >
            <span class="text-white text-md">Change password</span>
            <p-button
              class="p-button-black p-button-sm"
              (onClick)="onResetPassword()"
              label="Change password"
              size="small"
              severity="secondary"
            >
            </p-button>
          </div>
        </form>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex gap-2">
          <span class="pi pi-trash"></span>
          <span>Delete account</span>
        </div>
      </ng-template>
      <div
        class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-3 mb-5"
      >
        <div>
          <span class="text-white text-md block line-height-4"
            >Delete account</span
          >
          <span class="text-md text-gray-400"
            >The account cannot be recovered</span
          >
        </div>
        <p-button
          (onClick)="onDeleteAccount()"
          label="Delete account"
          severity="secondary"
        >
        </p-button>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
}
