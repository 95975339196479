import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AUTH_ACTION } from '../../../tools/constants/auth.constants';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { combineLatest } from 'rxjs';
import { AccountMigrationDialogComponent } from '../../../shared/modals/account-migration-dialog/account-migration-dialog.component';
import { BasicInfoDialogComponent } from '../../../shared/modals/basic-info-dialog/basic-info-dialog.component';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-auth-callback',
  standalone: true,
  imports: [ProgressSpinnerModule, LoaderComponent],
  templateUrl: './auth-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent {
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.processAuthTokensAndAction();
  }

  private processAuthTokensAndAction(): void {
    combineLatest([this.route.paramMap, this.route.queryParams]).subscribe(
      ([paramMap, queryParams]) => {
        const action: string | null = paramMap.get('action');
        const accessToken: string | undefined = queryParams['accessToken'];
        const refreshToken: string | undefined = queryParams['refreshToken'];

        if (!accessToken || !refreshToken || !action) {
          console.error('Could not retrieve tokens or action');
          this.messageService.add({
            severity: NOTIFICATION.ERROR,
            detail: 'Could not retrieve tokens or action',
          });
          this.router.navigateByUrl('/auth');
          return;
        }

        this.handleAuthAction(action, accessToken, refreshToken);
      }
    );
  }

  private handleAuthAction(
    authAction: string,
    accessToken: string,
    refreshToken: string
  ) {
    switch (authAction) {
      case AUTH_ACTION.SIGN_IN:
      case AUTH_ACTION.SIGN_UP: {
        this.authService.handleAccessTokenResponse(accessToken, refreshToken);
        break;
      }
      case AUTH_ACTION.MIGRATE_ACCOUNT:
      case AUTH_ACTION.MIGRATE_AND_CREATE: {
        this.http
          .get(`${environment.devportalApi}/api/user/me`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .subscribe({
            next: () => {
              this.dialogService.open(AccountMigrationDialogComponent, {
                data: {
                  accessToken,
                  refreshToken,
                },
                closable: false,
              });
            },
            error: () => {
              this.dialogService.open(BasicInfoDialogComponent, {
                data: {
                  accessToken,
                  refreshToken,
                  migration: true,
                },
                closable: false,
              });
            },
          });

        break;
      }
      default: {
        console.error('Could not proceed with unspecified auth action');
        this.messageService.add({
          severity: NOTIFICATION.ERROR,
          summary: 'Could not proceed with unspecified auth action',
        });
        this.router.navigateByUrl('/auth');
      }
    }
  }
}
