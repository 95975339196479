import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../app/shared/services/loader.service';
import { inject } from '@angular/core';

let totalRequests: number = 0;

export const loaderInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const loaderService = inject(LoaderService);
  ++totalRequests;
  loaderService.showLoader();

  return next(req).pipe(
    finalize(() => {
      --totalRequests;
      if (!totalRequests) loaderService.hideLoader();
    })
  );
};
