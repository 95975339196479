<section
  [ngStyle]="{
    'background-image':
      'url(../../../assets/illustrations/file-sharing-bg.png)',
    'background-position': 'top',
    'background-repeat': 'no-repeat',
  }"
>
  <div
    class="flex flex-column gap-8 justify-content-center align-items-center"
    [ngStyle]="{ height: 'calc(100vh - 56px)' }"
  >
    <div>
      <h2 class="text-5xl text-white font-bold text-center mb-5">
        Use GAIMIN P2P file sharing <br />
        to distribute your games
      </h2>

      <p class="text-center text-xl text-white font-normal">
        GAIMIN offers a peer-to-peer file sharing for a fraction <br />
        of the cost of any other solution.
      </p>
    </div>

    <div class="flex justify-content-center">
      <div class="flex">
        <div
          *ngFor="let benefit of benefits; let last = last"
          class="flex relative px-8"
          [class.border-right-1]="!last"
          [ngStyle]="{ 'border-color': 'rgba(255, 255, 255, 0.1)' }"
        >
          <img [src]="benefit.icon" alt="" width="150" height="150" />

          <h3
            class="text-gray-100 text-base font-normal absolute text-center"
            [ngStyle]="{ bottom: '20px', right: 0, left: 0 }"
          >
            {{ benefit.text }}
          </h3>
        </div>
      </div>
    </div>
  </div>

  <section class="mx-auto pb-8" [ngStyle]="{ 'max-width': '1430px' }">
    <h2 class="font-medium text-white text-2xl mb-3">Prepaid bundles</h2>

    <p class="font-normal text-white text-lg opacity-50 mb-4">
      Companies with high message volumes can benefit
    </p>

    <div class="flex justify-content-between gap-4">
      @for(bundle of bundles; track $index){
      <article
        class="w-full px-4 pt-5 pb-6 border-round-xl relative"
        [ngStyle]="{ 'background-color': 'rgba(255, 255, 255, 0.05)' }"
      >
        <header
          class="pb-4 mb-5"
          [ngStyle]="{ 'border-bottom': '1px solid #35353f' }"
        >
          <h3
            class="font-medium text-white mb-3 text-lg"
            [class.most-popular]="bundle.mostPopular"
          >
            {{ bundle.name }}
          </h3>

          <p class="text-4xl text-white font-medium mb-5">
            @if (bundle.name !== FILE_SHARING_BUNDLE_NAME.CUSTOM) {
            {{ bundle.subscriptionPrices[0].price / 100 | currency : 'USD' : 'symbol' : '1.0-0' }}
            <span class="font-normal text-sm" [ngStyle]="{ color: '#62697c' }"
              >/&nbsp;month</span
            >
            } @else { Custom }
          </p>

          <p-button
            [severity]="bundle.mostPopular ? 'warning' : 'primary'"
            class="p-fluid"
            [label]="
              bundle.name !== FILE_SHARING_BUNDLE_NAME.CUSTOM
                ? 'Get started'
                : 'Contact Sales'
            "
            (onClick)="onCardBtnClick(bundle.name)"
          ></p-button>
        </header>

        <div>
          @if (bundle.name !== FILE_SHARING_BUNDLE_NAME.CUSTOM) {
          <h4
            [ngStyle]="{ color: '#62697c' }"
            class="uppercase font-normal text-sm mb-4"
          >
            Plan details
          </h4>

          <ul class="m-0 p-0 list-none flex flex-column gap-4">
            @for (detail of bundle.features; track $index) {
            <li class="custom-li">
              {{ detail }}
            </li>
            }
          </ul>
          } @else {
          <p class="font-medium text-wthie text-lg w-12rem line-height-2">
            Contact our sales team to arrange a custom quota and price
          </p>

          <img
            class="absolute"
            [ngStyle]="{
              bottom: 0,
              right: 0
            }"
            src="../../../assets/illustrations/card-robot.png"
            alt=""
          />
          }
        </div>
      </article>
      }
    </div>
  </section>
</section>
