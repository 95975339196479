import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AuthService } from '../../services/auth/auth.service';
import { AUTH_ACTION } from '../../../tools/constants/auth.constants';

@Component({
  selector: 'app-migration-notice-dialog',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './migration-notice-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MigrationNoticeDialogComponent {
  public readonly MIGRATE_ACCOUNT: AUTH_ACTION = AUTH_ACTION.MIGRATE_ACCOUNT;
  public readonly MIGRATE_AND_CREATE: AUTH_ACTION =
    AUTH_ACTION.MIGRATE_AND_CREATE;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public authService: AuthService
  ) {
    this.config.header = 'Important Account Upgrade';
    this.config.contentStyle = {
      width: '60vw',
      'background-color': '#18181E',
    };
  }
}
