<div class="flex flex-column h-screen overflow-hidden">
  <app-header></app-header>

  <div
    class="flex-1 overflow-y-scroll custom-scroll"
    [ngStyle]="{ height: 'calc(100vh - 63px)' }"
  >
    <router-outlet></router-outlet>
  </div>
</div>

<app-loader></app-loader>
