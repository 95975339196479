import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BodyBackgroundSetterComponent } from '../../shared/components/body-background-setter/body-background-setter.component';
import { ButtonModule } from 'primeng/button';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-add-first-game',
  templateUrl: './add-first-game.component.html',
  standalone: true,
  imports: [NgStyle, BodyBackgroundSetterComponent, ButtonModule],
})
export class AddFirstGameComponent {
  constructor(private router: Router) {}

  addFirstGame(): void {
    this.router.navigateByUrl('/app/new-game');
  }
}
