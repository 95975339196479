import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-referral-dialog',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './referral-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralDialogComponent {
  constructor(private config: DynamicDialogConfig) {
    this.config.header = 'Your GAIMIN Platform account needs to be migrated to the GAIMIN Auth';
    this.config.contentStyle = {
      width: '750px',
    };
  }
}
