import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GameRequestsService } from '../../services/games/game-requests.service';

@Component({
  selector: 'app-request-unpublish',
  standalone: true,
  imports: [NgStyle, ButtonModule],
  templateUrl: './request-unpublish.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestUnpublishComponent {
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private gameRequestService: GameRequestsService
  ) {
    this.config.header = 'Unpublish game';
    this.config.contentStyle = {
      width: '770px',
    };
  }

  onCancelClick() {
    this.ref.close();
  }

  requestUnpublish() {
    this.gameRequestService
      .requestUnpulish(this.config.data.gameId)
      .subscribe(() => {
        this.config.data.initGameData();
        this.ref.close();
      });
  }
}
