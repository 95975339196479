<div>
  <h2 class="text-2xl mt-0 mb-5 text-white">My account</h2>
  <p-avatar
    [label]="accountInfoForm.get('username')?.value?.[0]"
    class="mb-6"
    [style]="{
      'background-color': '#ece9fc',
      color: '#2a1261',
      width: '100px',
      height: '100px',
      'font-size': '3rem',
      'margin-bottom': '2rem'
    }"
  ></p-avatar>

  <form [formGroup]="accountInfoForm">
    <h2 class="text-xl text-white font-medium mb-5">Company Information</h2>

    <div class="mb-4 relative">
      <label for="companyName" class="block mb-1 text-md text-gray-200">
        Company Name
      </label>
      <input
        id="companyName"
        formControlName="companyName"
        type="text"
        placeholder="Enter your company address"
        pInputText
        class="w-full"
        [appValidationMessage]="accountInfoForm.controls['companyName']"
        [validationMessages]="{
          pattern: 'Invalid format for company name',
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="address" class="block mb-1 text-md text-gray-200">
        Company Address
      </label>
      <input
        id="address"
        formControlName="registeredAddress"
        type="text"
        placeholder="Enter your company address"
        pInputText
        class="w-full"
        [appValidationMessage]="accountInfoForm.controls['registeredAddress']"
        [validationMessages]="{
          pattern: 'Invalid format for company address',
        }"
      />
    </div>

    <h2 class="text-xl text-white font-medium mb-5">Personal Information</h2>

    <div class="mb-4 relative">
      <label for="username" class="block mb-1 text-md text-gray-200">
        Username
      </label>
      <input
        id="username"
        type="text"
        placeholder="Enter your username"
        pInputText
        formControlName="username"
        class="p-input w-full"
        [appValidationMessage]="accountInfoForm.controls['username']"
        [validationMessages]="{
          pattern: 'Invalid format for username',
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="email" class="block mb-1 text-md text-gray-200">
        Developer's Tag
      </label>
      <input
        id="displayName"
        type="displayName"
        placeholder="Enter your developer's tag"
        pInputText
        formControlName="displayName"
        class="p-input w-full"
        [appValidationMessage]="accountInfoForm.controls['email']"
        [validationMessages]="{
          pattern: 'Invalid format for Developer\'s tag'
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="firstName" class="block mb-1 text-md text-gray-200">
        First name*
      </label>
      <input
        id="firstName"
        type="text"
        placeholder="Enter your first name"
        pInputText
        formControlName="firstName"
        class="p-input w-full"
        [appValidationMessage]="accountInfoForm.controls['firstName']"
        [validationMessages]="{
          required: 'First name is required',
          pattern: 'Invalid format for first name',
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="lastName" class="block mb-1 text-md text-gray-200">
        Last name*
      </label>
      <input
        id="lastName"
        type="text"
        placeholder="Enter your last name"
        pInputText
        formControlName="lastName"
        class="p-input w-full"
        [appValidationMessage]="accountInfoForm.controls['lastName']"
        [validationMessages]="{
          required: 'Last name is required',
          pattern: 'Invalid format for last name',
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="email" class="block mb-1 text-md text-gray-200">
        Email
      </label>
      <input
        id="email"
        type="email"
        placeholder="Enter your email"
        pInputText
        formControlName="email"
        class="autofill-main-black w-full"
        [appValidationMessage]="accountInfoForm.controls['email']"
        [validationMessages]="{
          email: 'Invalid format for email'
        }"
      />
    </div>

    <div class="mb-4 relative">
      <label for="phoneNumber" class="block mb-1 text-md text-gray-200">
        Mobile number
      </label>
      <p-inputMask
        id="phoneNumber"
        formControlName="phoneNumber"
        mask="+99 (999) 999-9999"
        placeholder="Enter your mobile number"
        class="p-fluid w-full"
      />
    </div>

    <div class="mb-4 relative">
      <label for="country" class="block mb-1 text-md text-gray-200">
        Country
      </label>
      <input
        id="country"
        type="country"
        placeholder="Enter your country"
        pInputText
        formControlName="country"
        class="autofill-main-black w-full"
        [appValidationMessage]="accountInfoForm.controls['country']"
        [validationMessages]="{
          pattern: 'Invalid format for country'
        }"
      />
    </div>

    <div
      class="flex justify-content-between align-items-center border-1 border-gray-800 border-round-xl px-3 py-2 mb-5"
    >
      <span class="text-white text-md">Change password</span>
      <p-button
        class="p-button-black p-button-sm"
        (onClick)="onResetPassword()"
        label="Change password"
        size="small"
        severity="secondary"
      >
      </p-button>
    </div>
  </form>
</div>
