<div class="h-screen overflow-y-scroll custom-scroll">
  <div
    [ngStyle]="{
      'background-image':
        'url(../../../../assets/illustrations/gradient-bg.png)',
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }"
  >
    <div
      [ngStyle]="{
        'max-width': '1260px',
        margin: '0 auto',
      }"
      class="h-screen p-4 flex flex-column"
    >
      <header class="flex justify-content-between mb-1">
        <div class="flex gap-5 align-items-center">
          <div>
            <a routerLink="/start" class="flex">
              <img src="../../../../assets/icons/logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="flex gap-4">
          <p-button
            label="Migration info"
            severity="primary"
            size="small"
            (onClick)="onMigrationInfoClick()"
          ></p-button>

          <div
            class="flex flex-column justify-content-center align-items-center gap-2"
          >
            <p-button
              label="Sign in"
              severity="primary"
              size="small"
              (onClick)="authService.authActionRedirect(SIGN_IN)"
            ></p-button>

            <div
              class="flex flex-column justify-content-center align-items-center"
            >
              <p class="text-gray-100 text-sm">Don't have an account?</p>
              <span
                class="w-max cursor-pointer font-medium text-primary custom-underline"
                (click)="authService.authActionRedirect(SIGN_UP)"
                >Sign up</span
              >
            </div>
          </div>
        </div>
      </header>

      <div
        class="flex flex-column align-items-center justify-content-center flex flex-grow-1"
      >
        <p
          [ngStyle]="{
            'border-radius': '100px',
            'background-color': 'rgba(0, 0, 0, 0.2)',
          }"
          class="uppercase text-center text-white text-xl font-medium px-4 py-3 mb-8"
        >
          Secure. Scalable. Affordable… for individuals and businesses.
        </p>

        <p class="text-center font-medium text-6xl mb-6 text-center">
          Peer-to-Peer File Sharing <br />
          utilizing GAIMIN’s DePIN
        </p>

        <p class="text-white text-2xl mb-6 text-center">
          Distribute your files anywhere globally at a lower cost than <br />
          Google, AWS, and Azure
        </p>

        <p-button
          label="Contact sales"
          severity="primary"
          size="large"
          (onClick)="onContactSalesClick()"
        ></p-button>
      </div>
    </div>
  </div>

  <div
    [ngStyle]="{
      'max-width': '1260px',
      margin: '0 auto',
    }"
  >
    <section
      class="border-round-xl relative mb-2"
      [ngStyle]="{
        border: '1px solid rgba(255, 255, 255, 0.13)',
        padding: '40px 110px 40px 60px',
        background: 'linear-gradient(221deg, rgba(52, 78, 86, 0.24) 0%, rgba(29, 30, 37, 0.24) 100%)'
      }"
    >
      <p class="text-white relative line-height-3">
        Your business demands fast, secure, and reliable file sharing but you
        are paying the high costs from Google, AWS and Azure! GAIMIN’s P2P
        file-sharing service lets you share your files through our global DePIN
        at a much lower cost than Google, AWS and Azure. And, we pay up to 90%
        of what you pay us back to our DePIN community. You not only get the
        best service for a fraction of the cost of other traditional solutions,
        but also the fees you pay help make a greater impact on our community!

        <img
          class="absolute"
          src="../../../assets/icons/stars.svg"
          alt=""
          [ngStyle]="{
            top: 0,
            left: '-40px'
          }"
        />
      </p>

      <img
        class="absolute"
        src="../../../assets/illustrations/marketing/robot.png"
        alt=""
        [ngStyle]="{
          top: 0,
          right: '-10px'
        }"
      />
    </section>

    <app-calculator></app-calculator>

    <section>
      <h2 class="font-bold text-4xl mb-4">How it works</h2>

      <div class="grid mb-8">
        @for (advantage of advantagesContent; track $index) {
        <div class="col-4">
          <article
            class="p-5 flex flex-column justify-content-end border-round-xl"
            [ngStyle]="{
              height: '360px',
              'background-image': advantage.backgroundUrl,
              'background-repeat': 'no-repeat',
              'background-position': 'top right',
              border: '1px solid rgba(255, 255, 255, 0.13)'
            }"
          >
            <h2 class="font-bold text-xl mb-4 relative">
              {{ advantage.title }}

              @if (advantage.hasTitleIcon) {
              <img
                class="absolute"
                src="../../../assets/icons/stars.svg"
                alt=""
                [ngStyle]="{
                  left: 0,
                  top: '-50px'
                }"
              />
              }
            </h2>

            <p [ngStyle]="{ height: '90px' }" [innerHTML]="advantage.text"></p>
          </article>
        </div>
        }
      </div>
    </section>
  </div>

  <section
    [ngStyle]="{
      'background-image':
        'url(../../../../assets/illustrations/marketing/bg.png)',
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }"
    class="pb-8"
  >
    <div
      [ngStyle]="{
        'max-width': '1260px',
        margin: '0 auto'
      }"
    >
      <h2 class="font-bold text-4xl mb-4">Why GAIMIN P2P File Sharing?</h2>

      <div class="flex flex-wrap gap-5 justify-content-between mb-6">
        @for (benefit of benefitsContent; track $index) {
        <article [ngStyle]="{ 'max-width': '47%' }">
          <h3 class="font-bold text-xl mb-4">{{ benefit.title }}</h3>

          <p>{{ benefit.text }}</p>
        </article>
        }
      </div>

      <img
        src="../../../assets/illustrations/marketing/p2p_file_sharing.png"
        alt=""
        class="w-full"
      />
    </div>
  </section>

  <app-products-footer></app-products-footer>
</div>
