<h2 class="text-white text-2xl my-3">{{ modalTitle }}</h2>

<p class="text-gray-200 text-lg line-height-3 mb-4">{{ modalDescription }}</p>

<p class="text-gray-200 text-lg line-height-3 mb-5">
  Type game name to proceed.
</p>

<form [formGroup]="removeGameForm">
  <div class="mb-5">
    <input
      type="text"
      [placeholder]="gameTitle"
      pInputText
      formControlName="confirmation"
      class="p-input w-full"
    />
  </div>

  <div class="flex gap-2 justify-content-end">
    <p-button
      severity="secondary"
      label="Cancel"
      (onClick)="ref.close()"
    ></p-button>
    <p-button
      [label]="modalConfirmButtonText"
      [disabled]="removeGameForm.invalid"
      severity="danger"
      (onClick)="removeGame()"
    ></p-button>
  </div>
</form>
