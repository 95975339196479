import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CanActivateFn, Router } from '@angular/router';
import { NetworkService } from '../services/network.service';
import { MessageService } from 'primeng/api';
import { NOTIFICATION } from '../../tools/constants/notification.constants';

export const OnlineGuard: CanActivateFn = (): Observable<boolean> => {
  const networkService: NetworkService = inject(NetworkService);
  const messageService: MessageService = inject(MessageService);
  const router: Router = inject(Router);

  return networkService.isOnline$.pipe(
    tap({
      next: (online: boolean) => {
        if (!online) {
          notifyNetworkError(messageService);
          router.navigateByUrl('/app/home');
        }
      },
    })
  );
};

function notifyNetworkError(messageService: MessageService): void {
  messageService.add({
    severity: NOTIFICATION.ERROR,
    summary: 'Network error',
    detail: 'There are some problems with internet connection',
  });
}
