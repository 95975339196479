import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { environment } from '../../../../environments/environment';
import { GamesService } from '../../services/games/games.service';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { GameRequestsService } from '../../services/games/game-requests.service';

@Component({
  selector: 'app-game-removal-confirmation-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonModule, InputTextModule],
  templateUrl: './game-removal-confirmation-modal.component.html',
})
export class GameRemovalConfirmationModalComponent {
  removeGameForm: FormGroup = this.fb.group({
    confirmation: ['', [Validators.required, this.matchRemoveGameValidator()]],
  });

  modalTitle: string = '';
  modalDescription: string = '';
  modalConfirmButtonText: string = '';

  constructor(
    private config: DynamicDialogConfig,
    private fb: FormBuilder,
    private http: HttpClient,
    private gamesService: GamesService,
    private notificationService: NotificationService,
    public ref: DynamicDialogRef,
    private gameRequestsService: GameRequestsService
  ) {
    this.config.styleClass = 'bg-gray-1000';
    this.config.contentStyle = {};
  }

  ngOnInit(): void {
    this.updateModalText(this.gameStatus);
  }

  get gameTitle(): string {
    return this.config.data.title;
  }

  get gameId(): number {
    return this.config.data.id;
  }

  get gameStatus(): GAME_STATUS {
    return this.config.data.status;
  }

  private matchRemoveGameValidator(): (
    control: AbstractControl
  ) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === this.gameTitle ? null : { mismatch: true };
    };
  }

  private updateModalText(status: GAME_STATUS): void {
    const modalData = this.getModalDataByStatus(status);
    this.config.header = modalData.header;
    this.modalTitle = modalData.title;
    this.modalDescription = modalData.description;
    this.modalConfirmButtonText = modalData.confirmButtonText;
  }

  private getModalDataByStatus(status: GAME_STATUS) {
    switch (status) {
      case GAME_STATUS.REVIEW:
        return {
          header: `Cancel ${this.gameTitle} review`,
          title: 'Are you sure you want to cancel game review?',
          description:
            'Once the action is completed, the game will be moved to Draft status, and you will have the option to resubmit it.',
          confirmButtonText: 'Cancel review',
        };
      case GAME_STATUS.PUBLISHED:
        return {
          header: `Unpublish ${this.gameTitle}`,
          title: 'Are you sure you want to unpublish the game?',
          description:
            'This process requires some time to ensure that the user experience on the GAIMIN platform remains unaffected.',
          confirmButtonText: 'Request unpublish',
        };
      case GAME_STATUS.REJECTED:
      case GAME_STATUS.DRAFT:
      case GAME_STATUS.READY_FOR_PUBLISH:
      case GAME_STATUS.CHANGES_REQUESTED:
        return {
          header: `Delete ${this.gameTitle}`,
          title: 'Are you sure you want to delete the game?',
          description:
            'This game and all associated data will be deleted. This action is permanent and irreversible.',
          confirmButtonText: 'Delete game',
        };
      default:
        throw new Error('Unknown game status appeared');
    }
  }

  removeGame(): void {
    switch (this.gameStatus) {
      case GAME_STATUS.REVIEW: {
        this.http;
        this.gameRequestsService.requestCancel(this.gameId).subscribe({
          next: () => {
            this.handleGameActionSuccess(this.gameStatus);
          },
        });
        break;
      }
      case GAME_STATUS.PUBLISHED: {
        this.gameRequestsService.requestUnpulish(this.gameId).subscribe({
          next: () => {
            this.handleGameActionSuccess(this.gameStatus);
          },
        });
        break;
      }
      default: {
        this.http
          .delete<unknown>(
            `${environment.devportalApi}/api/game/${this.gameId}/delete`,
            { body: {} }
          )
          .subscribe({
            next: () => {
              this.handleGameActionSuccess(this.gameStatus);
            },
          });
      }
    }
  }

  private handleGameActionSuccess(status: GAME_STATUS): void {
    this.gamesService.getUsersGames().subscribe({
      next: () => {
        const notificationMessage: string =
          this.getSuccessMessageByStatus(status);
        this.notificationService.throwSuccessNotification(notificationMessage);
        this.ref.close();
      },
    });

    this.config.data?.onRemoval?.();
  }

  private getSuccessMessageByStatus(status: GAME_STATUS): string {
    switch (status) {
      case GAME_STATUS.REVIEW:
        return 'Game review was successfully cancelled!';
      case GAME_STATUS.PUBLISHED:
        return 'Unpublish request was successfully sent!';
      default:
        return 'Game successfully deleted';
    }
  }
}
