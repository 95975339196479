<div
  class="flex justify-content-between px-4 py-3 border-bottom-1 border-gray-800 no-print"
>
  <div>
    <img
      src="assets/icons/app-icon.svg"
      alt="App Icon"
      class="w-5rem cursor-pointer"
      routerLink="/app/home"
    />
  </div>
  <div class="flex gap-3 align-items-center">
    @if((breakpointService.currentRange$ | async) !== SCREEN_SIZE.XSMALL) {
    <img
      src="./assets/icons/question-icon.svg"
      alt=""
      class="cursor-pointer"
      pTooltip="Support"
      [tooltipOptions]="tooltipOptions"
      (click)="openSupportDialog()"
    />

    <hr
      class="border-left-1 border-right-0 h-full"
      [ngStyle]="{ 'border-color': '#22262F' }"
    />
    }

    <img
      src="./assets/icons/account-icon.svg"
      alt=""
      class="cursor-pointer"
      pTooltip="Account"
      [tooltipOptions]="tooltipOptions"
      (click)="op.toggle($event)"
    />

    <p-overlayPanel #op>
      <div class="flex flex-column gap-3">
        <span
          class="cursor-pointer hover:text-gray-300 transition-duration-100"
          (click)="onOpenSettings()"
          >Account settings</span
        >
        <span
          class="cursor-pointer hover:text-gray-300 transition-duration-100"
          (click)="onSignOut()"
          >Sign out</span
        >
      </div>
    </p-overlayPanel>
  </div>
</div>
