<ng-container *ngIf="gameData.sourceLink; else downloadTemplate">
  <h3 class="font-medium text-4xl text-white mb-3">External Link</h3>
  <p class="font-normal text-md text-gray-500 mb-4">
    Go to external resource (browser-based game)
  </p>
  <p-button
    label="External Link"
    (onClick)="adminService.onRedirect(gameData.sourceLink.url)"
  ></p-button>
</ng-container>

<ng-template #downloadTemplate>
  <ng-container *ngIf="gameDownloadLink$ | async as response">
    <h3 class="font-medium text-4xl text-white mb-3">Download Link</h3>
    <p class="font-normal text-md text-gray-500 mb-4">
      Link to download the game
    </p>
    <p-button
      label="Download Game"
      (onClick)="onDownloadGame(response.url)"
    ></p-button>
  </ng-container>
</ng-template>
