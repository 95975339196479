@if (isConnectedToReferral !== null && isAccountMigrated !== null) { @if
(isConnectedToReferral && isAccountMigrated) {
<div class="py-4" [ngStyle]="{ margin: '0 auto', 'max-width': '1280px' }">
  <app-referral-invite></app-referral-invite>
  <app-referral-statistic [userNep]="userNep"></app-referral-statistic>
  @if (isOverviewDataLoaded) {
  <p-tabView>
    <p-tabPanel header="Overview">
      <ng-template pTemplate="content">
        <app-referral-overview
          [overviewData]="overviewData"
        ></app-referral-overview>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  }
</div>
} @else {
<app-referral-start
  (connectedToRefferal)="onConnectedToReferral()"
></app-referral-start>
} }
