import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SalesRequest } from '../../tools/dto/request.dto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  public contactSalesRequest(body: SalesRequest) {
    return this.http.post(
      `${environment.devportalApi}/api/client/request/file-sharing`,
      body
    );
  }
}
