import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GameData } from '../../tools/interfaces/response.interfaces';
import {
  catchError,
  finalize,
  from,
  mergeMap,
  Observable,
  switchMap,
  tap,
  throwError,
  toArray,
} from 'rxjs';
import { LoaderService } from './loader.service';
import { WakeLockService } from './wake-lock.service';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private games: GameData[] = [];

  // TODO: cleanup
  get gamesList(): GameData[] {
    return this.games.slice();
  }

  set gamesList(gameData: GameData[]) {
    this.games = gameData;
  }

  getGameByIdRequest(id: number): Observable<GameData> {
    return this.http.get<GameData>(
      `${environment.devportalApi}/api/game/${id}`
    );
  }

  getGameById(id: number): GameData | null {
    return this.games.find((game) => game.id === id) || null;
  }

  getUsersGames(): Observable<GameData[]> {
    return this.http
      .get<GameData[]>(`${environment.devportalApi}/api/game`)
      .pipe(
        tap((res: GameData[]) => {
          this.gamesList = res;
        })
      );
  }

  getReviewFeedback(id: number): Observable<{ review: string }> {
    return this.http.get<{ review: string }>(
      `${environment.devportalApi}/api/game/review/feedback/${id}`
    );
  }

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private wakeLockService: WakeLockService
  ) {
    this.initializeListeners();
  }

  initializeListeners(): void {}

  addNewGame({ gameTitle, gameIcon }: { gameTitle: string; gameIcon: File }) {
    const formData = new FormData();

    formData.append('icon', gameIcon);

    formData.append(
      'request',
      new Blob(
        [
          JSON.stringify({
            title: gameTitle,
          }),
        ],
        { type: 'application/json' }
      )
    );

    return this.http
      .put<GameData>(
        `${environment.devportalApi}/api/game/create-or-update`,
        formData
      )
      .pipe(
        tap((res) => {
          this.gamesList = [...this.games, res];
        })
      );
  }

  uploadGameImages(
    images: File[] | Uint8Array[],
    gameId: number
  ): Observable<string[]> {
    const formData = new FormData();

    images.forEach((image) => {
      if (image instanceof File) {
        formData.append('files', image);
      } else if (image instanceof Uint8Array) {
        formData.append(
          'files',
          new Blob([image], {
            type: 'image/png',
          })
        );
      }
    });

    return this.http.put<string[]>(
      `${environment.devportalApi}/api/storage/public/game/${gameId}/images`,
      formData
    );
  }

  uploadGameArchiveByChunks(
    gameId: number,
    gameArchive: File,
    relativeGameExePath: string
  ) {
    const { chunks: archiveChunks, chunksQuantity } =
      this.getFileChunks(gameArchive);
    const parts: { eTag: string | null; partNumber: number }[] = new Array(
      chunksQuantity
    ).fill(null);

    return this.initializeMultipartUpload(
      gameId,
      chunksQuantity,
      relativeGameExePath
    ).pipe(
      switchMap(({ uploadId, urls }) => {
        this.wakeLockService.requestWakeLock();
        this.loaderService.updateLoaderMessage(
          'Preparing your game to upload...',
          0
        );

        return this.uploadChunks(
          urls,
          archiveChunks,
          parts,
          chunksQuantity
        ).pipe(
          switchMap(() => this.completeMultipartUpload(gameId, uploadId, parts))
        );
      }),
      finalize(() => {
        this.loaderService.setMessage('Upload completed!');
        this.loaderService.setPercentage(100);
      }),
      catchError((error) => {
        this.loaderService.setMessage('Upload failed.');
        this.loaderService.setPercentage(0);
        this.wakeLockService.releaseWakeLock();
        return throwError(() => error);
      })
    );
  }

  private initializeMultipartUpload(
    gameId: number,
    chunksQuantity: number,
    relativeGameExePath: string
  ) {
    return this.http.put<{ uploadId: string; urls: { url: string }[] }>(
      `${environment.devportalApi}/api/storage/private/game/${gameId}/archive/multipart-upload/init`,
      {
        partCount: chunksQuantity,
        manifest: {
          pathToExecutable: relativeGameExePath,
          includePlayerToken: false,
          runFlags: [],
        },
      }
    );
  }

  private uploadChunks(
    urls: { url: string }[],
    archiveChunks: Blob[],
    parts: { eTag: string | null; partNumber: number }[],
    chunksQuantity: number
  ) {
    let loadedChunksCount: number = 0;

    return from(urls).pipe(
      mergeMap(
        (chunkUploadUrl, index) =>
          this.uploadChunk(
            chunkUploadUrl.url,
            archiveChunks[index],
            index
          ).pipe(
            tap((response: HttpResponse<any>) => {
              parts[index] = {
                eTag: response.headers.get('ETag'),
                partNumber: index + 1,
              };

              loadedChunksCount++;
              this.loaderService.updateLoaderMessage(
                `Loading chunks: ${loadedChunksCount} out of ${chunksQuantity}`,
                (loadedChunksCount / chunksQuantity) * 100
              );
            })
          ),
        3
      ),
      toArray()
    );
  }

  private completeMultipartUpload(
    gameId: number,
    uploadId: string,
    parts: { eTag: string | null; partNumber: number }[]
  ) {
    this.wakeLockService.releaseWakeLock();
    return this.http.put(
      `${environment.devportalApi}/api/storage/private/game/${gameId}/archive/multipart-upload/complete`,
      { uploadId, parts }
    );
  }

  private uploadChunk(url: string, chunk: Blob, index: number) {
    return this.http.put(url, chunk, { observe: 'response' }).pipe(
      catchError((error) => {
        this.loaderService.setMessage(`Failed to upload chunk ${index + 1}`);
        return throwError(() => error);
      })
    );
  }

  private getFileChunks(file: File): {
    chunks: Blob[];
    chunksQuantity: number;
  } {
    const chunkSize: number = 50 * 1024 * 1024; // 50mb
    const chunks: Blob[] = [];
    const chunksQuantity: number = Math.ceil(file.size / chunkSize);

    for (let i: number = 0; i < chunksQuantity; i++) {
      const start: number = i * chunkSize;
      const end: number = Math.min(start + chunkSize, file.size);
      const chunk: Blob = file.slice(start, end);
      chunks.push(chunk);
    }

    return {
      chunks,
      chunksQuantity,
    };
  }

  getGameDownloadLink(gameId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      `${environment.devportalApi}/api/game/${gameId}/download-link`
    );
  }
}
