import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from '../../../../shared/modals/support-dialog/support-dialog.component';
import { SidebarModule } from 'primeng/sidebar';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-layout-mobile-sidebar',
  standalone: true,
  imports: [RouterModule, SidebarModule],
  templateUrl: './layout-mobile-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutMobileSidebarComponent {
  isMobileSidebarVisible: boolean = false;

  constructor(private dialogService: DialogService) {}

  onOpenSupportCenter(): void {
    this.dialogService.open(SupportDialogComponent, {});
  }
}
