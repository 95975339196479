import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../../services/auth/auth.service';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { TEXT } from '../../../tools/constants/pattern.constants';
import { AuthRequestsService } from '../../services/auth/auth-requests.service';
import { AccountMigrationDialogComponent } from '../account-migration-dialog/account-migration-dialog.component';
import { AUTH_ACTION } from '../../../tools/constants/auth.constants';

@Component({
  selector: 'app-basic-info-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    InputTextModule,
  ],
  templateUrl: './basic-info-dialog.component.html',
})
export class BasicInfoDialogComponent {
  form: FormGroup = this.fb.group({
    developerTag: ['', [Validators.required, Validators.pattern(TEXT)]],
    registeredAddress: ['', [Validators.required, Validators.pattern(TEXT)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private authService: AuthService,
    private authRequestsService: AuthRequestsService,
    private dialogService: DialogService
  ) {
    this.config.header = 'Add your basic information';
    this.config.contentStyle = {
      'max-width': '448px',
      'background-color': '#18181E',
    };
  }

  get accessToken(): string {
    return this.config.data.accessToken;
  }

  get refreshToken(): string {
    return this.config.data.refreshToken;
  }

  get isMigrating(): boolean {
    const authAction: AUTH_ACTION = this.config.data.authAction;
    return (
      authAction === AUTH_ACTION.MIGRATE_ACCOUNT ||
      authAction === AUTH_ACTION.MIGRATE_AND_CREATE
    );
  }

  onSubmit(): void {
    const { developerTag, registeredAddress } = this.form.value;

    this.authRequestsService
      .signUpRequest(developerTag, registeredAddress, this.accessToken)
      .subscribe({
        next: () => {
          this.ref.close();

          if (this.isMigrating) {
            this.dialogService.open(AccountMigrationDialogComponent, {
              data: {
                accessToken: this.accessToken,
                refreshToken: this.refreshToken,
              },
              closable: false,
            });
          } else {
            this.authService.authorizeUser(this.accessToken, this.refreshToken);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
