<div class="pt-4 flex flex-column align-items-center justify-content-center">
  <h2 class="mb-3">
    To participate in the Referral Program please migrate your<br />
    GAIMIN platform account following these steps
  </h2>

  <ol>
    <li class="text-xl">
      Download the platform using
      <a
        class="text-primary font-normal no-underline hover:text-primary-300"
        href="https://www.gaimin.gg/download-thank-you"
        target="_blank"
        >this link</a
      >.
    </li>
    <li class="text-xl">Install and launch the platform.</li>
    <li class="text-xl">
      Login or sign up to your account with current credentials.
    </li>
    <li class="text-xl">
      Follow the instructions in the platform modal to complete the migration.
    </li>
    <li class="text-xl">Return to the referral page and click "Join"</li>
  </ol>
</div>
