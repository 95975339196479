import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { LicenseAgreementDialogComponent } from '../../modals/license-agreement-dialog/license-agreement-dialog.component';
import { PrivacyPolicyDialogComponent } from '../../modals/privacy-policy-dialog/privacy-policy-dialog.component';

@Component({
  selector: 'app-products-footer',
  standalone: true,
  imports: [NgStyle],
  styles: [
    `.coming-soon {
      display: flex;
      align-items: center;
      gap: 8px;

      &::after {
        content: 'SOON';
        font-weight: 600;
        font-size: 11px;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
        background-color: #373744;
      }
    }`,
  ],
  templateUrl: './products-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsFooterComponent {
  constructor(private dialogService: DialogService) {}

  gaiminLinks = [
    {
      title: 'GAIMIN',
      links: [
        {
          description: 'About Us',
          link: 'https://www.gaimin.io/about',
          isComingSoon: false,
        },
        {
          description: 'Roadmap',
          link: 'https://www.gaimin.io/roadmap',
          isComingSoon: false,
        },
        {
          description: 'Platform',
          link: 'https://www.gaimin.io/platform',
          isComingSoon: false,
        },
        {
          description: 'Blog',
          link: 'https://www.gaimin.io/blog',
          isComingSoon: false,
        },
        {
          description: 'Contact Us',
          link: 'https://www.gaimin.io/contact-us',
          isComingSoon: false,
        },
      ],
    },
    {
      title: 'Products',
      links: [
        {
          description: 'GAIMIN Platform',
          link: 'https://www.gaimin.gg/',
          isComingSoon: false,
        },
        {
          description: 'GAIMIN Gladiators',
          link: 'https://colosseum.gaimingladiators.gg/',
          isComingSoon: false,
        },
        {
          description: 'GME PRO',
          link: '',
          isComingSoon: true,
        },
      ],
    },
    {
      title: 'Resources',
      links: [
        {
          description: 'Developers',
          link: 'https://www.gaimin.io/developer',
          isComingSoon: false,
        },
        {
          description: 'Token',
          link: 'https://www.gaimin.io/token',
          isComingSoon: false,
        },
        {
          description: 'Documents',
          link: 'https://www.gaimin.io/documents',
          isComingSoon: false,
        },
        {
          description: 'Press',
          link: 'https://www.gaimin.io/press',
          isComingSoon: false,
        },
        {
          description: 'Careers',
          link: 'https://www.gaimin.io/careers',
          isComingSoon: false,
        },
      ],
    },
  ];
  socialLinks = [
    {
      title: 'Community',
      links: [
        {
          description: 'Telegram',
          link: 'https://t.me/+XFTC523WeTI1MjA0',
          isComingSoon: false,
        },
        {
          description: 'Facebook',
          link: 'https://www.facebook.com/Gaimin.io/',
          isComingSoon: false,
        },
        {
          description: 'Instagram',
          link: 'https://www.instagram.com/gaimin_io/',
          isComingSoon: false,
        },
        {
          description: 'Twitter (X)',
          link: 'https://x.com/GaiminIo',
          isComingSoon: false,
        },
        {
          description: 'YouTube',
          link: 'https://www.youtube.com/channel/UCp9Y_FMB1p4Nqjct0Ro9f3g',
          isComingSoon: false,
        },
        {
          description: 'LinkedIn',
          link: 'https://www.linkedin.com/company/gaimin/',
          isComingSoon: false,
        },
        {
          description: 'Discord',
          link: 'https://discord.com/invite/jemqJ9PkCJ',
          isComingSoon: false,
        },
        {
          description: 'TikTok',
          link: 'https://www.tiktok.com/@gaimin.gg?lang=en',
          isComingSoon: false,
        },
      ],
    },
  ];

  onEulaOpen(event: Event): void {
    event.preventDefault();
    this.dialogService.open(LicenseAgreementDialogComponent, {});
  }

  onPrivacyPolicyOpen(event: Event): void {
    event.preventDefault();
    this.dialogService.open(PrivacyPolicyDialogComponent, {});
  }
}
