import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import {
  EMAIL,
  NAME,
  PHONE_NUMBER,
  TEXT,
} from '../../../tools/constants/pattern.constants';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { EmailService } from '../../services/email.service';
import { AuthRequestsService } from '../../services/auth/auth-requests.service';
import { InputMaskModule } from 'primeng/inputmask';

@Component({
  selector: 'app-contact-sales',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    InputTextModule,
    InputMaskModule
  ],
  templateUrl: './contact-sales.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactSalesComponent implements OnInit {
  public isMessageSent = false;

  contactSalesForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(NAME)]],
    companyName: ['', [Validators.required, Validators.pattern(TEXT)]],
    email: ['', [Validators.required, Validators.pattern(EMAIL)]],
    phoneNumber: ['', [Validators.required, Validators.pattern(PHONE_NUMBER)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private emailService: EmailService,
    private authRequestsService: AuthRequestsService,
    private cdr: ChangeDetectorRef
  ) {
    this.config.header = 'Contact Sales';
    this.config.contentStyle = {
      width: '500px',
    };
  }
  ngOnInit(): void {
    this.getCurrentUserInfo();
  }

  private getCurrentUserInfo() {
    return this.authRequestsService
      .getUserInfoRequest()
      .subscribe((userInfo) => {
        const { gaiminAuthUser, companyName, phoneNumber } = userInfo;
        const { email, firstName, lastName } = gaiminAuthUser;

        this.contactSalesForm.patchValue({
          companyName,
          name: `${firstName} ${lastName}`,
          email,
          phoneNumber,
        });

        this.cdr.detectChanges();
      });
  }

  public onSubmit(): void {
    const requestBody = this.contactSalesForm.value;

    this.emailService.contactSalesRequest(requestBody).subscribe((res) => {
      this.isMessageSent = true;
    });
  }

  public closeModal(): void {
    this.ref.close();
  }
}
