<div class="m-auto w-400px">
  <div class="mb-6">
    <h2 class="text-4xl mb-2 font-medium">Sign in</h2>
    <span class="text-md font-normal text-gray-400">
      Don't have an account?
    </span>
    <a
      routerLink="/auth/signup"
      class="text-primary text-md font-normal no-underline hover:text-primary-300"
    >
      Sign up
    </a>
  </div>

  <p-button
    label="Sign in"
    severity="primary"
    class="p-fluid mb-5 block"
    (onClick)="authService.authActionRedirect(SIGN_IN)"
  ></p-button>

  <span class="text-gray-400 flex gap-3 align-items-center mb-5 text-md">
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
    <span>Or continue with</span>
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
  </span>

  <div>
    <p-button
      severity="secondary"
      label="Migration Info"
      class="p-fluid"
      (onClick)="authService.openMigrationNotice()"
    >
    </p-button>
  </div>
</div>
